import React from "react";
import { View, SafeAreaView, ScrollView, TextInput, Image, StatusBar } from "react-native";
import MyText from '../libs/MyText';
import MyButton from '../libs/MyButton';
import css from "../css";
import { colors, cssConfig } from "../css/cssConfig";
import { featureList } from "../config/appData";
import demoImg from '../images/demo.png';
import queImg from '../images/que.png';
import { FbIcon, InstaIcon, Micon, YoutubeIcon } from "../images/mIcons";
import RainBowIcon from "../images/RainBowIcon";
import GIcons from "../images/GIcons.js";
import TwitterIcon from "../images/Twitter.js";
import { getStoreData } from "../api";
import AppContext from "../context/AppContext";

const Home = ({navigation, route}) => {
    const [searchStr, setSearchStr] = React.useState('');
    const [_user, setUser] = React.useState({});
    const _param = route.params;
    const context = React.useContext(AppContext);
    React.useEffect(()=> {
       getStoreData('user').then((res)=> {
        setUser(res);
       }).catch((err)=> {
        console.log(err);
       })
    }, []);

    const handleSearch = () => {
        if (searchStr.length > 3) {
            navigation.navigate('Search', {searchStr});
        } else {
            context.showInfoModal({
                title: 'Enter search text first.',
                subTitle: "you can find your friedns here. Ex: 'Alvin'",
            })
        }
    }

    const onStartup = () => {
        navigation.navigate('Register');
    }

    const createFeatureTiles = React.useCallback((items)=> {
        return(
            <View 
                key={items.title}
                style={[
                    css.setWidth(30, '%'),
                    css.setHeight(300),
                    css.ptLg,
                    css.setMargin(20, 'bottom'),
                    css.setMargin(10, 'right'),
                    
                ]}
            >
                <View 
                    style={[
                        css.setScreen(70, 70), 
                        css.themeBgLight,
                        css.selfCenter,
                        css.center,
                        css.setRadius(35),
                    ]}
                >
                    <GIcons
                        name={items.iconName}
                        height={48}
                        width={48}
                    />
                </View>
                <View style={[css.vPadLg, css.setHeight(20, '%'), ]}>
                    <MyText
                        title={items.title}
                        style={[
                            css.setFont(20),
                            css.textCenter,
                            css.textLight,
                            css.textThemeLine
                        ]}
                    />
                </View>
                <View>
                    <MyText
                        title={items.content}
                        style={[
                            css.setFont(16),
                            css.textCenter,
                            css.setColor('#475467'),
                            css.textThemeLine
                        ]}
                    />
                </View>
            </View>
        )
    },[]);

    return (
        <AppContext.Consumer>
            {context => 
                <SafeAreaView style={[css.bgWhite]}>
                    <StatusBar
                        animated={true}
                        translucent={true}
                        backgroundColor={"transparent"}
                        showHideTransition={'slide'}
                        networkActivityIndicatorVisible={true}
                    />
                    <ScrollView>
                        <View 
                            style={[
                                css.setBg('#603DB5'),
                                css.setWidth(90, '%'),
                                css.setHeight(720),
                                //css.setPadding(12, 5, 12, 5, '%'),
                                css.setPadding(140, 'top'),
                                css.setPadding(140, 'bottom'),
                                css.selfCenter,
                                css.setMargin(35, 'top'),
                                css.setRadius(20),
                            
                            ]}
                        >
                            <MyText
                                theme="heading"
                                title={"Welcome to"}
                                style={[
                                    css.textCenter, 
                                    css.textWhite, 
                                    css.setFont(65), 
                                    css.textLight
                                ]}
                            />
                            <MyText
                                theme="heading"
                                title="The Internet Phone Book"
                                style={[
                                    css.textCenter, 
                                    css.textWhite, 
                                    css.setFont(65), 
                                    css.textLight
                                ]}
                            />
                            <MyText
                                title="Search for contact using email, phone numbers or social media handle."
                                style={[
                                    css.textCenter, 
                                    css.textWhite, 
                                    css.mtmd,
                                    css.setFont(20),
                                    css.setLineHeight(30)
                                ]}
                            />
                            <View 
                                style={[
                                    css.setPadding(50,10,10,10), 
                                    css.center, 
                                    css.row,
                                    css.setWidth(70, '%'),
                                    css.selfCenter
                                ]}
                            >
                                <TextInput
                                    onChangeText={setSearchStr}
                                    placeholder="Search"
                                    placeholderTextColor={'#667085'}
                                    style={[
                                        css.themeTextBox,
                                        css.setBg(colors.white),
                                        css.setHeight(50),
                                        css.setWidth(50, '%')
                                    ]}
                                />
                                <MyButton
                                    title="Search"
                                    theme={true}
                                    textStyle={[css.setFont(16), css.setCharSpace(1)]}
                                    style={[
                                        css.mlmd,
                                        css.setHeight(50), 
                                        //css.setWidth(15, '%')
                                    ]}
                                    onPress={handleSearch}
                                />
                            </View>
                        </View>
                        <View style={[css.center, css.setPadding(10, 'top', '%')]}>
                            <MyText
                                title="Features"
                                theme="heading"
                                style={[css.textBlack, css.textLight, css.setFont(38)]}
                            />
                            <View 
                                style={[
                                    css.setWidth(),
                                    css.wrap,
                                    css.pLg, 
                                    css.row, 
                                    css.center,
                                ]}
                            >
                                {featureList.map(createFeatureTiles)}
                            </View>
                        </View>
                        <View 
                            style={[
                                css.setPadding(5,5,5,5, '%'),
                                css.row,
                                css.vCenter
                            ]}
                        >
                            <View style={[css.flex1, css.atStart]}>
                                <View>
                                    <MyText
                                        title="Join us now!"
                                        theme="heading"
                                        style={[css.textDark, css.setFont(48), css.textLight]}
                                    />
                                </View>
                                <View style={[css.vPadLg, css.vMarginMd]}>
                                    <MyText
                                        title="Ready to simplify your digital life? Sign up now and take the first step towards a unified, verifiable online presence."
                                        style={[css.textThemeLine, css.textContent]}
                                    />
                                </View>
                                <MyButton
                                    title={"Get Started"}
                                    theme={true}
                                    onPress={onStartup}
                                    textStyle={[css.setFont(16), css.textLight]}
                                    style={[css.setWidth(130), css.setHeight(42), css.selfStart]}
                                />
                            </View>
                            <View style={[css.hCenter]}>
                                <Image
                                    source={demoImg}
                                    style={[
                                        css.imgContain,
                                        css.setWidth(cssConfig.width()/3),
                                        css.setHeight(cssConfig.height()/2)
                                    ]}
                                />
                            </View>
                        </View>
                        <View style={[css.center, css.setPadding(5,5,5,5, '%')]}>
                            <View 
                                style={[
                                    css.setBg('#F9FAFB'), 
                                    css.setPadding(20,10,20,10),
                                    css.setWidth(100, '%')
                                ]}
                            >
                                <View style={[css.center, css.pbMd]}>
                                    <Image
                                    source={queImg}
                                    style={[
                                            css.imgContain,
                                            css.setWidth(cssConfig.width()/2),
                                            css.setHeight(70)
                                        ]}
                                    />
                                </View>
                                <MyText 
                                    title="Got Questions?" 
                                    style={[css.setFont(20), css.textCenter, css.textLight]} 
                                />
                                <View style={[css.vPadLg]}>
                                    <MyText 
                                        title="We've got answers. Check out our frequently asked questions section to learn more." 
                                        style={[
                                            css.setFont(18), 
                                            css.textCenter,
                                            css.textContent
                                        ]} 
                                    />
                                </View>
                                <MyButton
                                    theme={true}
                                    title="FAQ"
                                    textStyle={[css.setFont(16)]}
                                    style={[css.setHeight(45), css.setWidth(75)]}
                                />
                            </View>
                        </View>
                        <View style={[css.mtmd]}>
                            <View 
                                style={[
                                    css.setBg('#F9FAFB'), 
                                    css.setPadding(20,10,20,10),
                                    css.setWidth(),
                                    css.center
                                ]}
                            >
                                <MyText
                                    theme="heading"
                                    title="Connect with us"
                                    style={[
                                        css.setFont(38), 
                                        css.textCenter, 
                                        css.mbmd,
                                        css.textBlack,
                                        css.textLight
                                    ]}
                                />
                                <View style={[css.setWidth(50, '%')]}>
                                    <MyText
                                        title="Stay updated. Follow us on our social media channels and join the conversation. Your online presence, simplified. Welcome to the future, welcome to The Internet Phone Book."
                                        style={[css.setFont(16), css.textCenter, css.textContent]}
                                    />
                                </View>
                                <View 
                                    style={[
                                        css.row, 
                                        css.alignAround,
                                        css.setWidth(300),
                                        css.setVpadding(20)
                                    ]}
                                >
                                    <FbIcon
                                        width={35}
                                        height={35}
                                    />
                                    <InstaIcon
                                        width={35}
                                        height={35}
                                    />
                                    <Micon
                                        width={35}
                                        height={35}
                                    />
                                    <TwitterIcon
                                        width={40}
                                        height={40}
                                    />
                                </View>
                            </View>
                        </View>
                    </ScrollView>
                </SafeAreaView>
            }
        </AppContext.Consumer>
    )
}

export default Home;