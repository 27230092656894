import React from "react"
import { ScrollView, TextInput, View } from "react-native"
import MyText from "../../libs/MyText"
import css from "../../css"
import { qrValue } from "./profileConfig"
import { cssConfig } from "../../css/cssConfig"
import MyButton from "../../libs/MyButton"
import { ApiCall } from "../../api"
import AppContext from "../../context/AppContext"

const SocialConnect = ({navigation, route}) => {
    const _param = route.params || {};
    const [_form, setForm] = React.useState({verify_url: qrValue(_param)});
    const [startVerify, setVerify] = React.useState(false);
    const context = React.useContext(AppContext);

    const handleInput = (name)=> {
        return (value) => {
            setForm({..._form,[name]: value});
        }
    }

    const onBack = ()=> {
        context.updateState({profileUpdate: context.profileUpdate+1})
        navigation.goBack();
    }

    const onSubmit = () => {
        setVerify(true);
    }

    const onVerify = () => {
        context.setLoading(true);
        ApiCall('/social-profiles-mastodon', {method: 'POST'}, _form)
        .then((res)=> {
            //console.log(res);
            if (res.status) {
                // context.showInfoModal({
                //     title: 'Success',
                //     subTitle: 'Profile verified.',
                //     onOk: onBack,
                // });
                navigation.navigate("Profile", {edit: true, username: context.user?.username})
            } else {
                context.showInfoModal({
                    title: 'Warning',
                    subTitle: res.error,
                })
            }
        })
        .catch((err)=> {
            console.log(err);
            context.showInfoModal({
                title: 'Warning',
                subTitle: 'Unable to verify. Please check your internet.'
            })
        }).finally(()=> { context.setLoading(false); })
    }

    return (
        <ScrollView style={css.bgWhite} contentContainerStyle={cssConfig.isWeb && css.center}>
            <View style={[css.setPadding(5,5,5,5, '5'), cssConfig.isWeb && css.setWidth(cssConfig.width()/2)]}>
                {startVerify
                    ? (
                        <View>
                            <MyText 
                                title="Enter your mastodon profile link here:" 
                                style={[css.fs20, css.setColor(cssConfig.themeBgDark)]}
                            />
                            <TextInput
                                onChangeText={handleInput('profile_url')}
                                style={[css.themeTextBox, css.setHeight(45), css.mt]}
                            />
                        </View>
                    )
                    : (
                        <View style={[css.ptLg]}>
                            <MyText 
                                title="Copy your profile link and add into your mastodon profile:" 
                                style={[css.fs20, css.setColor(cssConfig.themeBgDark)]}
                            />
                            <View style={[css.themeTextBox, css.pMd, css.mtmd]}>
                                <TextInput 
                                    value={_form.verify_url}
                                    multiline={true}
                                    style={[css.fs16, css.textContent]}
                                />
                            </View>
                        </View>
                    )
                }
                <View style={[css.ptLg, css.row, css.center]}>
                    <MyButton
                        theme={true}
                        title={startVerify ? "Verify Profile" : "Continue"}
                        style={[css.setHeight(45)]}
                        textStyle={[css.fs16]}
                        onPress={startVerify ? onVerify : onSubmit}
                    />
                    <MyButton
                        title={"Back"}
                        style={[
                            css.setHeight(45), 
                            css.setWidth(80), 
                            css.setBorder(1, cssConfig.themeBgDark),
                            css.center, 
                            css.ml
                        ]}
                        textStyle={[css.fs16, css.setColor(cssConfig.themeBgDark)]}
                        onPress={onBack}
                    />
                </View>
            </View>
        </ScrollView>
    )
}

export default SocialConnect;