import React from 'react';
import { Linking, Text, View } from 'react-native';
import { getRedrectData, twitterRedirect } from './SocialWebProfile';
import { ApiCall } from '../../api';
import MyButton from '../../libs/MyButton';
import css from '../../css';
import MyText from '../../libs/MyText';
import InstagramLogin from "react-instagram-oauth";
import { instaConfig } from "../../config/appData";

const WebConnect = ({route}) => {
    const [isLog, setLog] = React.useState(false);
    const instaRef = React.useRef('');
    const _param = route.params || {};

    const sendMsg = (data)=> {
      console.log(data)
    }

    const startConnect = async ()=> {
        twitterRedirect();
        setLog(true);
    }

    React.useEffect(()=> {
        console.log(_param);
        if (isLog) {
            getRedrectData().then((res)=> {
                if (res.user) {
                    const user = res?.user?.reloadUserInfo;
                    if (user) {
                        const nm = user.displayName.split(' ');
                        sendMsg(JSON.stringify({
                            profile_type: 'twitter',
                            profile_username: user.screenName,
                            profile_fname: nm[0],
                            profile_lname: nm[1],
                            profile_pic: (user.providerUserInfo[0].photoUrl) ? user.providerUserInfo[0].photoUrl : user.photoUrl,
                        }))
                    }
                } else {
                    sendMsg('err::: Issue with twtterkit, did not return data.');
                }
            }).catch((err)=> {
                sendMsg('err:::'+ JSON.stringify(err));
            })
            setLog(false);
        }
     // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const instaLogin = (err, data) => {
        console.log(err, data);
        window.location = 'internetphonebook://profile/Pyadav2?reload=true';
    }

    if (_param.type == 'twitter') {
        return (
            <View style={[css.setHeight(), css.setPadding(5,5,5,5, '%')]}>
                  <MyText 
                      title='To Connect Profie need to login:' 
                      style={[css.fs16]}
                  />
                  <MyText 
                      title='This login is just for autentication only. we are not storing any data.' 
                      style={[css.fs12]}
                  />
                  <MyButton
                      theme={true}
                      title="Login"
                      onPress={startConnect}
                      style={[css.setWidth(100, '%'),css.setHeight(35), css.setMargin(15, 'top')]}
                  />
            </View>  
          );
    }

    return (
        <View style={[css.setHeight(), css.setPadding(5,5,5,5, '%')]}>
            <MyText 
                title='To Connect Profie need to login:' 
                style={[css.fs16]}
            />
            <MyText 
                title='This login is just for autentication only. we are not storing any data.' 
                style={[css.fs12, css.mblg]}
            />
            <InstagramLogin
                ref={instaRef}
                buttonTheme="simple"
                authCallback={instaLogin}
                appId={instaConfig.appId}
                appSecret={instaConfig.appSecret}
                redirectUri={instaConfig.callBack}
            />
        </View>
    )
}

export default WebConnect;