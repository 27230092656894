import { Image, TextInput, View } from "react-native";
import MyText from "../../libs/MyText";
import css from "../../css";
import userIcon from '../../images/user.png';
import MyButton from "../../libs/MyButton";
import GIcons from "../../images/GIcons";
import { cssConfig } from "../../css/cssConfig";
import { useState } from "react";
import { BaseUrl, imgPrefix } from "../../api";
import { FbIcon, InstaIcon, Micon, YoutubeIcon } from "../../images/mIcons";
import TwitterIcon from "../../images/Twitter.js";

export function ContactHeader (props) {
    const [searchStr, setSearch] = useState(props.searchStr);
    const _profile = props.data || {};

    const onInput = (str) => {
        setSearch(str);
    }

    return(
        <>
            <View style={[css.setHeight(240)]}>
                <View 
                    style={[
                        css.absolute, 
                        css.themeBg,
                        css.setWidth(100,'%'),
                        css.setHeight(150),
                        css.center,
                    ]}
                >
                    <View 
                        style={[
                            css.absolute,
                            css.setBottom(-35, '%'),
                            css.bgWhite,
                            css.setRadius(75),
                            css.center,
                        ]}
                    >

                        <Image
                            source={_profile.profile_pic ? {uri:`${imgPrefix}/${_profile.profile_pic}`} : userIcon}
                            style={[
                                css.imgCover,
                                css.setScreen(150, 150),
                                css.setRadius(75),
                            ]}
                        />
                    </View>
                </View>
            </View>  
            <View style={[css.center]}>
                <MyText
                    title={props.title}
                    style={[css.fs20, css.textLight]}
                />
                <MyText
                    title={props.location}
                    style={[css.fs12, css.textGray, css.vPadSm]}
                />
            </View>
            <View style={[css.pLg, css.center]}>
                <View style={[css.themeTextBox, css.row]}>
                    <TextInput
                        onChangeText={onInput}
                        value={searchStr}
                        style={[css.setHeight(40), css.setWidth(88, '%'), css.textStyle, css.pl]}
                    />
                    <MyButton
                        style={[css.setScreen(40, 40), css.center]}
                        onPress={props.onSearch}
                        arg={searchStr}
                    >
                        <GIcons name="search-sm" height={24} width={25} stroke={cssConfig.textColor} />
                    </MyButton>
                </View>
            </View>
            <View style={[css.row, css.pLg]}>
                <View style={[css.setBorderBottom(1, props.currentTab == 1 ? '#6941C6' : '#F2F4F7'), css.setWidth(50, '%')]}>
                    <MyButton
                        onPress={props.handleTab}
                        arg={1}
                        style={[ css.setWidth(100, '%'), css.setHeight(30), css.center, css.row]}
                    >
                        <MyText title="Following" style={[css.setColor(cssConfig.themeBgDark)]} />
                        <View style={[css.themeBgLight, css.setScreen(30,30), css.center, css.setRadius(15), css.ml]}>
                            <MyText title={props.following} style={[css.setColor(cssConfig.themeBgDark)]}/>
                        </View>
                    </MyButton>
                </View>
                <View style={[css.setBorderBottom(1, props.currentTab == 2 ? '#6941C6' : '#F2F4F7'), css.setWidth(50, '%')]}>
                    <MyButton
                        onPress={props.handleTab}
                        arg={2}
                        style={[ css.setWidth(100, '%'), css.setHeight(30), css.center, css.row]}
                    >
                        <MyText title="Followers" style={[css.setColor(cssConfig.themeBgDark)]}/>
                        <View style={[css.themeBgLight, css.setScreen(30,30), css.center, css.setRadius(15), css.ml]}>
                            <MyText title={props.followers} style={[css.setColor(cssConfig.themeBgDark)]}/>
                        </View>
                    </MyButton>
                </View>
            </View>
        </>
    )
}

export function GetSocial (item) {
    return (
        <View style={css.row}>
            <View style={[css.pr, css.row]}>
                {(item.facebook_fname!="") ? <FbIcon width={25} height={25}/> : ""}
                {(item.instagram_fname!="") ? <InstaIcon width={25} height={25}/> : ""}
                {(item.twitter_fname!="") ? <TwitterIcon width={25} height={25}/> : ""}
                {(item.mastodon_fname!="") ? <Micon width={25} height={25}/> : ""}
            </View>
        </View>
    )
}