import React from 'react';
import {Text, View} from 'react-native';
import css from '../css';
import {appTheme} from '../config/theme';

const MyText = props => {
  const UI = appTheme();
  const theme = {
    default: [css.textRegular, css.textDark],
    heading: [css.themeHeadingText],
    medium: [css.textMedium],
  };

  const onTouch = () => {
    if (props.arg) {
      props.onPress?.call(this, props.arg);
    } else {
      props.onPress?.call(this);
    }
  };

  const getExtraProps = () => {
    if (!props.onPress) {
      return {...props.textProps, disabled: true};
    }
    return props.textProps;
  };

  const renderLayout = React.useMemo(() => {
    const type = props.nestedTitle ? 'nestedText' : props.type;
    const wrapperStyle = theme[props.theme || 'default'];
    switch (type) {
      case 'inLine': {
        const boxStyle =
          props.style && Array.isArray(props.style)
            ? props.style
            : [props.style || ''];
        return (
          <View onPress={onTouch} style={[css.row, ...boxStyle]}>
            <Text
              style={UI.mergeArray(wrapperStyle, props.titleStyle)}
              {...props.titleProps}>
              {props.title}
            </Text>
            <Text style={props.subTitleStyle} {...props.subTitleProps}>
              {props.subTitle}
            </Text>
          </View>
        );
      }
      case 'nestedText':
        return (
          <Text
            style={UI.mergeArray(wrapperStyle, props.style)}
            onPress={onTouch}
            {...props.textProps}>
            {props.title}
            <Text style={props.nestedStyle}>{props.nestedTitle}</Text>
          </Text>
        );
      default:
        return (
          <Text
            testID={props.testID}
            style={UI.mergeArray(wrapperStyle, props.style)}
            onPress={onTouch}
            {...getExtraProps()}>
            {props.title || props.children}
          </Text>
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onTouch, props]);

  if (props.hide) return null;
  return renderLayout;
};

export default MyText;
