/**
 *  File Name   : theme.js
 *  Author      : Pradeep Yadav
 *  Description : Theme Action Definitions
 *  Version     : 1.0
 *  Package     : Theme Components
 *  Last update : 4 Aug 2023
 */
import {LayoutAnimation, Alert} from 'react-native';
const emailReg =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const urlReg =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
const bs64Prefix = 'data:image/png;base64,';

export function appTheme() {
  return {
    getBase64: function (file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            if (reader.result.startsWith('data:image')) {
              if (!reader.result.startsWith(bs64Prefix)) {
                reject('Please upload only png file.');
              }
              resolve(reader.result);
            } else {
              resolve(bs64Prefix + reader.result)
            }
        };
        reader.onerror = function (error) {
            reject(error);
        };
      })
    },
    createError: function (res = 'Data not found', errKey = 'reqInfo') {
      let _err = res?.error || res;
      if (typeof _err == 'string') {
        let tempError = typeof res == 'object' ? res.error || res.message : res;
        _err = {[errKey]: [tempError]};
      }

      return _err;
    },
    errorCount: function (obj) {
      let count = 0;
      for (let key in obj) {
        if (obj[key]) count++;
      }
      return +count;
    },
    getError: function (errObj) {
      if (typeof errObj == 'object') {
        let result = '';
        for (let key in errObj) {
          result += errObj[key][0] + '\n';
        }
        return result;
      } else {
        return errObj;
      }
    },
    log: function (...data) {
      console.log(data);
    },
    setDigitStr: function (num) {
      if (num < 10) {
        return `0${num}`;
      }
      return num;
    },
    ask: function (data) {
      Alert.alert(
        data.title,
        data.msg,
        [
          {
            text: data.okText || 'OK',
            onPress: data.onOk,
            style: 'ok',
          },
          {
            text: data.cancelText || 'Cancel',
            onPress: data.onCancel,
            style: 'cancel',
          },
        ],
        {
          cancelable: true,
          onDismiss: data.onDismiss || data.onCancel,
        },
      );
    },
    mergeArray: function (oldArray, newData) {
      if (!oldArray && !newData) {
        return [];
      }
      return Array.isArray(newData)
        ? [...oldArray, ...newData]
        : [...oldArray, newData];
    },
    getDataIndex: function (list, selected) {
      if (list && selected != null) {
        let result = list.findIndex(x => x.data == selected);
        return result == -1 ? 0 : result;
      } else {
        return false;
      }
    },
    isValid: function (data, type) {
      if (
        data &&
        data !== 'undefined' &&
        data !== undefined &&
        data !== '' &&
        data != null
      ) {
        return type ? (typeof data === type ? true : false) : true;
      } else {
        return false;
      }
    },
    empty: function (data) {
      let result;
      if (
        data &&
        data != 'undefined' &&
        data != undefined &&
        data != '' &&
        data != null
      ) {
        if (typeof data == 'object') {
          if (Array.isArray(data) && !data.length) {
            result = true;
          } else if (!Object.keys(data).length) {
            result = true;
          } else {
            result = false;
          }
        } else {
          result = false;
        }
      } else {
        result = true;
      }
      return result;
    },
    array_flip: function (trans) {
      let tmp_ar = {};
      for (let key in trans) {
        if (trans.hasOwnProperty(key)) {
          tmp_ar[trans[key]] = key;
        }
      }
      return tmp_ar;
    },
    filterObjKeys: function (obj, keys = '') {
      const retObj = {};
      keys = keys.split(',');
      for (let key in obj) {
        retObj[key] = {};
        if (keys.length == 1) {
          retObj[key] = obj[key][keys[0]];
        } else {
          for (let i of keys) {
            retObj[key][i] = obj[key][i];
          }
        }
      }
      return retObj;
    },
    removeStrSpace: function (str) {
      if (typeof str == 'object') return str;
      return str?.replace(/ /g,'') || str;
    },
    setData: function (arg, val) {
      if (arg == 'accessKey') return '';
      const lStorage = global.locaStore || {};
      lStorage[arg] = val;
      return lStorage[arg];
    },
    getData: function (arg) {
      const lStorage = global.locaStore || {};
      return lStorage[arg];
    },
    animate: function (obj, type) {
      if (['easeInEaseOut', 'linear', 'spring'].includes(type)) {
        LayoutAnimation.configureNext(LayoutAnimation.Presets[type]);
      } else if (['scaleX', 'scaleY', 'scaleXY'].includes(type)) {
        LayoutAnimation.configureNext(
          LayoutAnimation.create(
            300,
            LayoutAnimation.Types.linear,
            LayoutAnimation.Properties[type],
          ),
        );
      } else {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
      }
      if (this.setState) {
        this.setState(obj);
      }
    },
    jsonToFormData(obj) {
      let formData = new FormData();
      for (let key in obj) {
        formData.append(key, obj[key]);
      }
      return formData;
    },
    accessKey: function (newToken) {
      const lStorage = global.locaStore || {};
      if (newToken) {
        lStorage['accessKey'] = newToken;
        return newToken;
      } else if (lStorage['accessKey']) {
        return lStorage['accessKey'];
      }
      return null;
    },
    abbrNum: function (number, decPlaces) {
      // 2 decimal places => 100, 3 => 1000, etc
      decPlaces = Math.pow(10, decPlaces);

      // Enumerate number abbreviations
      let abbrev = ['k', 'm', 'b', 't'];

      // Go through the array backwards, so we do the largest first
      for (let i = abbrev.length - 1; i >= 0; i--) {
        // Convert array index to "1000", "1000000", etc
        let size = Math.pow(10, (i + 1) * 3);

        // If the number is bigger or equal do the abbreviation
        if (size <= number) {
          // Here, we multiply by decPlaces, round, and then divide by decPlaces.
          // This gives us nice rounding to a particular decimal place.
          number = Math.round((number * decPlaces) / size) / decPlaces;

          // Handle special case where we round up to the next abbreviation
          if (number == 1000 && i < abbrev.length - 1) {
            number = 1;
            i++;
          }

          // Add the letter for the abbreviation
          number += abbrev[i];

          // We are done... stop
          break;
        }
      }

      return number;
    },
    truncate: function (str, num) {
      if (str.length > num) {
        return str.slice(0, num) + '...';
      } else {
        return str;
      }
    },
  };
}

export function checkInputErr(input, val, onlyErr = false) {
  let result = '';
  switch (input.rule) {
    case 'email':
      {
        const res = emailReg.test(val);
        if (!res) {
          result = 'Please enter valid email.';
        }
      }
      break;
    case 'number':
      if (isNaN(val)) {
        result = 'Please enter numbers only.';
      }
      break;
    case 'url':
      {
        const res = urlReg.test(val);
        if (!res) {
          result = 'Please enter valid url.';
        }
      }
      break;
  }
  if (onlyErr) {
    return result;
  }
  return {[input.name]: result};
}

export function checkFormData(formData, inputData) {
  const res = inputData.reduce(
    (acc, item) => {
      if (item.isRequired) {
        if (item.childOf) {
          if (formData[item.childOf]) {
            if (formData[item.name]) {
              const e = checkInputErr(item, formData[item.name], true);
              if (e) {
                acc.result.push(item.name);
                acc.data[item.name] = e;
              }
            } else {
              acc.result.push(item.name);
              acc.data[item.name] = 'Please fill details';
            }
          }
        } else if (!formData[item.name]) {
          acc.result.push(item.name);
          acc.data[item.name] = 'Please fill details';
        } else {
          const e = checkInputErr(item, formData[item.name], true);
          if (e) {
            acc.result.push(item.name);
            acc.data[item.name] = e;
          }
        }
      }
      return acc;
    },
    {result: [], data: {}},
  );

  return {result: res.data, error: res.result?.length > 0 ? true : false};
}
