import Svg, {Path, G} from 'react-native-svg';

const GIcons = (props) => {
    const vBox = props.viewBox || '24';
    const getGSvgIcon = (name)=> {
        const stColor = props.stroke || "#7F56D9";
        switch (name) {
            case 'activity': return(<Path id="Icon" d="M18.8333 10H15.5L13 17.5L8.00001 2.5L5.50001 10H2.16667" stroke={stColor} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>);
            case 'menu-02': return (<Path id="Icon" d="M3 12H15M3 6H21M3 18H21" stroke={stColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>);
            case 'coins-hand': return (<Path id="Icon" d="M11.7746 6.95996C11.2976 7.30004 10.7138 7.50008 10.0833 7.50008C8.47251 7.50008 7.16667 6.19425 7.16667 4.58341C7.16667 2.97258 8.47251 1.66675 10.0833 1.66675C11.1275 1.66675 12.0435 2.21543 12.5588 3.0402M5.50001 16.7394H7.67525C7.95886 16.7394 8.24074 16.7731 8.51568 16.8406L10.8141 17.3991C11.3128 17.5206 11.8323 17.5325 12.3362 17.4346L14.8775 16.9402C15.5488 16.8094 16.1663 16.488 16.6503 16.0172L18.4482 14.2682C18.9617 13.7695 18.9617 12.9604 18.4482 12.461C17.986 12.0113 17.2539 11.9607 16.7309 12.342L14.6355 13.8708C14.3354 14.0902 13.9703 14.2083 13.5947 14.2083H11.5713L12.8592 14.2082C13.5852 14.2082 14.1732 13.6362 14.1732 12.93V12.6744C14.1732 12.088 13.763 11.5767 13.1784 11.435L11.1905 10.9515C10.867 10.8731 10.5357 10.8334 10.2026 10.8334C9.39862 10.8334 7.94325 11.4991 7.94325 11.4991L5.50001 12.5208M17.1667 5.41675C17.1667 7.02758 15.8608 8.33342 14.25 8.33342C12.6392 8.33342 11.3333 7.02758 11.3333 5.41675C11.3333 3.80592 12.6392 2.50008 14.25 2.50008C15.8608 2.50008 17.1667 3.80592 17.1667 5.41675ZM2.16667 12.1667L2.16667 17.0001C2.16667 17.4668 2.16667 17.7001 2.2575 17.8784C2.33739 18.0352 2.46488 18.1627 2.62168 18.2426C2.79994 18.3334 3.03329 18.3334 3.5 18.3334H4.16667C4.63338 18.3334 4.86674 18.3334 5.045 18.2426C5.2018 18.1627 5.32928 18.0352 5.40918 17.8784C5.50001 17.7001 5.50001 17.4668 5.50001 17.0001V12.1667C5.50001 11.7 5.50001 11.4667 5.40918 11.2884C5.32928 11.1316 5.2018 11.0041 5.045 10.9242C4.86674 10.8334 4.63338 10.8334 4.16667 10.8334L3.50001 10.8334C3.03329 10.8334 2.79994 10.8334 2.62168 10.9242C2.46488 11.0041 2.33739 11.1316 2.2575 11.2884C2.16667 11.4667 2.16667 11.7 2.16667 12.1667Z" stroke={stColor} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>);
            case 'message-chat-circle': return (<Path id="Icon" d="M5.57864 9.35717C5.52685 9.02336 5.49997 8.68138 5.49997 8.33317C5.49997 4.65127 8.50438 1.6665 12.2105 1.6665C15.9166 1.6665 18.921 4.65127 18.921 8.33317C18.921 9.1649 18.7677 9.96105 18.4876 10.6953C18.4295 10.8477 18.4004 10.924 18.3872 10.9835C18.3741 11.0425 18.369 11.084 18.3676 11.1444C18.3662 11.2053 18.3744 11.2725 18.391 11.4068L18.7265 14.1319C18.7628 14.4269 18.7809 14.5745 18.7319 14.6817C18.6889 14.7756 18.6125 14.8503 18.5176 14.8911C18.4092 14.9377 18.2622 14.9161 17.9681 14.873L15.3137 14.4839C15.1751 14.4636 15.1058 14.4535 15.0427 14.4538C14.9803 14.4542 14.9371 14.4588 14.876 14.4716C14.8142 14.4846 14.7353 14.5142 14.5775 14.5733C13.8414 14.849 13.0437 14.9998 12.2105 14.9998C11.862 14.9998 11.5197 14.9734 11.1856 14.9226M6.85965 18.3332C9.3304 18.3332 11.3333 16.2811 11.3333 13.7498C11.3333 11.2185 9.3304 9.1665 6.85965 9.1665C4.38891 9.1665 2.38597 11.2185 2.38597 13.7498C2.38597 14.2587 2.4669 14.7481 2.6163 15.2054C2.67945 15.3988 2.71102 15.4954 2.72139 15.5615C2.7322 15.6304 2.7341 15.6691 2.73007 15.7388C2.72621 15.8055 2.70952 15.8809 2.67613 16.0318L2.16667 18.3332L4.66234 17.9923C4.79856 17.9737 4.86667 17.9644 4.92615 17.9648C4.98877 17.9653 5.02201 17.9687 5.08343 17.9809C5.14176 17.9925 5.22847 18.0231 5.4019 18.0843C5.85883 18.2456 6.34926 18.3332 6.85965 18.3332Z" stroke={stColor} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>);
            case 'lock-01': return (<Path id="Icon" d="M14.6667 8.33333V6.66667C14.6667 4.36548 12.8012 2.5 10.5 2.5C8.19881 2.5 6.33333 4.36548 6.33333 6.66667V8.33333M10.5 12.0833V13.75M7.83333 17.5H13.1667C14.5668 17.5 15.2669 17.5 15.8016 17.2275C16.272 16.9878 16.6545 16.6054 16.8942 16.135C17.1667 15.6002 17.1667 14.9001 17.1667 13.5V12.3333C17.1667 10.9332 17.1667 10.2331 16.8942 9.69836C16.6545 9.22795 16.272 8.8455 15.8016 8.60582C15.2669 8.33333 14.5668 8.33333 13.1667 8.33333H7.83333C6.4332 8.33333 5.73313 8.33333 5.19835 8.60582C4.72795 8.8455 4.3455 9.22795 4.10581 9.69836C3.83333 10.2331 3.83333 10.9332 3.83333 12.3333V13.5C3.83333 14.9001 3.83333 15.6002 4.10581 16.135C4.3455 16.6054 4.72795 16.9878 5.19835 17.2275C5.73313 17.5 6.4332 17.5 7.83333 17.5Z" stroke={stColor} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>);
            case 'share-06': return (<Path id="Icon" d="M17.8261 10.5062C18.0296 10.3318 18.1313 10.2446 18.1686 10.1409C18.2013 10.0498 18.2013 9.9502 18.1686 9.85914C18.1313 9.75539 18.0296 9.6682 17.8261 9.49383L10.7672 3.44331C10.417 3.14315 10.2419 2.99306 10.0937 2.98939C9.96485 2.98619 9.84178 3.04279 9.76037 3.14269C9.66668 3.25764 9.66668 3.48825 9.66668 3.94948V7.52886C7.88778 7.84007 6.25968 8.74146 5.04977 10.0949C3.7307 11.5704 3.00104 13.48 3.00002 15.4591V15.9691C3.87447 14.9157 4.96627 14.0638 6.20065 13.4716C7.28893 12.9495 8.46536 12.6403 9.66668 12.5588V16.0505C9.66668 16.5117 9.66668 16.7424 9.76037 16.8573C9.84178 16.9572 9.96485 17.0138 10.0937 17.0106C10.2419 17.0069 10.417 16.8569 10.7672 16.5567L17.8261 10.5062Z" stroke={stColor} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>)
            case 'user-check-01': return(<Path id="Icon" d="M10.5 12.9167H6.75001C5.58704 12.9167 5.00555 12.9167 4.53239 13.0602C3.46705 13.3834 2.63337 14.217 2.3102 15.2824C2.16667 15.7555 2.16667 16.337 2.16667 17.5M13.8333 15L15.5 16.6667L18.8333 13.3333M12.5833 6.25C12.5833 8.32107 10.9044 10 8.83334 10C6.76227 10 5.08334 8.32107 5.08334 6.25C5.08334 4.17893 6.76227 2.5 8.83334 2.5C10.9044 2.5 12.5833 4.17893 12.5833 6.25Z" stroke={stColor} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>);
            case 'link-03': return (<Path id="Icon" d="M8.83333 10.8335C9.19121 11.3119 9.6478 11.7078 10.1721 11.9942C10.6965 12.2807 11.2763 12.4511 11.8722 12.4937C12.4682 12.5364 13.0663 12.4504 13.6261 12.2416C14.1859 12.0328 14.6943 11.706 15.1167 11.2835L17.6167 8.78347C18.3757 7.99762 18.7956 6.94511 18.7861 5.85263C18.7766 4.76014 18.3384 3.71509 17.5659 2.94256C16.7934 2.17002 15.7483 1.73182 14.6558 1.72233C13.5633 1.71283 12.5108 2.13281 11.725 2.8918L10.2917 4.3168M12.1667 9.1668C11.8088 8.68836 11.3522 8.29248 10.8279 8.00602C10.3035 7.71955 9.72374 7.5492 9.12779 7.50652C8.53184 7.46384 7.93368 7.54983 7.37388 7.75865C6.81408 7.96747 6.30574 8.29424 5.88333 8.7168L3.38333 11.2168C2.62434 12.0026 2.20436 13.0552 2.21386 14.1476C2.22335 15.2401 2.66155 16.2852 3.43409 17.0577C4.20662 17.8302 5.25167 18.2684 6.34416 18.2779C7.43665 18.2874 8.48916 17.8675 9.275 17.1085L10.7 15.6835" stroke={stColor} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>);
            case 'search-sm': return(<Path id="Icon" d="M17.5 17.5L12.5001 12.5M14.1667 8.33333C14.1667 11.555 11.555 14.1667 8.33333 14.1667C5.11167 14.1667 2.5 11.555 2.5 8.33333C2.5 5.11167 5.11167 2.5 8.33333 2.5C11.555 2.5 14.1667 5.11167 14.1667 8.33333Z" stroke={stColor} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>);
            case 'plus-circle': return (<Path id="Icon" d="M10.8333 6.66666V13.3333M7.50001 9.99999H14.1667M19.1667 9.99999C19.1667 14.6024 15.4357 18.3333 10.8333 18.3333C6.23097 18.3333 2.50001 14.6024 2.50001 9.99999C2.50001 5.39762 6.23097 1.66666 10.8333 1.66666C15.4357 1.66666 19.1667 5.39762 19.1667 9.99999Z" stroke={stColor} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>);
            case 'checkbox': return (<Path fillRule="evenodd" clipRule="evenodd" d="M6 5C5.44772 5 5 5.44772 5 6V13V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V13V6C19 5.44772 18.5523 5 18 5H6ZM3 6C3 4.34315 4.34315 3 6 3H18C19.6569 3 21 4.34315 21 6V13V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V13V6Z" fill={stColor}/>);
            case 'checkbox_Check': return (<Path id="Vector" d="M8 12L11 15L16 9M4 16.8002V7.2002C4 6.08009 4 5.51962 4.21799 5.0918C4.40973 4.71547 4.71547 4.40973 5.0918 4.21799C5.51962 4 6.08009 4 7.2002 4H16.8002C17.9203 4 18.4796 4 18.9074 4.21799C19.2837 4.40973 19.5905 4.71547 19.7822 5.0918C20 5.5192 20 6.07899 20 7.19691V16.8036C20 17.9215 20 18.4805 19.7822 18.9079C19.5905 19.2842 19.2837 19.5905 18.9074 19.7822C18.48 20 17.921 20 16.8031 20H7.19691C6.07899 20 5.5192 20 5.0918 19.7822C4.71547 19.5905 4.40973 19.2842 4.21799 18.9079C4 18.4801 4 17.9203 4 16.8002Z" stroke={stColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>);
            case 'edit': return (<Path d="M 22.828125 3 C 22.316375 3 21.804562 3.1954375 21.414062 3.5859375 L 19 6 L 24 11 L 26.414062 8.5859375 C 27.195062 7.8049375 27.195062 6.5388125 26.414062 5.7578125 L 24.242188 3.5859375 C 23.851688 3.1954375 23.339875 3 22.828125 3 z M 17 8 L 5.2597656 19.740234 C 5.2597656 19.740234 6.1775313 19.658 6.5195312 20 C 6.8615312 20.342 6.58 22.58 7 23 C 7.42 23.42 9.6438906 23.124359 9.9628906 23.443359 C 10.281891 23.762359 10.259766 24.740234 10.259766 24.740234 L 22 13 L 17 8 z M 4 23 L 3.0566406 25.671875 A 1 1 0 0 0 3 26 A 1 1 0 0 0 4 27 A 1 1 0 0 0 4.328125 26.943359 A 1 1 0 0 0 4.3378906 26.939453 L 4.3632812 26.931641 A 1 1 0 0 0 4.3691406 26.927734 L 7 26 L 5.5 24.5 L 4 23 z" stroke={stColor} />);
            case 'verify': return (<Path d="M 25 2 C 12.317 2 2 12.317 2 25 C 2 37.683 12.317 48 25 48 C 37.683 48 48 37.683 48 25 C 48 20.44 46.660281 16.189328 44.363281 12.611328 L 42.994141 14.228516 C 44.889141 17.382516 46 21.06 46 25 C 46 36.579 36.579 46 25 46 C 13.421 46 4 36.579 4 25 C 4 13.421 13.421 4 25 4 C 30.443 4 35.393906 6.0997656 39.128906 9.5097656 L 40.4375 7.9648438 C 36.3525 4.2598437 30.935 2 25 2 z M 43.236328 7.7539062 L 23.914062 30.554688 L 15.78125 22.96875 L 14.417969 24.431641 L 24.083984 33.447266 L 44.763672 9.046875 L 43.236328 7.7539062 z" fill={stColor} />);
            case 'editIcon': return (<Path d="M12.5,10.2071068 L8,14.7071068 L8,16 L9.29289322,16 L13.7928932,11.5 L12.5,10.2071068 Z M13.2071068,9.5 L14.5,10.7928932 L15.7928932,9.5 L14.5,8.20710678 L13.2071068,9.5 Z M12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 Z M12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 Z M14.8535534,7.14644661 L16.8535534,9.14644661 C17.0488155,9.34170876 17.0488155,9.65829124 16.8535534,9.85355339 L9.85355339,16.8535534 C9.7597852,16.9473216 9.63260824,17 9.5,17 L7.5,17 C7.22385763,17 7,16.7761424 7,16.5 L7,14.5 C7,14.3673918 7.05267842,14.2402148 7.14644661,14.1464466 L14.1464466,7.14644661 C14.3417088,6.95118446 14.6582912,6.95118446 14.8535534,7.14644661 Z" fill={stColor} />)
            case 'removeIcon': return (<Path d="M178.666,134.4H77.331c-3.534,0-6.4-2.866-6.4-6.4s2.866-6.4,6.4-6.4h101.335c3.533,0,6.399,2.866,6.399,6.4  S182.199,134.4,178.666,134.4z M256,128C256,57.42,198.58,0,128,0C57.42,0,0,57.42,0,128c0,70.58,57.42,128,128,128  C198.58,256,256,198.58,256,128z M243.199,128c0,63.521-51.678,115.2-115.199,115.2c-63.522,0-115.2-51.679-115.2-115.2  C12.8,64.478,64.478,12.8,128,12.8C191.521,12.8,243.199,64.478,243.199,128z" fill={stColor}/>)
        }
    }
    
    return (
        <Svg 
            width={props.width || "24"} 
            height={props.height || "24"} 
            viewBox={`0 0 ${vBox} ${vBox}`}
            fill="none"
        >
            <G id={props.name}>
                {getGSvgIcon(props.name)}
            </G>
        </Svg>
    )
}

export default GIcons;