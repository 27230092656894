import {AppRegistry} from 'react-native';
import App from './App';

import Inter from './fontFamily/Inter.ttf';

const InterFont = `@font-face {
  src: url(${Inter});
  font-family: Inter;
}`;

const style = document.createElement('style');
style.type = 'text/css';

if (style.styleSheet) {
  style.styleSheet.cssText = InterFont;
} else {
  style.appendChild(document.createTextNode(InterFont));
}

document.head.appendChild(style);

if (module.hot) {
  module.hot.accept();
}

AppRegistry.registerComponent('App', () => App);
AppRegistry.runApplication('App', {
  rootTag: document.getElementById('root'),
});
