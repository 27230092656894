import React from "react";
import { View } from "react-native";
import MyText from "../../libs/MyText";
import { cssConfig } from "../../css/cssConfig";

const FindContacts = (props) => {
    const _param = props.route.params;
    const [list, setList] = React.useState([]);

    return (
        <View>
            <MyText title={"Find contacts"} />
        </View>
    )
}

export default FindContacts;