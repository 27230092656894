import React from 'react';
import {ActivityIndicator, View, Modal} from 'react-native';
import css from '../css';
import MyText from './MyText';

const Loader = props => {
  if (props.fullScreen) {
    return (
      <Modal
        animationType={props.animation || 'slide'}
        transparent={true}
        visible={props.loading}
        statusBarTranslucent={true}
        onRequestClose={() => {
          props.onClose?.();
        }}>
        <View style={[css.centeredView, {justifyContent: 'center'}]}>
          <View
            style={[
              css.absolute,
              css.bgDark,
              css.setScreen(),
              css.setPosition(0, 0, 0, 0),
              css.setOpacity(0.6),
            ]}
          />
          <View style={[css.modalView]}>
            <ActivityIndicator
              size={props.size || 'small'}
              color={props.color || '#0000ff'}
            />
            {props.title ? (
              <MyText title={props.title} style={props.textStyle} />
            ) : null}
          </View>
        </View>
      </Modal>
    );
  } else {
    if (props.loading) {
      return (
        <View style={[props.style]}>
          <ActivityIndicator
            size={props.size || 'small'}
            color={props.color || '#0000ff'}
          />
          {props.title ? (
            <MyText title={props.title} style={props.textStyle} />
          ) : null}
        </View>
      );
    }
  }

  return null;
};

export default Loader;
