import { ScrollView, TextInput, View } from "react-native";
import MyText from "../../libs/MyText";
import React from "react";
import { ApiCall } from "../../api";
import AppContext from "../../context/AppContext";
import Select from "../../libs/Select";
import css from "../../css";
import MyButton from "../../libs/MyButton";
import { cssConfig } from "../../css/cssConfig";
import Loader from "../../libs/Loader";

const EditInfo = ({ navigation, route }) => {
    const _params = route.params;
    const [_data, setData] = React.useState({ name: _params.searchStr, countryVal: _params.country_val == '1' ? `${_params.country_val}_${_params.country_code}` : _params.country_val, city: _params.city });
    const [locations, setLocationList] = React.useState([]);
    const context = React.useContext(AppContext);
    const [isLoading, setLoading] = React.useState(true);

    React.useEffect(() => {
        ApiCall('/countries').then((res) => {
            console.log('countries', res)
            if (res.status) {
                const temp = res.data.reduce((acc, item) => {
                    acc.push({
                        label: item.name,
                        value: item.phone == 1 ? `${item.phone}_${item.code}` : item.phone,
                        code: item.code,
                    })
                    return acc;
                }, [])
                console.log('temp', temp)
                setLocationList(temp);
            }
        }).catch((err) => {
            console.log(err);
        })
            .finally(() => {
                setLoading(false);
            })
    }, []);

    const handleSelect = (name) => {

        return (value) => {
            console.log(name, value);
            if (name === 'country') {
                setData({ ..._data, [name]: value.label, countryVal: value.value, country_code: value.code });
            } else {
                setData({ ..._data, [name]: value });
            }
        }
    }

    const onBack = () => {
        navigation.goBack();
    }

    const onSubmit = () => {
        let arr = []
        if (typeof (_data?.countryVal) === 'string') {
            arr = _data?.countryVal?.split('_')
        }
        let data = {
            country: _data?.country,
            countryVal: arr.length > 0 ? parseInt(arr[0]) : _data?.countryVal,
            country_code: _data?.country_code,
            city: _data?.city
        }

        ApiCall('/update-profile', { method: 'POST' }, data)
            .then((res) => {
                console.log(res);
                if (res.status) {
                    context.updateState({ profileUpdate: context.profileUpdate + 1 })
                    navigation.navigate('Profile', { edit: true, username: context.user?.username });
                    // context.showInfoModal({
                    //     title: 'Success',
                    //     subtitle: 'Profile updated successfully!',
                    //     onOk: () => {
                    //         navigation.goBack();
                    //     }
                    // })
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    if (isLoading) {
        return (
            <View style={[css.bgWhite]}>
                <Loader loading={true} style={[css.pLg]} />
            </View>
        )
    }
    return (
        <ScrollView style={[css.bgWhite]} contentContainerStyle={css.center}>
            <View style={[css.ptLg]}>
                <MyText title="Select Country" style={[css.fs16, css.mb]} />
                <Select
                    onPress={handleSelect('country')}
                    headerTitle="Select Location"
                    list={locations}
                    value={_data.countryVal}
                    placeholder="Location"
                    style={[css.setHeight(40), css.setWidth(200), css.themeTextBox]}
                    extraProps={cssConfig.isWeb ? { center: true, widthPart: 3, viewStyle: 'fade' } : {}}
                />
            </View>
            <View style={[css.ptLg]}>
                <MyText title="Enter City" style={[css.fs16, css.mb]} />
                <TextInput
                    value={_data.city}
                    onChangeText={handleSelect('city')}
                    style={[css.setHeight(40), css.themeTextBox, !cssConfig.isWeb && css.setWidth(cssConfig.width() / 2)]}
                />
            </View>
            <View style={[css.ptLg, css.row]}>
                <MyButton
                    title="Back"
                    onPress={onBack}
                    style={[
                        css.setHeight(40),
                        css.setBorder(1, cssConfig.themeBgDark),
                        css.center,
                        css.setWidth(80)
                    ]}
                />
                <MyButton
                    theme={true}
                    title="Update"
                    onPress={onSubmit}
                    style={[css.setHeight(40), css.setWidth(80), css.mlmd]}
                />
            </View>
        </ScrollView>
    )
}

export default EditInfo;