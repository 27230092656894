import { ApiCall } from "../../api";

export function getList (isFolowing, str, uid) {
    return new Promise ((resolve, reject)=> {
        const apiName = isFolowing ? '/following-list': '/follower-list';
        const url = str ? `${apiName}?name=${str}&profile_uuid=${uid}` : `${apiName}?profile_uuid=${uid}`;
        console.log(url);
        ApiCall(url).then((res)=> {
            const data = filterList(res.data, isFolowing);
            resolve(data);
        }).catch((err)=> {
            reject(err);
        })
    })
}

function filterList (res, isFolowing) {
    if (res) {
        return res.reduce((acc, item)=> {
            const uId = isFolowing ? item.follower_user_id : item.follower_user_id;
            acc.push({
                ...item,
                userId: uId
            })
            return acc;
        }, [])
    }
    return [];
}

export const dummyList = [
    {
        contactId: '01',
        name: 'Olivia Star',
        profilePic: '',
        location: 'Australia, Mailbourne',
        email: 'Anika@Levin.com',
        phonenumber: '+1 123 456 798',
        socialMedia: ['fb', 'insta']
    },
    {
        contactId: '02',
        name: 'Olivia Star',
        profilePic: '',
        location: 'Australia, Mailbourne',
        email: 'Anika@Levin.com',
        phonenumber: '+1 123 456 798',
        socialMedia: ['fb', 'insta']
    },
    {
        contactId: '03',
        name: 'Olivia Star',
        profilePic: '',
        location: 'Australia, Mailbourne',
        email: 'Anika@Levin.com',
        phonenumber: '+1 123 456 798',
        socialMedia: ['fb', 'insta']
    },
    {
        contactId: '04',
        name: 'Olivia Star',
        profilePic: '',
        location: 'Australia, Mailbourne',
        email: 'Anika@Levin.com',
        phonenumber: '+1 123 456 798',
        socialMedia: ['fb', 'insta']
    },
    {
        contactId: '05',
        name: 'Olivia Star',
        profilePic: '',
        location: 'Australia, Mailbourne',
        email: 'Anika@Levin.com',
        phonenumber: '+1 123 456 798',
        socialMedia: ['fb', 'insta']
    },
    {
        contactId: '06',
        name: 'Olivia Star',
        profilePic: '',
        location: 'Australia, Mailbourne',
        email: 'Anika@Levin.com',
        phonenumber: '+1 123 456 798',
        socialMedia: ['fb', 'insta']
    }
]