import { ApiCall } from "../../api";

export function searchData (data) {
    let url = '/search-profile?'; 
    if (data.name) {
        url += `&name=${data.name}`;
    }
    if (data.country) {
        url += `&country=${data.country}`;
    }
    if (data.city) {
        url += `&city=${data.city}`;
    }
    if (data.socialMedia) {
        url += `&socialMedia=${data.socialMedia}`;
    }
    return ApiCall(url);
}

export const dummyUser = [
    {
        userId: '01',
        name: 'Olivia Star',
        profilePic: '',
        location: 'Australia, Mailbourne',
        social: [],
        follow: false,
    },
    {
        userId: '02',
        name: 'Olivia Star',
        profilePic: '',
        location: 'Australia, Mailbourne',
        social: [],
        follow: false,
    },
    {
        userId: '03',
        name: 'Olivia Star',
        profilePic: '',
        location: 'Australia, Mailbourne',
        social: [],
        follow: true,
    },
    {
        userId: '04',
        name: 'Olivia Star',
        profilePic: '',
        location: 'Australia, Mailbourne',
        social: [],
        follow: false,
    },
    {
        userId: '05',
        name: 'Olivia Star',
        profilePic: '',
        location: 'Australia, Mailbourne',
        social: [],
        follow: false,
    },
    {
        userId: '06',
        name: 'Olivia Star',
        profilePic: '',
        location: 'Australia, Mailbourne',
        social: [],
        follow: false,
    }
]