import React, { useEffect } from 'react';
import { View } from 'react-native';
import MyButton from '../libs/MyButton';
import css from '../css';
import GIcons from '../images/GIcons';
import { cssConfig } from '../css/cssConfig';
import { BaseUrl, StoreData } from '../api';
import Logout from '../images/Logout';
import AppContext from '../context/AppContext';
import  userIcon from '../images/user.png';

const HeaderRight = (props) => {
    const logout = (context)=> {
        context.onLogout()
        props.navigation.reset({
            index: 0,
            routes: [{name: 'Home'}]
        });
    }

    const openProfile = async (context) => {
        if (context.isLogin) {
            props.navigation.navigate("Profile", {edit: true, username: context.user?.username});
        } else {
            props.navigation.navigate("Login");
        }
    }

    const onAction = (name) => {
        if (props.route.name != name) {
            props.navigation.navigate(name);
        }
    }

    const renderOptions = (context)=> {
        const _profile = context.user || {};
        if (cssConfig.isWeb) {
            if (context.isLogin) {
                if (props.route.name == 'Login' || props.route.name == 'Register') {
                    const st = [css.setHeight(35), css.center];
                    return (
                        <View 
                            style={[
                                css.row, 
                                css.setPadding(55, 'right', '%'),
                                css.center
                            ]}
                        >
                            <MyButton
                                theme={props.route.name == 'Login'}
                                title='Login'
                                arg={'Login'}
                                style={[...st]}
                                onPress={onAction}
                            />
                            <MyButton
                                theme={props.route.name == 'Register'}
                                title='Sign Up'
                                arg={'Register'}
                                style={[...st, css.ml]}
                                onPress={onAction}
                            />
                        </View>
                    )
                } else {
                    return (
                        <View style={[css.row, cssConfig.isWeb && css.setPadding(60, 'right', '%')]}>
                            <MyButton
                                type="rightImage"
                                source={_profile.profile_pic ? {uri:`${BaseUrl}/${_profile.profile_pic}`} : userIcon}
                                imgStyle={[css.setSize(40), css.setBorder(1, '#ccc'), css.setRadius(20)]}
                                textStyle={[css.pr]}
                                arg={context}
                                onPress={openProfile}
                            />
                            <MyButton
                                style={[css.setWidth(40), css.center]}
                                onPress={logout}
                                arg={context}
                                hide={!context.isLogin}
                            >
                                <Logout
                                    height={22}
                                    width={22}
                                    stroke={cssConfig.textColor}
                                />
                            </MyButton>
                        </View>
                    )
                }
            } else {
                return (
                    <View 
                        style={[
                            css.row, 
                            css.setPadding(55, 'right', '%'),
                            css.center
                        ]}
                    >
                        <MyButton
                            theme={true}
                            title='Login'
                            arg={'Login'}
                            style={[css.setHeight(35), css.center]}
                            onPress={onAction}
                        />
                         <MyButton
                            theme={true}
                            title='Sign Up'
                            arg={'Register'}
                            style={[css.setHeight(35), css.center, css.setMargin(10)]}
                            onPress={onAction}
                        />
                    </View>
                )
            }
        }
        return (
            <View style={[css.row]}>
                <MyButton
                    style={[
                        css.setWidth(40)
                    ]}
                    arg={context}
                    onPress={openProfile}
                >
                    <GIcons
                        name="menu-02"
                        size={34}
                        stroke={cssConfig.textColor}
                    />
                </MyButton>
                <MyButton
                    style={[
                        css.setWidth(40)
                    ]}
                    onPress={logout}
                    arg={context}
                    hide={!context.isLogin}
                >
                    <Logout
                        height={22}
                        width={22}
                        stroke={cssConfig.textColor}
                    />
                </MyButton>
            </View>
        )
    }

    return(
        <AppContext.Consumer>
            {context => renderOptions(context)}
        </AppContext.Consumer>
    )
}
export default HeaderRight;