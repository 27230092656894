import React from 'react';
import {Modal, View, ActivityIndicator, Image} from 'react-native';
import css from '../css';
import MyText from './MyText';
import MyButton from './MyButton';
import {cssConfig} from '../css/cssConfig';
import {ReactComponent as CloseIcon} from '../images/CloseIcon.svg';
const InfoModal = React.forwardRef((props, ref) => {
  const [modalVisible, updateModal] = React.useState(false);
  const [infoData, updateInfoStr] = React.useState();
  const [_data, setData] = React.useState({});

  const modalWidth = props.widthPart ? cssConfig.width()/props.widthPart : cssConfig.width();

  React.useImperativeHandle(ref, () => ({
    showInfoModal: data => {
      if (typeof data === 'object') {
        setData({...data});
        updateInfoStr(data.title || '');
      } else {
        setData({});
        updateInfoStr(data || '');
      }
      updateModal(true);
    },
    close: () => {
      updateModal(false);
    },
  }));

  const onTouch = () => {
    updateModal(false);
    props.onClose?.();
    _data.onOk?.();
  };

  const onCancel = () => {
    updateModal(false);
    props.onClose?.();
    _data.onCancel?.();
  };

  const onCloseLocal = () => {
    updateModal(false);
    props.onClose?.();
  }

  const getBackdrop = () => {
    return (
      <View
        style={[
          css.absolute,
          css.bgDark,
          css.setScreen(),
          css.setPosition(0, 0, 0, 0),
          css.setOpacity(0.6),
        ]}></View>
    );
  };

  const modalHeader =()=> {
    const hTitle = props.headerTitle || _data.headerTitle;
    const hIcon = props.headerIcon || _data.headerIcon;
    return (
      <View 
        style={[
          css.row, 
          css.setWidth(modalWidth), 
          css.alignCorner, 
          css.vCenter, 
          css.hPadLg
        ]}>
          {hIcon
            ? <Image source={props.headerIcon} style={[css.setSize(props.iconSize), css.imgContain]}/>
            : <MyText title={hTitle || ''} style={props.headerTitleStyle}/>
          }
          <MyButton
            style={[css.setScreen(20,20), css.center]}
            onPress={onCloseLocal}
          >
            <Image source={require('../images/close.png')} style={[css.imgContain, css.setSize(props.iconSize || 16)]} />
          </MyButton> 
      </View>
    )
  };

  const getLayout = () => {
    if (props.children) {
      if (props.wrap) {
        const st = props.wrapStyle || [css.setWidth(100, '%'), css.hCenter];
        return <View style={st}>{props.children}</View>;
      }
      return props.children;
    } else if (_data.isLoading) {
      return (
        <View style={[css.setMinHeight(100)]}>
          <ActivityIndicator size={'large'} color={'#0000ff'} />
        </View>
      );
    } else {
      return (
        <View
          style={[css.setMinHeight(100), css.setWidth(100, '%'), _data.childCenter && css.center]}>
          <MyText
            title={infoData || 'Please check your internet.'}
            theme="heading"
            style={[css.fs18, css.textLightBold]}
          />
          {_data.subTitle ? (
            <MyText title={_data.subTitle} style={[css.fs14, css.textGray, css.mt]} />
          ) : null}
          <View style={[css.row]}>
            <MyButton
              theme={true}
              title={_data.btnLabel || 'OK'}
              style={[css.setHeight(40), css.mtmd, css.mr]}
              textStyle={[css.fs18]}
              onPress={onTouch}
              hide={!_data.onOk}
            />
            <MyButton
              theme={true}
              title={'Cancel'}
              style={[
                css.setHeight(40),
                css.mtmd,
                css.bgWhite,
                css.setBorder(1, cssConfig.themeBgColor),
              ]}
              textStyle={[css.fs18, css.textDarkBlue]}
              onPress={onCancel}
              hide={!_data.onCancel}
            />
          </View>
        </View>
      );
    }
  };

  return (
    <Modal
      animationType={props.viewStyle || 'slide'}
      hardwareAccelerated={true}
      transparent={true}
      visible={props.show || modalVisible}
      statusBarTranslucent={true}
      onRequestClose={onCloseLocal}
    >
      {getBackdrop()}
      <View 
        style={[
          (props.center || _data.center) ? css.webCenteredView : css.centeredView, 
          css.setWidth(modalWidth)
        ]}
      >
        <View style={[css.modalView, css.setWidth(modalWidth)]}>
          {modalHeader()}
          {getLayout()}
        </View>
      </View>
    </Modal>
  );
});

export default InfoModal;
