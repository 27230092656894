import { useState, useEffect } from "react";
import { View, Text } from "react-native";
import css from "../css";


const Warn = (props) => {
    const [str, setStr] = useState('');

    useEffect(()=> {
        if (props.data?.[props.name]) {
            setStr(props.data[props.name]);
        }
    }, [props.data, props.name]);

    if (str) {
        return (
            <View style={props.style}>
                <Text style={[css.themeNormalText, css.setColor('red'), [...props.textStyle || []]]}>
                    {str}
                </Text>
            </View>
        )
    }

    return null;
}

export default Warn;