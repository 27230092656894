import { G, Path, Svg } from "react-native-svg";

export default function Camera (props) {
    return (
        <Svg width={props.width || "48"} height={props.height || "48"} viewBox={`0 0 ${props.viewBox || 48} ${props.viewBox || 48}`} fill={props.fill || "none"} xmlns="http://www.w3.org/2000/svg">
            <G id="camera-01">
                <G id="Icon">
                    <Path d="M4 16.7544C4 16.0538 4 15.7035 4.02923 15.4084C4.3112 12.5625 6.56254 10.3112 9.40842 10.0292C9.70349 10 10.0727 10 10.8112 10C11.0957 10 11.238 10 11.3588 9.99268C12.9012 9.89927 14.2519 8.92575 14.8283 7.492C14.8734 7.37972 14.9156 7.25314 15 7C15.0844 6.74686 15.1266 6.62028 15.1717 6.508C15.7481 5.07425 17.0988 4.10073 18.6412 4.00732C18.762 4 18.8954 4 19.1623 4H28.8377C29.1046 4 29.238 4 29.3588 4.00732C30.9012 4.10073 32.2519 5.07425 32.8283 6.508C32.8734 6.62028 32.9156 6.74686 33 7C33.0844 7.25314 33.1266 7.37972 33.1717 7.492C33.7481 8.92575 35.0988 9.89927 36.6412 9.99268C36.762 10 36.9043 10 37.1888 10C37.9273 10 38.2965 10 38.5916 10.0292C41.4375 10.3112 43.6888 12.5625 43.9708 15.4084C44 15.7035 44 16.0538 44 16.7544V32.4C44 35.7603 44 37.4405 43.346 38.7239C42.7708 39.8529 41.8529 40.7708 40.7239 41.346C39.4405 42 37.7603 42 34.4 42H13.6C10.2397 42 8.55953 42 7.27606 41.346C6.14708 40.7708 5.2292 39.8529 4.65396 38.7239C4 37.4405 4 35.7603 4 32.4V16.7544Z" stroke={props.stroke || "white"} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                    <Path d="M24 33C28.4183 33 32 29.4183 32 25C32 20.5817 28.4183 17 24 17C19.5817 17 16 20.5817 16 25C16 29.4183 19.5817 33 24 33Z" stroke={props.stroke || "white"} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                </G>
            </G>
        </Svg>
    )
}