import Svg, {Path, Rect, Stop, LinearGradient, Defs, RadialGradient} from 'react-native-svg';

const RainBowIcon = (props) => {
    const vBox = props.viewBox || '70';
    return (
        <Svg width={props.width} height={props.height} viewBox={`0 0 ${vBox} ${vBox}`} fill="none">
        <Rect width={props.width*2} height={props.height*2} fill="#113989" transform="translate(0.166656)" x="1" rx="12"/>
            <Path d="M11.6667 22.1667H15.1667C33.208 22.1667 47.8333 36.7921 47.8333 54.8334V58.3334H54.8333C56.7663 58.3334 58.3333 56.7664 58.3333 54.8334C58.3333 30.9931 39.007 11.6667 15.1667 11.6667C13.2337 11.6667 11.6667 13.2338 11.6667 15.1667V22.1667Z" fill="url(#paint0_radial_279_11872)"/>
            <Path d="M49.0001 54.8335H58.3335C58.3335 56.7665 56.7664 58.3335 54.8335 58.3335H49.0001V54.8335Z" fill="url(#paint1_linear_279_11872)"/>
            <Path d="M15.1667 11.6667L15.1667 21.0001H11.6667L11.6667 15.1667C11.6667 13.2338 13.2337 11.6667 15.1667 11.6667Z" fill="url(#paint2_linear_279_11872)"/>
            <Path d="M11.6668 21H15.1668C33.8524 21 49.0001 36.1477 49.0001 54.8333V58.3333H38.5001V54.8333C38.5001 41.9467 28.0535 31.5 15.1668 31.5H11.6668V21Z" fill="url(#paint3_radial_279_11872)"/>
            <Path d="M39.6669 54.8333H49.0003V58.3332H39.6669V54.8333Z" fill="url(#paint4_linear_279_11872)"/>
            <Path d="M11.6668 30.3333L11.6668 21L15.1668 21L15.1668 30.3333H11.6668Z" fill="url(#paint5_linear_279_11872)"/>
            <Path d="M11.6668 36.1668C11.6668 38.0998 13.2338 39.6668 15.1668 39.6668C23.5431 39.6668 30.3335 46.4572 30.3335 54.8335C30.3335 56.7665 31.9005 58.3335 33.8335 58.3335H39.6668V54.8335C39.6668 41.3025 28.6978 30.3335 15.1668 30.3335H11.6668V36.1668Z" fill="url(#paint6_radial_279_11872)"/>
            <Path d="M30.3336 54.8335H39.6669V58.3335H33.8336C31.9006 58.3335 30.3336 56.7665 30.3336 54.8335Z" fill="url(#paint7_radial_279_11872)"/>
            <Path d="M15.1668 39.6668C13.2338 39.6668 11.6668 38.0998 11.6668 36.1668L11.6668 30.3335L15.1668 30.3335L15.1668 39.6668Z" fill="url(#paint8_radial_279_11872)"/>
            <Defs>
                <RadialGradient id="paint0_radial_279_11872" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(15.1667 54.8334) rotate(-90) scale(43.1667 43.1667)">
                <Stop offset="0.770277" stopColor="#FF4000"/>
                <Stop offset="1" stopColor="#8754C9"/>
                </RadialGradient>
                <LinearGradient id="paint1_linear_279_11872" x1="48.4168" y1="56.5835" x2="58.3335" y2="56.5835" gradientUnits="userSpaceOnUse">
                <Stop stopColor="#FF4000"/>
                <Stop offset="1" stopColor="#8754C9"/>
                </LinearGradient>
                <LinearGradient id="paint2_linear_279_11872" x1="13.4167" y1="11.6667" x2="13.4167" y2="21.5834" gradientUnits="userSpaceOnUse">
                <Stop stopColor="#8754C9"/>
                <Stop offset="1" stopColor="#FF4000"/>
                </LinearGradient>
                <RadialGradient id="paint3_radial_279_11872" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(15.1668 54.8333) rotate(-90) scale(33.8333 33.8333)">
                <Stop offset="0.723929" stopColor="#FFF700"/>
                <Stop offset="1" stopColor="#FF9901"/>
                </RadialGradient>
                <LinearGradient id="paint4_linear_279_11872" x1="39.6669" y1="56.5833" x2="49.0003" y2="56.5833" gradientUnits="userSpaceOnUse">
                <Stop stopColor="#FFF700"/>
                <Stop offset="1" stopColor="#FF9901"/>
                </LinearGradient>
                <LinearGradient id="paint5_linear_279_11872" x1="13.4168" y1="30.3333" x2="13.4168" y2="21" gradientUnits="userSpaceOnUse">
                <Stop stopColor="#FFF700"/>
                <Stop offset="1" stopColor="#FF9901"/>
                </LinearGradient>
                <RadialGradient id="paint6_radial_279_11872" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(15.1668 54.8335) rotate(-90) scale(24.5 24.5)">
                <Stop offset="0.59513" stopColor="#00AAFF"/>
                <Stop offset="1" stopColor="#01DA40"/>
                </RadialGradient>
                <RadialGradient id="paint7_radial_279_11872" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(29.7502 56.5835) scale(9.91667 26.4444)">
                <Stop stopColor="#00AAFF"/>
                <Stop offset="1" stopColor="#01DA40"/>
                </RadialGradient>
                <RadialGradient id="paint8_radial_279_11872" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(13.4168 40.2502) rotate(-90) scale(9.91667 188.049)">
                <Stop stopColor="#00AAFF"/>
                <Stop offset="1" stopColor="#01DA40"/>
                </RadialGradient>
            </Defs>
        </Svg>
    )
};

export default RainBowIcon;