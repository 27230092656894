import React from 'react';
import {FlatList, Image, ScrollView, Text, View} from 'react-native';
import Loader from './Loader';
import MyButton from './MyButton';
import css from '../css';
import InfoModal from './InfoModal';
import { cssConfig } from '../css/cssConfig';

const Select = props => {
  const [showOpt, updateOpt] = React.useState(false);
  const onPressOpt = props.onPress;
  const wdPart = props.extraProps?.widthPart;
  const toggleSelect = () => {
    updateOpt(!showOpt);
  }

  const handleOpt = React.useCallback((arg) => {
    onPressOpt?.(arg);
    updateOpt(false);
  },[updateOpt, onPressOpt])

  const closeOpt = React.useCallback(()=> {
    updateOpt(false);
  }, [updateOpt])

  const getStyle = (type)=> {
    if (type == 'btn') {
      let st = [css.btn, css.row, css.hCenter];
      if (props.style) {
        return Array.isArray(props.style) ? [...st, ...props.style] : [...st, props.style];
      }
      return st;
    } else {
      let st = [css.textBlack, css.setWidth(100, '%'), css.textStyle];
      if (props.textStyle) {
        return Array.isArray(props.textStyle) ? [...st, ...props.textStyle] : [...st, props.textStyle];
      }
      return st;
    }
  }

  const renderOptions = (item, index)=> {
    return (
      <MyButton
        key={item.label}
        onPress={handleOpt}
        arg={item}
        style={[
          css.setHeight(40),
          css.hCenter,
          index < 1 && css.setBorderTop(1, '#ddd'),
          css.setBorderBottom(1, '#ddd'),
          css.hPadLg
        ]}
      >
          <Text style={[css.setWidth(100, '%'), css.textStyle]}>
            {item.label}
          </Text>
      </MyButton>
    )
  };

  const getBtnLabel = ()=> {
    const defaultStr = props.placeholder || 'Select';
    if (props.value) {
      const item = props.list.find((res)=> res.value == props.value);
      return item ? item.label : defaultStr;
    }
    return defaultStr
  }

  return (
    <>
      <View style={props.wrapperStyle}>
        {props.isLoading ? (
          <Loader loading={true} />
        ) : (
          <MyButton
            style={getStyle('btn')}
            onPress={toggleSelect}
          >
            <Text style={getStyle()}>
              {getBtnLabel()}
            </Text>
            <View style={[css.pl, css.center]}>
                <Image
                  source={require("../images/down-arrow.png")}
                  style={[css.setSize(props.iconSize || 14), css.imgContain]}
                />
            </View>
          </MyButton>
        )}
      </View>
      <InfoModal 
        show={showOpt}
        onClose={closeOpt}
        headerTitle={props.headerTitle}
        headerTitleStyle={[css.fs18, css.textLightBold]}
        {...props.extraProps}
      >
        <ScrollView style={[css.setWidth(wdPart ? cssConfig.width()/wdPart : cssConfig.width()), css.setMaxHeight(200)]}>
          <View style={[css.vPadLg]}>
            {props.list.map(renderOptions)}
          </View>
        </ScrollView>
      </InfoModal>
    </>
  );
};

export default Select;
