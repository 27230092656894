import React from 'react';
import {
  Text,
  Pressable,
  Image,
  ActivityIndicator,
  Animated,
} from 'react-native';
import css from '../css';
import {appTheme} from '../config/theme';
import GIcons from '../images/GIcons';

const rowBased = [
  'rightTextIcon',
  'leftTextIcon',
  'rightImage',
  'leftImage',
  'leftVar',
  'rightVar',
  'checkbox',
];

const MyButton = props => {
  const UI = appTheme()
  const onTouch = () => {
    if (props.arg) {
      props.onPress?.call(this, props.arg, props.name);
    } else {
      props.onPress?.call(this, props.name);
    }
  };

  const getIconView = React.useCallback((data, iconColor) => {
    return null;
  }, []);

  const getImageView = React.useCallback(data => {
    return <Image source={data.source} style={data.imgStyle} />;
  }, []);

  const getVar = React.useCallback(data => {
    return data.source || null;
  }, []);

  const getLayout = pressed => {
    if (props.children) {
      return props.children;
    }
    let iconColor = props.theme ? css.textWhite : props.iconStyle;
    const title = props.title ? (
      <Text style={getWrapperStyle('text')}>{props.title}</Text>
    ) : null;
    switch (props.type) {
      case 'rightTextIcon':
        return (
          <>
            {getIconView(props, iconColor)}
            {title}
          </>
        );
      case 'leftTextIcon':
        return (
          <>
            {title}
            {getIconView(props, iconColor)}
          </>
        );
      case 'image':
        return getImageView(props);
      case 'leftVar':
        return (
          <>
            {getVar(props)}
            {title}
          </>
        );
      case 'rightVar':
        return (
          <>
            {title}
            {getVar(props)}
          </>
        );
      case 'imageText':
      case 'leftImage':
        return (
          <>
            {getImageView(props)}
            {title}
          </>
        );
      case 'rightImage':
        return (
          <>
            {title}
            {getImageView(props)}
          </>
        );
      case 'checkbox':
        return (
          <>
            <GIcons
              name={
                props.checked ? 'checkbox_Check' : 'checkbox'
              }
              height={props.iconH}
              width={props.iconW}
              stroke={props.color || "red"}
              style={props.iconStyle}
            />
            {title}
          </>
        );
      case 'icon':
        return getIconView(props, iconColor);
      case 'loading':
        return (
          <>
            <ActivityIndicator
              size={props.loaderSize || 'small'}
              color={props.iconColor || '#0000ff'}
              style={props.loaderStyle}
            />
            {title}
          </>
        );
      default:
        return title;
    }
  };

  const getWrapperStyle = (target, pressed) => {
    if (target == 'text') {
      const st = props.theme ? [css.themeBtnText] : [css.textStyle];
      return UI.mergeArray(st, props.textStyle);
    } else {
      let st = props.theme ? [css.themeBtnBg, css.btn, css.shadow] : [css.br, css.vCenter];
      if (rowBased.includes(props.type)) {
        st.push(css.row);
      }
      st = UI.mergeArray(st, props.style);
      return [
        ...st,
        css.setOpacity(pressed ? 0.7 : 1),
        props.disabled && css.disable,
      ];
    }
  };

  if (props.hide) return null;

  return (
    <Pressable
      testID={props.testID}
      onPress={onTouch}
      style={({pressed}) => getWrapperStyle('wrapper', pressed)}
      pointerEvents='box-only'
      disabled={props.disabled || false}
    >
      {({pressed}) => getLayout(pressed)}
    </Pressable>
  );
};

export default MyButton;
