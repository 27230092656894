import { ScrollView, TextInput, View } from "react-native"
import MyText from "../../libs/MyText"
import Select from "../../libs/Select"
import React from "react"
import css from "../../css"
import MyButton from "../../libs/MyButton"
import { cssConfig } from "../../css/cssConfig"
import { ApiCall } from "../../api"
import Loader from "../../libs/Loader"
import AppContext from "../../context/AppContext"

const Reports = (props)=> {
    const _params = props.route.params || {};
    const [cats, setCats] = React.useState([]);
    const [_form, setForm] = React.useState({});
    const [isLoading, setLoading] = React.useState(false);
    const context = React.useContext(AppContext);
    const wd = cssConfig.isWeb ? cssConfig.width()/2.5 : cssConfig.width();

    React.useEffect(()=> {
        setForm({'user_uuid': _params.uuid})
        setCats([
            {
                label: "Duplicate Profile",
                value: "Duplicate Profile"
            },
            {
                label: "Fraud Profile",
                value: "Fraud Profile"
            }
        ])
    }, [_params.uuid]);

    const onSubmit = () => {
        console.log(_form);
        setLoading(true);
        ApiCall('/report-user', {method: 'POST'}, _form)
        .then((res)=> {
            if (res) {
                context.showInfoModal({
                    title: 'Success',
                    subTitle: 'Report subbmited',
                    onOk: ()=> props.navigation.goBack()
                })
            }
        })
        .catch((err)=> {
            console.log(err);
            context.showInfoModal({
                title: 'Warning',
                subTitle: 'Please check internet'
            })
        })
        .finally(()=> { setLoading(false) })
    }

    const handleInput = (val) => {
        setForm({..._form, 'description': val})
    }

    const handleSelect = (data)=> {
        setForm({..._form, 'category': data.value})
    }

    return (
        <ScrollView style={css.bgWhite}>
            <View style={[css.setPadding(5,5,5,5, '%'), css.setWidth(wd), css.selfCenter]}>
                <Loader loaading={isLoading} style={[css.pLg]} />
                <MyText title="Select Category" style={[css.fs16, css.textLight]}/>
                <View style={[css.themeTextBox, css.vMarginLg]}>
                    <Select 
                        onPress={handleSelect}
                        value={_form.category}
                        headerTitle="Select Category"
                        list={cats}
                        placeholder="Category"
                        style={[css.setHeight(35), css.setWidth(100, '%')]}
                        extraProps={cssConfig.isWeb ? {center:true, widthPart: 3, viewStyle: 'fade'} : {viewStyle: 'slide'} }
                    />
                </View>
                <View style={[css.pbLg]}>
                    <TextInput
                        style={[css.themeTextBox, css.setHeight(75), css.themeNormalText, css.pMd]}
                        onChangeText={handleInput}
                        placeholder="Enter description"
                        placeholderTextColor={cssConfig.placeHolderColor}
                        maxLength={300}
                        numberOfLines={2}
                        multiline={true}
                    />
                </View>
                <MyButton
                    theme={true}
                    title="Submit"
                    onPress={onSubmit}
                    style={[css.setHeight(40)]}
                />
            </View>
        </ScrollView>
    )
}

export default Reports;