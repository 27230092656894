const { Svg, G, Path, ClipPath, Rect, Defs } = require("react-native-svg")

const AlertTriangle = (props) => {
    return (
        <Svg width={props.width || "21"} height={props.height || "20"} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <G id="alert-triangle" clipPath="url(#clip0_1376_768)">
                <Path id="Icon" d="M10.1664 7.49998V10.8333M10.1664 14.1666H10.1748M9.01254 3.24308L2.15847 15.0819C1.7783 15.7386 1.58821 16.0669 1.61631 16.3364C1.64081 16.5714 1.76395 16.785 1.95509 16.924C2.17422 17.0833 2.5536 17.0833 3.31237 17.0833H17.0205C17.7793 17.0833 18.1587 17.0833 18.3778 16.924C18.5689 16.785 18.6921 16.5714 18.7166 16.3364C18.7447 16.0669 18.5546 15.7386 18.1744 15.0819L11.3203 3.24308C10.9415 2.58878 10.7521 2.26163 10.505 2.15175C10.2895 2.0559 10.0434 2.0559 9.82786 2.15175C9.58075 2.26163 9.39135 2.58878 9.01254 3.24308Z" stroke={props.stroke || "#6941C6"} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
            </G>
            <Defs>
                <ClipPath id="clip0_1376_768">
                    <Rect width={props.rectW || "20"} height={props.rectH || "20"} fill="white" transform="translate(0.166656)"/>
                </ClipPath>
            </Defs>
        </Svg>
    )
}

export default AlertTriangle;
