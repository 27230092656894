import {Dimensions, Platform, StatusBar} from 'react-native';
const statusBarHeight = () => (Platform.OS === 'ios' ? 0 : StatusBar.currentHeight);

export const cssConfig = {
  themeBtnBgColor: '#7F56D9',
  themeBtnText: '#fff',
  themeBtnLightBgColor: '#EBF6FE',
  themeFadeBg: '#f9f9f9',
  themeBgColor: '#9E77ED',
  themeBgDark: '#6941C6',
  themeBgLight: '#F4EBFF',
  themeBorderColor: '#D0D5DD',
  themeLineHeight: 24,
  textColor: '#344054', 
  themeContent: '#485467',
  placeHolderColor: '#9aa1b1',
  bgColor: '#FAFAFA',
  textHeadingColor: '#6941C6',
  themeTextColor: '#E9D7FE',
  fontFamily: 'Inter',
  bgWhite: '#f2f2f2',
  borderColor: '#E5E5E5',
  switchTrackColor: {false: '#E0E0E0', true: '#A2B7D9'},
  switchIOSBackgroundColor: '#E0E0E0',
  switchThumbActive: '#005AFF',
  switchThumbFalse: '#737373',
  navTitleFont: 18,
  ios: Platform.OS === 'ios',
  isWeb: Platform.OS === 'web',
  width: getWidth,
  statusBarHeight,
  height: getHeight,
};

export const colors = {
  textGray: '#737373',
  nativeBgDefault: '#f2f2f2',
  themeBgColor: '#004d9b',
  themeColor: '#004d9b',
  primary: '#1292B4',
  whiteOff: '#FAFAFA',
  lighter: '#F3F3F3',
  light: '#DAE1E7',
  dark: '#444',
  darker: '#222',
  aliceblue: '#f0f8ff',
  antiquewhite: '#faebd7',
  aqua: '#00ffff',
  aquamarine: '#7fffd4',
  azure: '#f0ffff',
  beige: '#f5f5dc',
  bisque: '#ffe4c4',
  black: '#000000',
  blanchedalmond: '#ffebcd',
  blue: '#0000ff',
  blueviolet: '#8a2be2',
  brown: '#a52a2a',
  burlywood: '#deb887',
  cadetblue: '#5f9ea0',
  chartreuse: '#7fff00',
  chocolate: '#d2691e',
  coral: '#ff7f50',
  cornflowerblue: '#6495ed',
  cornsilk: '#fff8dc',
  crimson: '#dc143c',
  cyan: '#00ffff',
  darkblue: '#00008b',
  darkcyan: '#008b8b',
  darkgoldenrod: '#b8860b',
  darkgray: '#a9a9a9',
  darkgreen: '#006400',
  darkgrey: '#a9a9a9',
  darkkhaki: '#bdb76b',
  darkmagenta: '#8b008b',
  darkolivegreen: '#556b2f',
  darkorange: '#ff8c00',
  darkorchid: '#9932cc',
  darkred: '#8b0000',
  darksalmon: '#e9967a',
  darkseagreen: '#8fbc8f',
  darkslateblue: '#483d8b',
  darkslategrey: '#2f4f4f',
  darkturquoise: '#00ced1',
  darkviolet: '#9400d3',
  deeppink: '#ff1493',
  deepskyblue: '#00bfff',
  dimgray: '#696969',
  dimgrey: '#696969',
  dodgerblue: '#1e90ff',
  firebrick: '#b22222',
  floralwhite: '#fffaf0',
  forestgreen: '#228b22',
  fuchsia: '#ff00ff',
  gainsboro: '#dcdcdc',
  ghostwhite: '#f8f8ff',
  gold: '#ffd700',
  goldenrod: '#daa520',
  gray: '#808080',
  green: '#008000',
  greenyellow: '#adff2f',
  grey: '#808080',
  honeydew: '#f0fff0',
  hotpink: '#ff69b4',
  indianred: '#cd5c5c',
  indigo: '#4b0082',
  ivory: '#fffff0',
  khaki: '#f0e68c',
  lavender: '#e6e6fa',
  lavenderblush: '#fff0f5',
  lawngreen: '#7cfc00',
  lemonchiffon: '#fffacd',
  lightblue: '#add8e6',
  lightcoral: '#f08080',
  lightcyan: '#e0ffff',
  lightgoldenrodyellow: '#fafad2',
  lightgray: '#d3d3d3',
  lightgreen: '#90ee90',
  lightgrey: '#d3d3d3',
  lightpink: '#ffb6c1',
  lightsalmon: '#ffa07a',
  lightseagreen: '#20b2aa',
  lightskyblue: '#87cefa',
  lightslategrey: '#778899',
  lightsteelblue: '#b0c4de',
  lightyellow: '#ffffe0',
  lime: '#00ff00',
  limegreen: '#32cd32',
  linen: '#faf0e6',
  magenta: '#ff00ff',
  maroon: '#800000',
  mediumaquamarine: '#66cdaa',
  mediumblue: '#0000cd',
  mediumorchid: '#ba55d3',
  mediumpurple: '#9370db',
  mediumseagreen: '#3cb371',
  mediumslateblue: '#7b68ee',
  mediumspringgreen: '#00fa9a',
  mediumturquoise: '#48d1cc',
  mediumvioletred: '#c71585',
  midnightblue: '#191970',
  mintcream: '#f5fffa',
  mistyrose: '#ffe4e1',
  moccasin: '#ffe4b5',
  navajowhite: '#ffdead',
  navy: '#000080',
  oldlace: '#fdf5e6',
  olive: '#808000',
  olivedrab: '#6b8e23',
  orange: '#ffa500',
  orangered: '#ff4500',
  orchid: '#da70d6',
  palegoldenrod: '#eee8aa',
  palegreen: '#98fb98',
  paleturquoise: '#afeeee',
  palevioletred: '#db7093',
  papayawhip: '#ffefd5',
  peachpuff: '#ffdab9',
  peru: '#cd853f',
  pink: '#ffc0cb',
  plum: '#dda0dd',
  powderblue: '#b0e0e6',
  purple: '#800080',
  rebeccapurple: '#663399',
  red: '#ff0000',
  rosybrown: '#bc8f8f',
  royalblue: '#4169e1',
  saddlebrown: '#8b4513',
  salmon: '#fa8072',
  sandybrown: '#f4a460',
  seagreen: '#2e8b57',
  seashell: '#fff5ee',
  sienna: '#a0522d',
  silver: '#c0c0c0',
  skyblue: '#87ceeb',
  slateblue: '#6a5acd',
  slategray: '#708090',
  snow: '#fffafa',
  springgreen: '#00ff7f',
  steelblue: '#4682b4',
  tan: '#d2b48c',
  teal: '#008080',
  thistle: '#d8bfd8',
  tomato: '#ff6347',
  turquoise: '#40e0d0',
  violet: '#ee82ee',
  wheat: '#f5deb3',
  white: '#ffffff',
  whitesmoke: '#f5f5f5',
  yellow: '#ffff00',
  yellowgreen: '#9acd32',
};

/* istanbul ignore next */
export function createDynamicStyle() {
  return {
    setZindex: function (n) {
      return {
        zIndex: Number(n),
      };
    },
    setBg: function (c) {
      return {
        backgroundColor: c,
      };
    },
    setOpacity: function (n) {
      return {
        opacity: Number(n),
      };
    },
    setFont: function (n) {
      return {
        fontSize: +n,
      };
    },
    getFont: function (n) {
      return +n;
    },
    setFontWeight: function (n) {
      return {
        fontWeight: n,
      };
    },
    setColor: function (col) {
      return {
        color: col,
      };
    },
    setRound: function (size) {
      return {
        height: +size,
        width: +size,
        borderRadius: +(size / 2),
      };
    },
    setRadius: function () {
      if (arguments.length > 2) {
        let [topL, topR, bottomL, bottomR] = arguments;
        return {
          borderTopLeftRadius: Number(topL),
          borderTopRightRadius: Number(topR),
          borderBottomLeftRadius: Number(bottomL),
          borderBottomRightRadius: Number(bottomR),
        };
      } else {
        let [size, place] = arguments;
        switch (place) {
          case 'topLeft':
            return {borderTopLeftRadius: size};
          case 'topRight':
            return {borderTopRightRadius: size};
          case 'bottomLeft':
            return {borderBottomLeftRadius: size};
          case 'bottomRight':
            return {borderBottomRightRadius: size};
          case 'topStart':
            return {borderTopStartRadius: size};
          case 'bottomStart':
            return {borderBottomStartRadius: size};
          case 'topEnd':
            return {borderTopEndRadius: size};
          case 'bottomEnd':
            return {borderBottomEndRadius: size};
          default:
            return {borderRadius: size};
        }
      }
    },
    setMargin: function () {
      if (arguments.length > 3) {
        let [top, left, bottom, right, isPercent] = arguments;
        if (isPercent) {
          return {
            marginTop: `${top || 0}%`,
            marginLeft: `${left || 0}%`,
            marginRight: `${right || 0}%`,
            marginBottom: `${bottom || 0}%`,
          };
        } else {
          return {
            marginTop: Number(top || 0),
            marginLeft: Number(left || 0),
            marginRight: Number(right || 0),
            marginBottom: Number(bottom || 0),
          };
        }
      } else {
        let [n, place, isPercent] = arguments;
        switch (place) {
          case 'top':
            return {marginTop: isPercent ? `${n}%` : n};
          case 'left':
            return {marginLeft: isPercent ? `${n}%` : n};
          case 'right':
            return {marginRight: isPercent ? `${n}%` : n};
          case 'bottom':
            return {marginBottom: isPercent ? `${n}%` : n};
          default:
            return {margin: isPercent ? `${n}%` : n};
        }
      }
    },
    setPadding: function () {
      if (arguments.length > 3) {
        let [top, left, bottom, right, isPercent] = arguments;
        return {
          paddingTop: isPercent ? `${top}%` : top,
          paddingLeft: isPercent ? `${left}%` : left,
          paddingRight: isPercent ? `${right}%` : right,
          paddingBottom: isPercent ? `${bottom}%` : bottom,
        };
      } else {
        let [n, place, isPercent] = arguments;
        switch (place) {
          case 'top':
            return {paddingTop: isPercent ? `${n}%` : n};
          case 'left':
            return {paddingLeft: isPercent ? `${n}%` : n};
          case 'right':
            return {paddingRight: isPercent ? `${n}%` : n};
          case 'bottom':
            return {paddingBottom: isPercent ? `${n}%` : n};
          default:
            return {padding: isPercent ? `${n}%` : n};
        }
      }
    },
    setVmargin: function (n, isPercent) {
      return {
        marginVertical: isPercent ? `${n}%` : n,
      };
    },

    setHmargin: function (n, isPercent) {
      return {
        marginHorizontal: isPercent ? `${n}%` : n,
      };
    },

    setHpadding: function (n, isPercent) {
      return {
        paddingHorizontal: isPercent ? `${n}%` : n,
      };
    },

    setVpadding: function (n, isPercent) {
      return {
        paddingVertical: isPercent ? `${n}%` : n,
      };
    },

    setBorder: function (n, color, place) {
      if (typeof n == 'object') {
        return {
          borderColor: color,
          borderLeftWidth: n.left,
          borderTopWidth: n.top,
          borderRightWidth: n.right,
          borderBottomWidth: n.bottom,
        }
      } else {
        switch (place) {
          case 'top':
            return {
              borderTopWidth: n,
              borderTopColor: color,
            };
          case 'left':
            return {
              borderLeftWidth: n,
              borderLeftColor: color,
            };
          case 'right':
            return {
              borderRightWidth: n,
              borderRightColor: color,
            };
          case 'bottom':
            return {
              borderBottomWidth: n,
              borderBottomColor: color,
            };
          default:
            return {
              borderWidth: n,
              borderColor: color,
            };
        }
      }
      
    },
    setAllBorder: function (n, color, type) {
      return {
        borderLeftWidth: n,
        borderLeftColor: color,
        borderTopWidth: n,
        borderTopColor: color,
        borderRightWidth: n,
        borderRightColor: color,
        borderBottomWidth: n,
        borderBottomColor: color,
      };
    },
    setBorderTop: function (n, color) {
      return {
        borderTopWidth: n,
        borderTopColor: color,
      };
    },

    setBorderLeft: function (n, color) {
      return {
        borderLeftWidth: n,
        borderLeftColor: color,
      };
    },

    setBorderRight: function (n, color) {
      return {
        borderRightWidth: n,
        borderRightColor: color,
      };
    },

    setBorderBottom: function (n, color) {
      return {
        borderBottomWidth: n,
        borderBottomColor: color,
      };
    },
    setSize: function (n) {
      return {
        width: n,
        height: n,
      };
    },
    setScreen: function (_width, _height, isPercent) {
      return {
        width: isPercent ? _width + '%' : _width || getWidth(),
        height: isPercent ? _height + '%' : _height || getHeight(),
      };
    },
    setWidth: function (n, isPercent) {
      return {
        width: isPercent ? n + '%' : n || getWidth(),
      };
    },

    setHeight: function (n, isPercent) {
      return {
        height: isPercent ? n + '%' : n || getHeight(),
      };
    },

    setAuto: function (type) {
      switch (type) {
        case 'height':
          return {height: 'auto'};
        case 'width':
          return {width: 'auto'};
        default:
          return {width: '100%', height: 'auto'};
      }
    },

    setMinMaxHeight: function (min, max) {
      return {
        minHeight: min,
        maxHeight: max,
      };
    },

    setMinMaxWidth: function (min, max) {
      return {
        minWidth: min,
        maxWidth: max,
      };
    },

    setMinWidth: function (n) {
      return {
        minWidth: n,
      };
    },

    setMinHeight: function (n) {
      return {
        minHeight: n,
      };
    },

    setMaxWidth: function (n) {
      return {
        maxWidth: n,
      };
    },

    setMaxHeight: function (n) {
      return {
        maxHeight: n,
      };
    },

    setFlex: function (_value) {
      return {
        flex: _value,
      };
    },

    setTop: function (top, isPercent) {
      return {
        top: isPercent ? top + '%' : Number(top),
      };
    },

    setLeft: function (left, isPercent) {
      return {
        left: isPercent ? left + '%' : Number(left),
      };
    },

    setRight: function (right, isPercent) {
      return {
        right: isPercent ? right + '%' : Number(right),
      };
    },

    setBottom: function (bottom, isPercent) {
      return {
        bottom: isPercent ? bottom + '%' : Number(bottom),
      };
    },

    setPosition: function (top, left, isPercent) {
      return {
        top: isPercent ? top + '%' : Number(top),
        left: isPercent ? left + '%' : Number(left),
      };
    },

    setLineHeight: function (n) {
      return {
        lineHeight: n,
      };
    },

    setCenter: function (type) {
      switch (type) {
        case 'v':
          return {alignItems: 'center'};
        case 'h':
          return {justifyContent: 'center'};
        case 'self':
          return {alignSelf: 'center'};
        default:
          return {alignItems: 'center', justifyContent: 'center'};
      }
    },

    setItem: function (type) {
      switch (type) {
        case 'hStart':
          return {alignItems: 'flex-start'};
        case 'hEnd':
          return {alignItems: 'flex-end'};
        case 'vCenter':
          return {alignItems: 'center'};
        case 'hStretch':
          return {alignItems: 'stretch'};
        case 'vStart':
          return {justifyContent: 'flex-start'};
        case 'vEnd':
          return {justifyContent: 'flex-end'};
        case 'vCenter':
          return {justifyContent: 'center'};
        case 'vGap':
          return {justifyContent: 'space-between'};
        case 'vAround':
          return {justifyContent: 'space-around'};
        case 'selfStart':
          return {alignSelf: 'flex-start'};
        case 'selfEnd':
          return {alignSelf: 'flext-end'};
        case 'selfCenter':
          return {alignSelf: 'center'};
        case 'selfStretch':
          return {alignSelf: 'stretch'};
        default:
          return {alignItems: 'center', justifyContent: 'center'};
      }
    },

    setTextAlign: function (type) {
      // type - 'auto', 'top', 'bottom', 'center', 'justify'
      return {
        textAlign: type,
      };
    },

    setCharSpace: function (num) {
      return {
        letterSpacing: num,
      };
    },
  };
}

/* istanbul ignore next */
function getWidth() {
  return Dimensions.get('window').width;
}

function getHeight() {
  if (Platform.OS === 'web') {
    return Dimensions.get('window').height;
  }
  return (Dimensions.get('window').height -  statusBarHeight())
}
