import { Svg, Path, G } from "react-native-svg";
const TwitterIcon = (props) => {
    return (
        <Svg width={props.width || "25"} height={props.height || "24"} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <G id="Icons/Social 25px/Twitter">
                <Path id="Twitter" fillRule="evenodd" clipRule="evenodd" d="M22.1856 4.80005C21.4335 5.23408 19.9244 5.82561 19.0372 5.99622C19.0112 6.00279 18.99 6.01122 18.9649 6.01779C18.1809 5.26596 17.1067 4.80005 15.9178 4.80005C13.5215 4.80005 11.5785 6.68899 11.5785 9.01853C11.5785 9.14134 11.5679 9.36726 11.5785 9.48726C8.34522 9.48726 5.88437 7.84111 4.11972 5.73749C3.92783 6.20621 3.84394 6.94679 3.84394 7.64237C3.84394 8.95573 4.89983 10.2456 6.54394 11.0453C6.24115 11.1212 5.90751 11.1756 5.56037 11.1756C5.00012 11.1756 4.40708 11.0322 3.86419 10.5972C3.86419 10.6131 3.86419 10.6281 3.86419 10.645C3.86419 12.4805 5.86797 13.7301 7.64997 14.0779C7.28837 14.2851 6.55937 14.3057 6.20355 14.3057C5.95283 14.3057 5.06569 14.1941 4.82847 14.151C5.32412 15.6556 7.1119 16.5012 8.8158 16.5312C7.48315 17.5474 6.5584 17.9242 3.82947 17.9242H2.8999C4.62405 18.9985 6.81972 19.8 9.02022 19.8C16.1849 19.8 20.257 14.4913 20.257 9.48726C20.257 9.40664 20.2551 9.2379 20.2522 9.06822C20.2522 9.05135 20.257 9.03541 20.257 9.01853C20.257 8.99322 20.2493 8.96885 20.2493 8.94354C20.2464 8.81605 20.2435 8.69699 20.2407 8.63512C21.0024 8.10078 21.663 7.43426 22.1856 6.67493C21.4865 6.97679 20.7363 7.17928 19.9485 7.27114C20.7527 6.80242 21.8954 5.68499 22.1856 4.80005Z" fill={props.color || "#006BFF"}/>
            </G>
        </Svg>
    )

}

export default TwitterIcon;