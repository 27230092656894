import React from "react";
import { Image, ScrollView, TextInput, View, Linking } from "react-native";
import css from "../../css";
import MyText from "../../libs/MyText";
import MyButton from "../../libs/MyButton";
import { cssConfig } from "../../css/cssConfig";
import QrIcon from "../../images/QrIcon";
import linkIcon from '../../images/link.png';
import avatarIcon from '../../images/AvatarIcon.png';
import { ApiCall, BaseUrl, hostBase, getStoreData } from "../../api";
import InfoModal from "../../libs/InfoModal";
import { GetModalData, ProfileHeader, GetField, getLabel, GetSocialUI, AddInfo, checkFollower } from "./ProfileUI";
import { signInFacebook, twitterSign } from "./SocialWebProfile";
import { onDeleteApi, qrValue, saveEdits, sendEmail, sendOtp } from "./profileConfig";
import Loader from "../../libs/Loader";
import { appTheme } from "../../config/theme";
import AppContext from "../../context/AppContext";
import QRCode from "react-qr-code";
import InstagramLogin from "react-instagram-oauth";
import { instaConfig } from "../../config/appData";

const Profile = ({ navigation, route }) => {
    const [_user, setUser] = React.useState({});
    const [_profile, setProfile] = React.useState({});
    const [_layout, setLayout] = React.useState('');
    const [showModal, updateModal] = React.useState(false);
    const [_form, setForm] = React.useState({});
    const [socialData, setSocialData] = React.useState([]);
    const [modalVal, setModalVal] = React.useState('');
    const [isLoading, setLoading] = React.useState(true);
    const [progress, setProgress] = React.useState(false);
    const [selectedImage, setSelectedImage] = React.useState(null);
    const [linkUrl, setLinkUrl] = React.useState('');
    const _param = route.params;
    const hiddenFileInput = React.useRef(null);
    const UI = appTheme();
    const context = React.useContext(AppContext);
    const instaRef = React.useRef('');
    const [isError, setError] = React.useState('');

    const isEditable = ()=> {
        if (context.isLogin && (_param.edit || context.isAdmin)) return true;
        return false;
    }

    const getData = (data) => {
        const api = `/profile/${isEditable() ? data.username : _param.username}`;
        ApiCall(api).then((res) => {
            if (res.status) {
                isEditable() && context.updateUser(res.data);
                setProfile({ ...res.data });
            } else {
                setError(res.error)
            } 
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            setLoading(false);
        })
    }

    React.useEffect(() => {
        setLoading(true);
        getStoreData('user').then((data) => {
            setUser(data);
            getData(data);
        });
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_param, context.profileUpdate]);

    const openContacts = () => {
        navigation.navigate('Contacts');
    }

    const handleAction = (arg) => {
        setLayout(arg)
        updateModal(true);
    }

    const closeModal = () => {
        updateModal(false);
        getData(_user);
    }

    const updateLinkIcon = (filePath, id) => {
        ApiCall('/upload-link-icon',
            { method: 'POST' },
            { 'link_icon': filePath, link_id: id }
        )
            .then((res) => {
                if (res.status) {
                    setLinkUrl(res.image);
                }
            })
            .catch((err) => {
                console.log(err);
                context.showInfoModal({
                    title: 'Warning',
                    subTitle: 'Unable to upload profile image. Please try again later.',
                })
            }).finally(() => {
                setProgress(false);
            })
    }

    const changePic = (file) => {
        //console.log(file);
        ApiCall('/upload-profile-pic',
            { method: 'POST' },
            { 'profile_pic': file }
        )
            .then((res) => {
                if (res.status) {
                    getData(_user);
                    context.showInfoModal({
                        title: 'Success',
                        subTitle: 'Profile image updated successfully.',
                    })
                } else {
                    context.showInfoModal({
                        title: 'Warning',
                        subTitle: 'Unable to upload profile image, Image should be png and less than 5MB.',
                    })
                }
            })
            .catch((err) => {
                console.log(err);
                context.showInfoModal({
                    title: 'Warning',
                    subTitle: 'Unable to upload profile image, Image should be png and less than 5MB.',
                })
            }).finally(() => {
                setProgress(false);
            })
    }

    const onImgChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            UI.getBase64(file).then((res) => {
                if (_layout == 'link') {
                    updateLinkIcon(res, modalVal.link_id)
                } else {
                    changePic(res);
                }
            }).catch((err) => {
                updateModal(false);
                context.showInfoModal({
                    title: 'Warning',
                    subTitle: 'Unable to upload profile image, Image should be png and less than 2MB.',
                })
            })
        }
    }

    const exploreImage = (arg) => {
        hiddenFileInput.current.click();
    }

    const nextStep = (arg) => {
        setModalVal(arg);
        if (arg.email_address) {
            sendEmail(arg.email_address).then((status) => {
                if (status) {
                    handleAction('emailOtp');
                } else {
                    context.showInfoModal({
                        title: 'Warning',
                        subTitle: 'Please check your email. we are unable to send email',
                    })
                }
            })
        } else {
            sendOtp(arg.phone_num, arg.phone_cc).then((status) => {
                if (status) {
                    handleAction('otp');
                } else {
                    context.showInfoModal({
                        title: 'Warning',
                        subTitle: 'Please check your phone. we are unable to send otp',
                    })
                }
            })
        }
    }

    const onSave = (apiName, req, method, nextData = false) => {
        saveEdits(apiName, req, method)
            .then((res) => {
                if (res.status) {
                    if (nextData) {
                        nextStep(nextData);
                    } else {
                        context.showInfoModal({
                            title: 'Success!',
                            subTitle: 'Data saved sucessfully!'
                        })
                    }
                    getData(_user);
                } else {
                    updateModal(false);
                    context.showInfoModal({
                        title: 'Warning!',
                        subTitle: res.error || 'Unable to save, Input is invalid or already exist.'
                    })
                }
            })
            .catch((err) => {
                updateModal(false);
                context.showInfoModal({
                    title: 'Warning',
                    subTitle: 'Unable to save, Input is invalid or already exist.'
                })
            })
    }

    const handleSave = (arg) => {
        closeModal();
        switch (arg.name) {
            case 'newEmail': {
                const apiName = `/user-emails`;
                const req = {
                    email_address: arg.data[arg.name],
                    email_visibility: arg.data?.visiblity
                };
                onSave(apiName, req, 'POST', req);
            };
                break;
            case 'email': {
                const apiName = `/user-emails/${arg.data.email_id}`;
                const req = {
                    email_address: arg.data[arg.name],
                    email_visibility: arg.data?.visiblity
                };
                onSave(apiName, req, 'PUT');
            }
                break;
            case 'newPhone': {
                const apiName = `/user-phones`
                const req = {
                    phone_num: arg.data[arg.name],
                    phone_visibility: arg.data?.visiblity,
                    phone_cc: arg.data.phone_cc,
                    country_code: arg.data?.country_code
                };
                onSave(apiName, req, 'POST', req);
            };
                break;
            case 'verifyLink': {
                const apiName = `/verify-link`;
                const req = {
                    profile_url: `${hostBase}/profile/${_user.username}`,
                    link_id: arg.data?.link_id,
                };
                onSave(apiName, req, 'POST');
            }
                break;
            case 'verifyEmailOtp':
            case 'verifyOtp':
                context.showInfoModal({
                    title: 'Success',
                    subTitle: arg.name == 'verifyOtp' ? 'Phone Verfified!!' : 'Email Verfified!!',
                });
                break;
            case 'phone': {
                const apiName = `/user-phones/${arg.data?.phone_id}`;
                const req = {
                    phone_num: arg.data[arg.name],
                    phone_visibility: arg.data?.visiblity,
                    phone_cc: arg.data.phone_cc,
                    country_code: arg.data?.country_code
                };
                onSave(apiName, req, 'PUT');
            }
                break;
            case 'newLink': {
                const apiName = `/user-links`;
                const req = {
                    link_url: arg.data[arg.name],
                    link_visibility: arg.data?.visiblity,
                };
                onSave(apiName, req, 'POST');
            };
                break;
            case 'link': {
                const apiName = `/user-links/${arg.data?.link_id}`;
                const req = {
                    link_url: arg.data[arg.name],
                    link_visibility: arg.data?.visiblity,
                    link_icon: linkUrl || arg.data?.link_icon,
                };
                onSave(apiName, req, 'POST');
            }
                break;
        }
    }

    const onDelete = (arg) => {
        onDeleteApi(arg).then(res => {
            if (res.status) {
                getData(_user);
            } else {
                context.showInfoModal({
                    title: 'Warning',
                    subTitle: 'Unable to delete. Please try again later'
                })
            }
        })
            .catch(err => {
                console.log(err);
                context.showInfoModal({
                    title: 'Warning',
                    subTitle: 'Internet connection error. Please try again later'
                })
            })
    }

    const checkDelete = (arg) => {
        context.showInfoModal({
            title: 'Warning',
            subTitle: 'Are you sure want to remove this item?',
            onOk: () => { onDelete(arg) },
        })
    }

    const disconnectProfile = (id) => {
        ApiCall(`/social-profiles/${id}`, { method: 'DELETE' })
            .then((res) => {
                navigation.navigate("Profile", { edit: true, username: context.user?.username })
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const connectProfile = (data) => {
        ApiCall('/social-profiles', { method: 'POST' }, data)
            .then((res) => {
                getData(_user);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const instaLogin = (err, data) => {
        console.log(err, data);
    }

    const handleSocial = (arg) => {
        switch (arg.icon) {
            case 'fb':
                signInFacebook().then((res) => {
                    const user = res.user;
                    if (user) {
                        const nm = user.displayName.split(' ');
                        // user.uid
                        connectProfile({
                            profile_type: 'facebook',
                            profile_username: user.displayName,
                            profile_fname: nm[0],
                            profile_lname: nm[1],
                            profile_pic: user.photoURL,
                        })
                    }
                }).catch((err) => {
                    console.log(err);
                })
                break;
            case 'twitter':
                twitterSign().then((res) => {
                    const user = res?.user?.reloadUserInfo;
                    if (user) {
                        const nm = user.displayName.split(' ');
                        connectProfile({
                            profile_type: 'twitter',
                            profile_username: user.screenName,
                            profile_fname: nm[0],
                            profile_lname: nm[1],
                            profile_pic: (user.providerUserInfo[0].photoUrl) ? user.providerUserInfo[0].photoUrl : user.photoUrl,
                        })
                    }
                }).catch((err) => {

                    console.log('tweeterr', err);
                })
                break;
            case 'mastodon': navigation.navigate('SocialConnect', { ..._user, ..._param });
                break;
            case 'instagram':
                instaRef.current.handleLoginClick();
                break;
        }
    }

    const handleEdit = (data) => {
        if (data.social) {
            disconnectProfile(data.arg?.id)
        } else {
            if (data.type == 'verifyEmail') {
                context.setLoading(true);
                sendEmail(data.arg?.email_address).then((status) => {
                    context.setLoading(false);
                    if (status) {
                        setModalVal({ ...data.arg, [data.type]: data.btnLabel });
                        handleAction(data.type)
                    } else {
                        context.showInfoModal({
                            title: 'Warning',
                            subTitle: 'Please check your email. we are unable to send email',
                        })
                    }
                })
            } else if (data.type == 'verifyLink') {
                console.log(data);
                setModalVal({ ...data.arg, [data.type]: data.type});
                handleAction(data.type)
            } else {
                let val = data.btnLabel;
                if (data.type == 'phone') {
                    val = data.arg.phone_num;
                }
                setModalVal({ ...data.arg, [data.type]: val, country_code: data.arg.country_code });
                handleAction(data.type)
            }
        }
    }

    const handleHeaderAction = (arg) => {
        switch (arg) {
            case 'report': navigation.navigate('Reports', _profile);
                break;
            case 'share':
                const url = `${hostBase}/profile/${isEditable() ? _user.username : _param.username}`;
                context.showInfoModal({
                    title: 'Copy your profile link',
                    subTitle: url,
                    onOk: () => { },
                })
                break;
            case 'unfollow':
                ApiCall('/unfollow-user', {method: 'POST'}, {uuid_to_unfollow: _profile.uuid})
                .then((res)=> {
                    if (res.status) {
                        getData(_user);
                        context.showInfoModal({
                            title: 'Success',
                            subTitle: 'User unfollowed.'
                        })
                    }
                })
                .catch((err)=> {
                    console.log(err);
                })
                break;
            case 'follow':
                ApiCall('/follow-user', { method: 'POST' }, { following_user_id: _profile.uuid })
                    .then((res) => {
                        if (res.status) {
                            context.showInfoModal({
                                title: 'Followed',
                                subTitle: 'You are now following this profile.'
                            })
                        } else {
                            context.showInfoModal({
                                title: 'Warning',
                                subTitle: res.error
                            })
                        }
                    });
                break;
            case 'contacts': navigation.navigate('Contacts', _profile);
                break;
            case 'locationUpdate': navigation.navigate('EditInfo', _profile);
                break;
        }
    }

    if (isLoading) {
        return (<Loader loading={true} style={[css.pLg]} />)
    }

    if (isError) {
        return (
            <View style={[css.setPadding(5,5,5,5, '%'), css.bgWhite]}>
                <MyText 
                    title={isError} 
                    style={[css.textOffDark, css.fs18]}
                />
            </View>
        )
    }

    return (
        <ScrollView style={css.bgWhite}>
            <ProfileHeader
                title={getLabel(`${_profile.fname} ${_profile.lname}`)}
                data={_profile}
                user={context.user}
                location={`${_profile.country || 'Country'}, ${_profile.city || 'City'}`}
                followers={_profile.followers_count?.toString?.()}
                followed={_profile.following_count?.toString?.()}
                edit={isEditable()}
                openContacts={openContacts}
                handleHeaderAction={handleHeaderAction}
                isFollowed={checkFollower(_profile, context.user)}
                exploreImage={exploreImage}
                isLogin={context.isLogin}
            />
            <Loader loading={progress} style={[css.pLg]} />
            <View style={[css.center, css.mtlg, css.setWidth(100, '%')]}>
                <QRCode
                    value={qrValue({ ..._user, ..._param })}
                    size={150}
                />
            </View>
            <View style={[css.setPadding(5, 5, 5, 5, '%')]}>
                <View style={[css.center, css.setWidth(100, '%')]}>
                    {getLabel("Emails")}
                    {_profile.user_emails.map((item) =>
                        <GetField
                            key={item.email_id}
                            btnLabel={item.email_address}
                            handleEdit={handleEdit}
                            arg={item}
                            edit={isEditable()}
                            type={item.status ? "email" : "verifyEmail"}
                            onDelete={checkDelete}
                            style={[css.mt]}
                        />
                    )}
                    <AddInfo
                        label="+ Add Email"
                        onTouch={handleAction}
                        arg={'newEmail'}
                        edit={isEditable()}
                    />
                </View>
                <View style={[css.center, css.mtlg, css.setWidth(100, '%')]}>
                    {getLabel("Phone numbers")}
                    {_profile.user_phones.map((item) =>
                        <GetField
                            key={item.phone_id}
                            btnLabel={`+${item.phone_cc} ${item.phone_num}`}
                            handleEdit={handleEdit}
                            arg={item}
                            edit={isEditable()}
                            type={item.status ? "phone" : "verifyPhone"}
                            onDelete={checkDelete}
                            style={[css.mt]}
                        />
                    )}
                    <AddInfo
                        label="+ Add Phone Number"
                        onTouch={handleAction}
                        arg={'newPhone'}
                        edit={isEditable()}
                    />
                </View>
                <View style={[css.center, css.mtlg, css.setWidth(100, '%')]}>
                    {getLabel("Links")}
                    {_profile.user_links.map((item) =>
                        <GetField
                            key={item.link_id}
                            btnLabel={item.link_url}
                            handleEdit={handleEdit}
                            source={(item.link_icon) ? `${BaseUrl}/${item.link_icon}` : linkIcon}
                            arg={item}
                            style={[css.mt]}
                            edit={isEditable()}
                            type={item.status ? "link" : "verifyLink"}
                            onDelete={checkDelete}
                        />
                    )}
                    <AddInfo
                        label="+ Add Link"
                        onTouch={handleAction}
                        arg={'newLink'}
                        edit={isEditable()}
                    />
                </View>
                <View style={[css.center, css.mtlg, css.setWidth(100, '%')]}>
                    {getLabel("Social media")}
                    {_profile.social_profiles.map((item) => (
                        <GetField
                            key={item.id}
                            btnLabel={`@${item.profile_username}`}
                            source={item.profile_pic ? { uri: `${item.profile_pic}` } : avatarIcon}
                            handleEdit={handleEdit}
                            arg={item}
                            social={true}
                            type={item.profile_type}
                            socialTriger={true}
                            edit={isEditable()}
                            style={[css.mt]}
                        />
                    ))}
                </View>
                {isEditable() ? <GetSocialUI handleSocial={handleSocial} data={_profile.social_profiles} /> : null}
            </View>
            <View>
                <input
                    ref={hiddenFileInput}
                    type="file"
                    onChange={onImgChange}
                    accept="image/*"
                    style={{ display: "none" }}
                />
            </View>
            <InfoModal
                show={showModal}
                onClose={closeModal}
                viewStyle="fade"
                widthPart={3}
                center={true}
                headerIcon={require('../../images/plus.png')}
                iconSize={25}
            >
                <GetModalData
                    name={_layout}
                    data={modalVal}
                    handleSave={handleSave}
                    closeModal={closeModal}
                    exploreImage={exploreImage}
                />
            </InfoModal>
            <View style={[css.absolute, css.setOpacity(0), css.setLeft(-1000)]}>
                <InstagramLogin
                    ref={instaRef}
                    buttonTheme="simple"
                    authCallback={instaLogin}
                    appId={instaConfig.appId}
                    appSecret={instaConfig.appSecret}
                    redirectUri={instaConfig.callBack}
                />
            </View>
        </ScrollView>
    )
}

export default Profile;