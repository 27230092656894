import {StyleSheet, StatusBar} from 'react-native';
import {colors, createDynamicStyle, cssConfig} from './cssConfig';

export default StyleSheet.create({
  ...createDynamicStyle(),
  safeContainer: {
    flex: 1,
    paddingTop: StatusBar.currentHeight,
  },
  absoluteCenter: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  container: {
    flex: 1,
    padding: 10,
  },
  flex1: {
    flex: 1,
  },
  centeredView: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  webCenteredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center'
  },
  headerBtn: {
    height: 40,
    paddingHorizontal: 10,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  absolute: {position: 'absolute'},
  imgContain: {resizeMode: 'contain'},
  imgCover: {resizeMode: 'cover'},
  imgRepeat: {resizeMode: 'repeat'},
  imgStretch: {resizeMode: 'stretch'},
  imgCenter: {resizeMode: 'center'},
  wAuto: {width: 'auto'},
  hAuto: {height: 'auto'},
  fullWidth: {width: '100%'},
  fullHeight: {height: '100%'},
  center: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  hCenter: {justifyContent: 'center'},
  vCenter: {alignItems: 'center'},
  selfCenter: {alignSelf: 'center'},
  selfStart: {alignSelf: 'flex-start'},
  selfEnd: {alignSelf: 'flex-end'},
  atEnd: {alignItems: 'flex-end'},
  atStart: {alignItems: 'flex-start'},
  row: {flexDirection: 'row'},
  rowReverse: {flexDirection: 'row-reverse'},
  alignCorner: {justifyContent: 'space-between'},
  alignStart: {justifyContent: 'flex-start'},
  alignEnd: {justifyContent: 'flex-end'},
  alignAround: {justifyContent: 'space-around'},
  alignEven: {justifyContent: 'space-evenly'},
  wrap: {flexWrap: 'wrap'},
  m: {margin: 5},
  msm: {margin: 10},
  mlg: {margin: 30},
  mbxm: {marginBottom: 3},
  mtxm: {marginTop: 3},
  mrxm: {marginRight: 3},
  mlxm: {marginLeft: 3},
  mb: {marginBottom: 5},
  mt: {marginTop: 5},
  mr: {marginRight: 5},
  ml: {marginLeft: 5},
  mbsm: {marginBottom: 10},
  mtsm: {marginTop: 10},
  mrsm: {marginRight: 10},
  mlsm: {marginLeft: 10},
  mbmd: {marginBottom: 20},
  mtmd: {marginTop: 20},
  mrmd: {marginRight: 20},
  mlmd: {marginLeft: 20},
  mblg: {marginBottom: 30},
  mtlg: {marginTop: 30},
  mrlg: {marginRight: 30},
  mllg: {marginLeft: 30},
  mbxl: {marginBottom: 40},
  mtxl: {marginTop: 40},
  mrxl: {marginRight: 40},
  mlxl: {marginLeft: 40},
  vMarginSm: {marginVertical: 3},
  vMarginMd: {marginVertical: 5},
  vMarginLg: {marginVertical: 10},
  hMarginSm: {marginHorizontal: 3},
  hMarginMd: {marginHorizontal: 5},
  hMarginLg: {marginHorizontal: 10},
  pr: {paddingRight: 5},
  prSm: {paddingRight: 3},
  prMd: {paddingRight: 10},
  pl: {paddingLeft: 5},
  plSm: {paddingLeft: 3},
  plMd: {paddingLeft: 10},
  pt: {paddingTop: 5},
  ptSm: {paddingTop: 3},
  ptMd: {paddingTop: 10},
  ptLg: {paddingTop: 15},
  ptXLg: {paddingTop: 20},
  pb: {paddingBottom: 5},
  pbSm: {paddingBottom: 3},
  pbMd: {paddingBottom: 10},
  pbLg: {paddingBottom: 15},
  pbXLg: {paddingBottom: 20},
  vPadSm: {paddingVertical: 5},
  vPadMd: {paddingVertical: 10},
  vPadLg: {paddingVertical: 15},
  hPadSm: {paddingHorizontal: 5},
  hPadMd: {paddingHorizontal: 10},
  hPadLg: {paddingHorizontal: 15},
  pSm: {padding: 3},
  np: {padding: 0},
  p: {padding: 5},
  pMd: {padding: 10},
  pLg: {padding: 15},
  pXlg: {padding: 20},
  fs10: {fontSize: 10},
  fs12: {fontSize: 12},
  fs14: {fontSize: 14},
  fs16: {fontSize: 16},
  fs18: {fontSize: 18},
  fs20: {fontSize: 20},
  fs22: {fontSize: 22},
  fs24: {fontSize: 24},
  fs26: {fontSize: 26},
  fs27: {fontSize: 27},
  textUnderline: {
    textDecorationColor: colors.themeColor,
    textDecorationLine: 'underline',
    textDecorationStyle: 'solid',
  },
  textCenter: {textAlign: 'center'},
  textJustify: {textAlign: 'justify'},
  textAlignV: {textAlignVertical: 'top'},
  textRight: {textAlign: 'right'},
  textLeft: {textAlign: 'left'},
  introHeadingSize: {fontSize: 30},
  textLineSm: {lineHeight: 16},
  textLineMd: {lineHeight: 20},
  textThemeLine: {lineHeight: cssConfig.themeLineHeight},
  textStyle: {fontFamily: cssConfig.fontFamily},
  textBold: {fontWeight: 'bold'},
  textLightBold: {fontWeight: '600'},
  textLight: {fontWeight: '500'},
  noBg: {backgroundColor: 'transparent'},
  bgDark: {backgroundColor: '#4d4d4d'},
  bgWhite: {backgroundColor: colors.white},
  bgCyan: {backgroundColor: 'cyan'},
  btnLightBg: {backgroundColor: cssConfig.themeBtnLightBgColor},
  btnFadeBg: {backgroundColor: cssConfig.themeFadeBg},
  offBg: {backgroundColor: colors.whiteOff},
  themeBg: {backgroundColor: cssConfig.themeBgColor},
  themeBgLight: {backgroundColor: cssConfig.themeBgLight},
  themeRevertBg: {backgroundColor: cssConfig.textHeadingColor},
  brSm: {borderRadius: 3},
  br: {borderRadius: 5},
  brMd: {borderRadius: 10},
  brLg: {borderRadius: 15},
  textRegular: {
    fontFamily: cssConfig.fontFamily,
    fontWeight: 'normal',
  },
  textMedium: {
    fontFamily: cssConfig.fontFamily,
    fontWeight: '500',
  },
  themeHeadingText: {
    fontFamily: cssConfig.fontFamily,
    fontSize: 30,
    fontWeight: 'bold',
    color: cssConfig.textHeadingColor,
  },
  themeNormalText: {
    fontFamily: cssConfig.fontFamily,
    fontWeight: 'normal',
    fontSize: 16,
  },
  themeTextBox: {
    borderColor: cssConfig.themeBorderColor,
    borderWidth: 1,
    borderRadius: 8,
    fontFamily: cssConfig.fontFamily,
    fontSize: 16,
    padding: 5,
  },
  textThemeColor: {color: cssConfig.themeTextColor},
  textContent: {color: cssConfig.themeContent}, 
  textWhite: {color: colors.white},
  textDark: {color: '#212121'},
  textOffDark: {color: '#a6a6a6'},
  textGray: {color: colors.textGray},
  textBlack: {color: colors.dark},
  textColor: {color: cssConfig.textColor},
  themeTextColor: {color: cssConfig.themeTextColor},
  textHeading: {color: cssConfig.textHeadingColor},
  themeBorder: {
    borderWidth: 1,
    borderColor: cssConfig.themeBorderColor,
  },
  colorBorder: {
    borderWidth: 1,
    borderColor: '#d8440e',
  },
  textBoxBorderColor: {
    borderColor: cssConfig.themeBorderColor,
    borderWidth: 1,
    borderRadius: 8,
  },
  pageIcons: {
    width: 110,
    height: 110,
  },
  modalView: {
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  listItem: {
    padding: 5,
    marginBottom: 20,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 3,
  },
  listImg: {
    width: 80,
    height: 80,
  },
  themeBtnText: {
    color: cssConfig.themeBtnText,
    fontFamily: cssConfig.fontFamily,
    fontWeight: 'normal',
  },
  btn: {
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    paddingHorizontal: 15,
  },
  themeBtnBg: {
    backgroundColor: cssConfig.themeBtnBgColor,
  },
  shadow: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
  },
  disable: {
    opacity: 0.4,
  },
  noRadius: {
    borderRadius: 0,
  },
});
