import AsyncStorage from '@react-native-async-storage/async-storage';
import NetInfo from "@react-native-community/netinfo";
import { appTheme } from '../config/theme';
import { cssConfig } from '../css/cssConfig';
const urlHub = {
    dev: "https://theinternetphonebook.com:82",
    prod: "https://theinternetphonebook.com:82",
    prodImg: "https://theinternetphonebook.com:82",
    devImg: "",
};
export const hostBase = 'https://theinternetphonebook.com:82';
export const BaseUrl = urlHub.prod;
export const imgPrefix = urlHub.prodImg;
const baseApiUrl = `${BaseUrl}/api`;

/*
 method: 'POST', // *GET, POST, PUT, DELETE, etc.
 mode: 'cors', // no-cors, *cors, same-origin
 cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
 credentials: 'same-origin', // include, *same-origin, omit//
 headers: {
 'Content-Type': 'application/json'
 // 'Content-Type': 'application/x-www-form-urlencoded || multipart/form-data || text/plain',
 },
 redirect: 'follow', // manual, *follow, error
 referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
 body: JSON.stringify(data) // body data type must match "Content-Type" header if form-data then pass in OBJ
 */
const bodyRequire = ['POST', 'PATCH', 'PUT'];
export async function ApiCall(endPoint = "", options = {}, _data = false) {
    const UI = appTheme();
    const reqHeader = new Headers();
    reqHeader.append('Content-Type', 'application/json');
    const accKey = await UI.accessKey();
    //console.log({ accKey })

    if (!options.login) {
        reqHeader.append('Authorization', accKey);
    }
    const reqOpt = {
        method: options.method || 'GET',
        headers: reqHeader,
        redirect: 'follow',
        //mode: 'no-cors'
    };
    if (bodyRequire.includes(options.method) && _data) {
        // body data type must match "Content-Type" header
        reqOpt.body = JSON.stringify(_data)
    }
    try {
        let netState = await NetInfo.fetch();
        if (netState.isConnected) {
            //console.log('reqOpt', reqOpt)
            const res = await fetch(baseApiUrl + endPoint, reqOpt);
            if (options.plain) {
                const resText = await res.text();
                return Promise.resolve({ status: res.status, text: resText })
            } else {
                return res.json();
            }
        } else {
            return Promise.reject(503);
        }
    } catch (err) {
        return Promise.reject(err);
    }
}

export async function uploadImg(endPoint = "", _data) {
    const UI = appTheme();
    const reqHeader = new Headers();
    const accKey = await UI.accessKey();
    reqHeader.append('Content-Type', "multipart/form-data");
    reqHeader.append('Authorization', accKey);

    const reqOpt = {
        method: 'POST',
        headers: reqHeader,
        body: getFile(_data)
    };
    try {
        let netState = await NetInfo.fetch();
        if (netState.isConnected) {
            const res = await fetch(baseApiUrl + endPoint, reqOpt);
            return res.json();
        } else {
            return Promise.reject(503);
        }
    } catch (err) {
        return Promise.reject(err);
    }
}

function getFile(data) {
    const body = new FormData();
    if (cssConfig.isWeb) {
        body.append(data.name, {
            name: data.imgName,
            type: 'image/png',
            uri: data.file,
        });
        return body;
    } else {
        const fileuri = cssConfig.ios ? data.file.replace("file://", "") : data.file;
        body.append(data.name, {
            name: data.imgName,
            type: 'image/png',
            uri: fileuri,
        });
        return body;
    }
}


export async function StoreData(_key, value, isTheme) {
    const UI = appTheme();
    try {
        const jsonValue = (typeof value == 'object') ? JSON.stringify(value) : value;
        if (isTheme) UI.setData(_key, jsonValue);
        await AsyncStorage.setItem(_key, jsonValue);
    } catch (e) {
        // saving error
        console.log(e);
    }
}

export async function getStoreData(_key) {
    try {
        const _jsonValue = await AsyncStorage.getItem(_key);
        try {
            return JSON.parse(_jsonValue)
        } catch (err) {
            return _jsonValue;
        }
    } catch (e) {
        // saving error
        console.log(e);
    }
}

export function goHome(navRef) {
    navRef.reset({
        index: 0,
        routes: [{ name: 'Home' }]
    });
}

export function checkRules(input, value) {
    let result = "";
    switch (input.rule) {
        case 'email': {
            let reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            let res = reg.test(value);
            if (!res) {
                result = "Please enter a valid email";
            }
        }
            break;
        case 'number': {
            if (isNaN(value)) {
                result = "Please enter numbers only."
            }
        }
            break;
    }

    return result;
}