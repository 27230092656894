import React from 'react';
import { View, TouchableOpacity, Image } from 'react-native';
import css from '../css';
import MyButton from '../libs/MyButton';
import { BackIcon } from '../images/mIcons';
import { cssConfig } from '../css/cssConfig';
import logoIcon from '../images/logo.png';

const HeaderLeft = (props) => {
    const onBack = ()=> {
        props.navigation.goBack(null);
    }

    const checkBack = () => {
        return (props.route.name != "Home" && !cssConfig.isWeb);
    }

    const toggleDrawer = () => {
        props.navigation.reset({
            index: 0,
            routes: [{name: 'Home'}]
        });
    }

    return(
        <View style={[css.row, css.vCenter, cssConfig.isWeb && css.setPadding(25, 'left', '%')]}>
           { checkBack() ? 
                <MyButton 
                    style={[css.center]}
                    onPress={onBack} 
                >
                    <BackIcon height={34} width={34} />
                </MyButton> 
                : null
            }
           <TouchableOpacity 
                onPress={toggleDrawer} 
                style={[css.headerBtn]}
            >
                <Image
                    source={logoIcon}
                    style={[
                        css.imgContain,
                        css.setScreen(checkBack() ? 80 : 100, 50)
                    ]}
                />
            </TouchableOpacity>
        </View>
    )
}

export default HeaderLeft;