import React from "react";
import { ScrollView, TextInput, View } from "react-native";
import MyText from "../../libs/MyText";
import css from "../../css";
import MyButton from "../../libs/MyButton";
import { ApiCall, StoreData } from "../../api";
import { appTheme } from "../../config/theme";
import { cssConfig } from "../../css/cssConfig";
import CountryPicker from 'react-native-country-picker-modal'
import OTPbox from "./OTPbox";
import Warn from "../../libs/Warn";
import AppContext from "../../context/AppContext";

const Login = ({ navigation, route }) => {
    const UI = appTheme();
    const [countryCode, setCountryCode] = React.useState('US');
    const [cc, setCC] = React.useState('1');
    const [phoneNumber, setPhone] = React.useState('');
    const [showOtp, setShow] = React.useState(false);
    const [errObj, setErr] = React.useState({});
    const webCss = [css.setWidth(45, '%'), css.selfCenter];
    const appStore = React.useContext(AppContext);
    React.useEffect(() => {
        UI.accessKey('eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1dWlkIjoiMDg5MmIxMTgtODU2ZS00YTE1LWFmMGMtNjhoM2Q0YTI4ZWVkIiwidHlwZSI6MiwiZm5hbWUiOiJzdWRoaXIiLCJsbmFtZSI6ImJoYWRhdXJpYSIsInVzZXJuYW1lIjoic2JoYWRhdXJpYSIsInByb2ZpbGVfcGljIjpudWxsLCJwcmltYXJ5X3Bob25lIjoiODU4NjA3OTQ3OSIsInByb2ZpbGVfdmlzaWJpbGl0eSI6MSwicHJpbWFyeV9waG9uZV92aXNpYmlsaXR5IjowfQ.ZTaK9r8OR1Op_0t_GpPvWLszRgg1IK-UnhSHESfo2Gs');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleInput = (name) => {
        return (value) => {
            setPhone(value);
        }
    }

    const onSelectCountry = (country) => {
        console.log(country);
        setCountryCode(country.cca2);
        setCC(country.callingCode[0] || 0);
    }

    const toggleOtp = () => {
        setShow(!showOtp);
    }

    const onVerify = (res) => {
        console.log(res);
        appStore.onLogin(res);
        navigation.navigate('Profile', {edit: true, username: res.username});
    }

    const onSubmit = () => {
        
        ApiCall('/send-otp',
            { method: 'POST' },
            { phone: phoneNumber, cc: cc }
        ).then((res) => {
            if (res.status) {
                setErr({ 'login': '' });
                toggleOtp();
            } else {
                setErr({ 'login': res.error });
            }
            console.log(res);
        }).catch((err) => {
            console.log(err);
        })
    }

    if (showOtp) {
        return (
            <View style={[css.center]}>
                <OTPbox
                    show={showOtp}
                    cc={cc}
                    phone={phoneNumber}
                    toggleOtp={toggleOtp}
                    showInfoModal={appStore.showInfoModal}
                    onVerify={onVerify}
                />
            </View>
        )
    }

    return (
        <ScrollView style={[css.bgWhite]}>
            <View
                style={[
                    css.setPadding(5, 5, 5, 5, '%'),
                    cssConfig.isWeb ? [...webCss] : null,
                ]}
            >
                <View style={[css.setPadding(24, 'bottom')]}>
                    <MyText
                        theme="heading"
                        title="Enter your phone number"
                        style={[css.textBlack, css.setFont(20)]}
                    />
                </View>
                <View style={[css.row]}>
                    <CountryPicker
                        //visible={show}
                        countryCode={countryCode}
                        onSelect={onSelectCountry}
                        withFlag={true}
                        withFilter={true}
                        withEmoji={true}
                        withAlphaFilter={false}
                        withCallingCode={false}
                        containerButtonStyle={[
                            css.setHeight(40),
                            css.setWidth(50),
                            css.setRadius(4, 0, 4, 0),
                            css.setBg(cssConfig.themeBgLight),
                            css.setBorder({ top: 1, left: 1, bottom: 1, right: 0 }, '#ccc'),
                            css.center,
                        ]}
                    />
                    <TextInput
                        value={phoneNumber}
                        onChangeText={handleInput('phone')}
                        style={[
                            css.themeTextBox,
                            css.plMd,
                            css.setHeight(40),
                            css.setRadius(0, 4, 0, 4),
                            css.flex1
                        ]}
                    />
                </View>
                <MyButton
                    theme={true}
                    title={"Submit"}
                    onPress={onSubmit}
                    style={[css.setWidth(100, '%'), css.setHeight(40), css.mtlg]}
                    disabled={!phoneNumber}
                />
                <Warn data={errObj} name="login" style={[css.pt]} />
            </View>
        </ScrollView>
    )
}

export default Login;