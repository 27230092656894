import React from "react";
import { Linking, ScrollView, TextInput, View } from "react-native";
import MyText from "../../libs/MyText";
import css from "../../css";
import { cssConfig } from "../../css/cssConfig";
// import FireWebApp from "../../config/FireWebApp";
import MyButton from "../../libs/MyButton";
import { ApiCall } from "../../api";
import CountryPicker from 'react-native-country-picker-modal'
import Warn from "../../libs/Warn";
import Loader from "../../libs/Loader";
import OTPbox from "./OTPbox";
import AppContext from "../../context/AppContext";

const Register = ({ navigation, route }) => {
    const [_formObj, setForm] = React.useState({ cc: '1' });
    const [countryCode, setCountryCode] = React.useState('US');
    const [errObj, setErr] = React.useState({});
    const [isLoading, setLoading] = React.useState(false);
    const [showOtp, setShow] = React.useState(false);
    const _param = route.params;
    const context = React.useContext(AppContext);

    const handleInput = (name) => {
        return (value) => {
            setForm({ ..._formObj, [name]: value });
        }
    }

    const toggleOtp = () => {
        setShow(!showOtp);
    }

    const openLogin = () => {
        navigation.navigate('Login');
    }

    const onSelectCountry = (country) => {
        console.log(country);
        setCountryCode(country.cca2);
        setForm({ ..._formObj, cc: country.callingCode[0] || 0 });
    }

    const onVerify = (status) => {
        // eslint-disable-next-line no-undef
        if (_formObj.phone && _formObj.cc && _formObj.lname && _formObj.fname) {
            setLoading(true);
            ApiCall('/register', { method: 'POST' }, _formObj)
                .then((res) => {
                    if (res.status) {
                        setErr({})
                        setTimeout(() => {
                            context.onLogin(res.data);
                            //navigation.navigate('Home');
                            navigation.navigate('Profile', {edit: true, username: res.data.username});
                        }, 2000);
                    } else {
                        setErr({ register: "The phone has already been taken." })
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setErr({ register: "An Error occured. Please check internet" })
                })
                .finally(() => { setLoading(false) })
        } else {
            setErr({ register: "Please fill all fields." })
        }
    }

    const sendOtp = () => {

        setLoading(true);
        ApiCall('/send-otp-register', { method: 'POST' }, { phone: _formObj.phone, cc: _formObj.cc })
            .then((res) => {
                console.log(res);
                if (res.status) {
                    setErr({})
                    toggleOtp();
                } else {
                    setErr({ 'otp': res.error });
                }
            })
            .catch((err) => {
                console.log(err);
                setErr({ register: "An Error occured. Please check internet" })
            })
            .finally(() => { setLoading(false) })
    }

    const openLink = (isPrivacy) => {
        if (isPrivacy) {
            Linking.openURL('https://theinternetphonebook.com/privacy.html')
        } else {
            Linking.openURL('https://theinternetphonebook.com/terms.html')
        }
    }

    const onRegister = () => {
        setLoading(true);
        ApiCall('/register', { method: 'POST' }, _formObj)
            .then((res) => {
                if (res.status) {
                    setErr({})
                    sendOtp(res);
                } else {
                    setErr({ register: "The phone has already been taken." })
                }
            })
            .catch((err) => {
                console.log(err);
                setErr({ register: "An Error occured. Please check internet" })
            })
            .finally(() => { setLoading(false) })
    }

    if (showOtp) {
        return (
            <View style={[css.center, css.bgWhite]}>
                <OTPbox
                    show={showOtp}
                    cc={_formObj.cc}
                    phone={_formObj.phone}
                    toggleOtp={toggleOtp}
                    onVerify={onVerify}
                />
            </View>
        )
    }

    return (
        <ScrollView style={[css.bgWhite]}>
            <View style={[css.setPadding(5, 5, 5, 5, '%'), css.setWidth(45, '%'), css.selfCenter]}>
                <View>
                    <MyText
                        theme="heading"
                        title="Create an account"
                        style={[css.textBlack, css.textLight, css.setFont(30)]}
                    />
                </View>
                <View style={[css.ptXLg]}>
                    <MyText
                        title="First Name*"
                        style={[css.textColor, css.mb, css.fs14]}
                    />
                    <TextInput
                        onChangeText={handleInput('fname')}
                        placeholder="Enter your name"
                        placeholderTextColor={cssConfig.placeHolderColor}
                        style={[css.themeTextBox, css.plMd, css.setHeight(40)]}
                    />
                </View>
                <View style={[css.ptXLg]}>
                    <MyText
                        title="Last Name*"
                        style={[css.textColor, css.mb, css.fs14]}
                    />
                    <TextInput
                        onChangeText={handleInput('lname')}
                        placeholder="Enter your name"
                        placeholderTextColor={cssConfig.placeHolderColor}
                        style={[css.themeTextBox, css.plMd, css.setHeight(40)]}
                    />
                </View>
                <View style={[css.ptXLg]}>
                    <MyText
                        title="Phone Number*"
                        style={[css.textColor, css.mb, css.fs14]}
                    />
                    <View style={[css.row]}>
                        <CountryPicker
                            //visible={show}
                            countryCode={countryCode}
                            onSelect={onSelectCountry}
                            withFlag={true}
                            withFilter={true}
                            withEmoji={true}
                            withAlphaFilter={false}
                            withCallingCode={false}
                            containerButtonStyle={[
                                css.setHeight(40),
                                css.setWidth(50),
                                css.setRadius(4, 0, 4, 0),
                                css.setBg(cssConfig.themeBgLight),
                                css.setBorder({ top: 1, left: 1, bottom: 1, right: 0 }, '#ccc'),
                                css.center,
                            ]}
                        />
                        <TextInput
                            onChangeText={handleInput('phone')}
                            placeholderTextColor={cssConfig.placeHolderColor}
                            placeholder="Enter your phone number"
                            style={[
                                css.themeTextBox,
                                css.plMd,
                                css.setHeight(40),
                                css.setRadius(0, 4, 0, 4),
                                css.flex1
                            ]}
                        />
                    </View>
                </View>
                <View style={[css.ptXLg, css.row]}>
                    <MyText
                        title="To learn more, see "
                        nestedStyle={[css.setColor(cssConfig.themeBtnBgColor)]}
                        style={[css.textColor, css.fs12]}
                    />
                    <MyText
                        title="Terms of Service"
                        nestedTitle=" and "
                        nestedStyle={[css.textColor]}
                        style={[css.fs12, css.setColor(cssConfig.themeBtnBgColor)]}
                        onPress={openLink}
                    />
                    <MyText
                        title="Privacy Policy."
                        style={[css.fs12, css.setColor(cssConfig.themeBtnBgColor)]}
                        onPress={openLink}
                        arg={'privacy'}
                    />
                </View>
                <View style={[css.ptXLg]}>
                    <MyText
                        title="By continuing your mobile number, you agree to receive SMS for verification."
                        style={[css.textColor, css.fs12]}
                    />
                </View>
                <MyButton
                    theme={true}
                    title="Send Code"
                    onPress={sendOtp}
                    style={[css.setHeight(40), css.setWidth(100, '%'), css.mtmd]}
                    textStyle={[css.fs16, css.textLight]}
                />
                <Loader loading={isLoading} style={[css.ptLg]} />
                <Warn name="register" data={errObj} style={[css.ptLg]} />
                <View style={[css.row, css.center, css.ptXLg]}>
                    <MyText
                        title="Already have an account? "
                        style={css.textColor}
                    />
                    <MyText
                        title="Log in"
                        onPress={openLogin}
                        style={[css.setColor(cssConfig.themeBgDark), css.textLight]}
                    />
                </View>
            </View>
        </ScrollView>
    )
}

export default Register;