import { TextInput, View } from "react-native";
import css from "../../css";
import MyText from "../../libs/MyText";
import React from "react";
import { colors, cssConfig } from "../../css/cssConfig";
import MyButton from "../../libs/MyButton";
import { ApiCall } from "../../api";
import AppContext from "../../context/AppContext";
const otpArr = ['otp1', 'otp2', 'otp3', 'otp4', 'otp5', 'otp6'];
const OTPbox = (props) => {
    const otpRefs = React.useRef(otpArr.map(() => React.createRef()));
    const [isError, setErr] = React.useState(false);
    const [otp, setOtp] = React.useState([]);
    const [isLoading, setLoading] = React.useState(false);
    const phonelastDigit = props.phone?.slice?.(-4) || '0000';
    console.log('props::', props);
    const editPhone = () => {
        props.toggleOtp(false);
    }

    const sendAgain = (context) => {
        let apiName = '/send-otp';
        let req = {
            phone: props.phone,
            cc: props.cc,
        };
        setLoading(true);
        if (props.email) {
            req = {
                email: props.email,
            }
            apiName = '/send-email-otp';
        } else if (props.type == "newPhone") {
            req = {
                phone_num: props.phone,
                phone_cc: props.cc,
            }
            apiName = '/send-phone-otp';
        }

        ApiCall(apiName, { method: 'POST' }, req)
            .then((res) => {
                console.log('apiName', res)
                if (!props.embed) {
                    if (res.status) {
                        context.showInfoModal?.({ title: "Otp sent.", subTitle: "Please check your message." });
                    } else {
                        context.showInfoModal?.("Unable to sent OTP. Please check phone number");
                    }
                }
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            })
    }

    const onVerify = (context) => {
        let apiName = '/verify-otp';
        let req = {
            phone: props.phone,
            cc: props.cc,
            otp: otp.join('')
        };
        if (props.email) {
            req = {
                email: props.email,
                otp: otp.join('')
            }
            apiName = '/verify-email-otp';
        } else if (props.type == "newPhone") {
            req = {
                phone_num: props.phone,
                phone_cc: props.cc,
                otp: otp.join('')
            }
            apiName = '/verify-phone-otp';
        }
        ApiCall(apiName, { method: 'POST' }, req)
            .then((res) => {
                if (res.status) {
                    props.onVerify(res.data)
                } else {
                    // eslint-disable-next-line no-undef
                    //props.toggleOtp()
                    if (!props.embed) context.showInfoModal?.("Otp is not valid");
                }
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
                console.log('apiNameResErr:', err)
            })
    }

    const handleOtp = (key) => {
        return (str) => {
            let tempOtp = otp;
            tempOtp[key] = +str;
            setOtp(tempOtp);
            if (str && otpRefs.current[key + 1]) {
                otpRefs.current[key + 1].current.focus();
            }
        }
    }

    const handleKey = (key) => {
        return (e)=> {
            if (e.key === "Backspace") {
                let tm = setTimeout(() => {
                    if (otpRefs.current[key - 1]) {
                        otpRefs.current[key - 1].current.focus();
                    } else {
                        otpRefs.current[key].current.blur();
                    }
                    clearTimeout(tm);
                }, 100);
            }
        }
    }

    const handleOut = (key) => {
        return () => {
            if (otpRefs.current[key + 1]) {
                otpRefs.current[key + 1].current.focus();
            } else {
                otpRefs.current[key].current.blur();
            }
        }
    }

    const otpGrid = () => {
        return (
            <View style={[css.row]}>
                {otpArr.map((item, key) => (
                    <TextInput
                        key={item}
                        ref={otpRefs.current[key]}
                        onChangeText={handleOtp(key)}
                        maxLength={1}
                        focus={key === 2}
                        returnKeyType="next"
                        onSubmitEditing={handleOut(key)}
                        blurOnSubmit={false}
                        keyboardType="phone-pad"
                        onKeyPress={handleKey(key)}
                        style={[
                            css.themeTextBox,
                            css.textCenter,
                            css.setMargin(5),
                            css.setRadius(10),
                            css.setSize(cssConfig.isWeb ? 56 : 50, '%'),
                            isError && css.setBorder(1, 'red')
                        ]}
                    />
                ))}
            </View>
        )
    }

    if (props.show) {
        const wd = cssConfig.isWeb ? cssConfig.width() / 3 : cssConfig.width();
        const pd = cssConfig.isWeb ? 2 : 5;
        const suffix = props.email ? props.email : `+${props.cc} (***) *** ${phonelastDigit}`
        return (
            <AppContext.Consumer>
                {context => (
                    <View style={[css.setWidth(), css.center]}>
                        <View style={[css.setWidth(wd), css.setPadding(pd, pd, pd, pd, '%')]}>
                            <MyText
                                title={`Confirm the ${props.email ? "email" : "phone"}`}
                                style={[css.fs18, css.textLightBold]}
                            />
                            <MyText
                                title={`Please input the 6-digit code we just send to ${suffix}.`}
                                style={[css.textContent, css.mbmd]}
                            />
                            <MyText
                                title={`Edit my ${props.email ? "email" : "mobile number"}`}
                                style={[css.mbmd, css.textBlack, css.fs16]}
                                onPress={editPhone}
                            />
                            <View>
                                <MyText title="Verification code" style={[css.textContent]} />
                                {otpGrid()}
                                <MyText
                                    title="Don't get your code? "
                                    nestedTitle="Click to Resend"
                                    style={[css.textContent]}
                                    nestedStyle={[css.setColor(colors.blue)]}
                                    arg={context}
                                    onPress={sendAgain}
                                />
                            </View>
                            <View style={[css.row, css.setPadding(20, 'top')]}>
                                <MyButton
                                    title="Cancel"
                                    onPress={props.toggleOtp}
                                    arg={context}
                                    textStyle={[css.textLightBold, css.textContent]}
                                    style={[css.setHeight(40), css.themeBorder, css.center, css.setWidth(48, '%')]}
                                />
                                <MyButton
                                    theme={true}
                                    title="Submit"
                                    onPress={onVerify}
                                    arg={context}
                                    style={[css.setHeight(40), css.themeBorder, css.setWidth(48, '%'), css.mlmd]}
                                />
                            </View>
                        </View>
                    </View>
                )}
            </AppContext.Consumer>
        )
    }

    return null;
}

export default OTPbox;