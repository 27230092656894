import React from "react";
import { FlatList, Image, View, TouchableOpacity } from "react-native";
import MyText from "../../libs/MyText";
import { ContactHeader, GetSocial } from "./ContactUI";
import css from "../../css";
// import FbTheme from "../../images/FbTheme";
// import InstaTheme from "../../images/InstaTheme";
import { dummyList, getList } from "./contactHelper";
import Loader from "../../libs/Loader";
import AppContext from "../../context/AppContext";
import { ApiCall, BaseUrl, imgPrefix } from "../../api";
import MyButton from "../../libs/MyButton";

const Contacts = (props) => {
    const [currentTab, setTab] = React.useState(1);
    const [list, setList] = React.useState([]);
    const [isLoading, setLoading] = React.useState(true);
    const _param = props.route?.params;
    const context = React.useContext(AppContext);
    const [count, setCount] = React.useState(1);
    const [searchStr, setSearchStr] = React.useState('');

    React.useEffect(() => {
        getList("following", '', _param.uuid)
            .then((res) => {
                if (res) {
                    setList(res);
                } else {
                    setList(dummyList);
                }
            })
            .catch((err) => {
                context.showInfoModal({
                    title: 'Error in network!',
                    subTitle: 'Please check your internent!'
                })
            })
            .finally(() => {
                setLoading(false);
            })
    }, [context, count, _param.uuid]);

    const onSearch = (str) => {
        setLoading(true);
        setSearchStr(str);
        getList(currentTab === 1, str, _param.uuid)
            .then((res) => {
                setList([...res]);
            })
            .catch((err) => {
                console.log(err);
                context.showInfoModal({
                    title: 'Error in network!',
                    subTitle: 'Please check your internent!'
                })
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const handleTab = (tab) => {
        setLoading(true);
        setTab(tab);
        getList(tab === 1, '', _param.uuid)
            .then((res) => {
                setList([...res]);
            })
            .catch((err) => {
                console.log(err);
                context.showInfoModal({
                    title: 'Error in network!',
                    subTitle: 'Please check your internent!'
                })
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const renderEmpty = () => {
        return (
            <View>
                <Loader loading={isLoading} style={[css.pLg]} />
                {isLoading ? null
                    : (
                        <View style={[css.pLg, css.themeBorder, css.setMargin(22)]}>
                            <MyText
                                title={"No Record found. Please try again later."}
                                style={[css.fs16, css.textGray]}
                            />
                        </View>
                    )
                }
            </View>
        )
    }

    const renderHeader = () => {
        return (
            <>
                <ContactHeader
                    title={`${_param.fname} ${_param.lname}`}
                    location={`${_param.country || 'Country'}, ${_param.city || 'City'}`}
                    data={_param}
                    handleTab={handleTab}
                    onSearch={onSearch}
                    searchStr={searchStr}
                    currentTab={currentTab}
                    following={_param.following_count.toString?.()}
                    followers={_param.followers_count.toString?.()}
                />
                <View style={[css.row, css.pLg, css.setBorderBottom(1, '#ddd'), css.hMarginLg]}>
                    <View style={[css.setWidth(30, '%')]}>
                        <MyText title="Name" />
                    </View>
                    <View style={[css.setWidth(20, '%')]}>
                        <MyText title="Phone number" />
                    </View>
                    <View style={[css.setWidth(30, '%')]}>
                        <MyText title="Socail Media" style={[css.textRight]} />
                    </View>
                    <View style={[css.setWidth(20, '%')]}>
                        <MyText title="" style={[css.textRight]} />
                    </View>
                </View>
            </>
        )
    }
    const openProfile = (arg) => {
        return () => {
            props.navigation.navigate('Profile', arg);
        }
    }

    const unfollow = (arg)=> {
        //console.log(_param.uuid);console.log(context.user?.uuid);
        ApiCall('/unfollow-user', {method: 'POST'}, {uuid_to_unfollow: arg.userId})
        .then((res)=> {
            if (res.status) {
                setCount(count+1)
                context.showInfoModal({
                    title: 'Success',
                    subTitle: 'User unfollowed.'
                })
            }
        })
        .catch((err)=> {
            console.log(err);
            context.showInfoModal({
                title: 'Warnin',
                subTitle: 'Unable to unfollow. Try again latter.'
            })
        })
    }

    const renderRow = ({ item }) => {
        return (
            <View style={[css.row, css.setBorderBottom(1, '#ddd')]}>
                <TouchableOpacity 
                    style={[
                        css.row, 
                        css.pLg, 
                        css.hMarginLg,
                        css.setWidth(98, '%')
                    ]} 
                    onPress={openProfile(item)}
                >
                    <View key={item.followers_id} style={[css.setWidth(30, '%'), css.row, css.vCenter]}>
                        <View>
                            <Image
                                source={item.profile_pic ? { uri: `${imgPrefix}/${item.profile_pic}` } : require("../../images/user.png")}
                                style={[css.setScreen(60, 60), css.imgCover, css.setRadius(30), css.setMargin(10,'right')]}
                            />
                        </View>
                        <View>
                            <MyText title={`${item.fname} ${item.lname}`} style={[css.setColor('#6941C6'), css.textLightBold]} onPress={openProfile(item)} />
                            <MyText title={`${item.country}, ${item.city}`} style={[css.textBlack]} onPress={openProfile(item)} />
                        </View>
                    </View>
                    <View style={[css.setWidth(20, '%'), css.selfCenter]}>
                        <MyText title={`+${item.primary_cc+item.primary_phone}`} style={[css.textBlack]} />
                    </View>
                    <View style={[css.setWidth(30, '%'), css.row, css.alignEnd, css.vCenter]}>
                        <GetSocial {...item} />
                    </View>
                    <View style={[css.setWidth(20, '%'), css.row, css.alignEnd]}>
                    <MyButton
                        theme={true}
                        title="Unfollow"
                        onPress={unfollow}
                        arg={item}
                        hide={currentTab == 2 || _param.uuid != context.user?.uuid}
                        style={[css.setScreen(100, 35)]}
                    />
                    </View>
                </TouchableOpacity>
            </View>
        )
    }

    return (
        <View>
            <FlatList
                data={list}
                keyExtractor={(item) => item.contactId}
                renderItem={renderRow}
                ListHeaderComponent={renderHeader}
                ListEmptyComponent={renderEmpty}
                style={[css.bgWhite]}
            />
        </View>
    )
}

export default Contacts;