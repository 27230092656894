import { initializeApp } from 'firebase/app';
import { 
    getAuth, 
    signInWithPopup, 
    FacebookAuthProvider, 
    TwitterAuthProvider, 
    signInWithRedirect,
    getRedirectResult
} from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyCDQVHL0HVJ85HnvZpYh6Jf7sBmci5bfnY",
    authDomain: "internetphonedirectory.firebaseapp.com",
    projectId: "internetphonedirectory",
    storageBucket: "internetphonedirectory.appspot.com",
    messagingSenderId: "304124948447",
    appId: "1:304124948447:web:a63c98ec32d63706c39e6a",
    measurementId: "G-0VWRGG9G88"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export const twitterRedirect = () => {
    return new Promise ((resolve, reject)=> {
        const provider = new TwitterAuthProvider();
        signInWithRedirect(auth, provider)
        .then((result)=> {
            resolve({user: result.user, userName: result.reloadUserInfo?.screenName});
        })
        .catch((error)=> {
            const credential = TwitterAuthProvider.credentialFromError(error);
            reject({credential, error});
        })
    })
}

export const instaRedirect = () => {
    return new Promise ((resolve, reject)=> {
        const provider = new TwitterAuthProvider();
        signInWithRedirect(auth, provider)
        .then((result)=> {
            resolve({user: result.user, userName: result.reloadUserInfo?.screenName});
        })
        .catch((error)=> {
            const credential = TwitterAuthProvider.credentialFromError(error);
            reject({credential, error});
        })
    })
}

export const getRedrectData = (loginType) => {
    return new Promise ((resolve, reject)=> {
        getRedirectResult(auth)
        .then((result) => {
            resolve({user: result?.user, userName: result?.reloadUserInfo?.screenName});
        }).catch((error) => {
            // Handle Errors here.
            // The AuthCredential type that was used.
            //const credential = TwitterAuthProvider.credentialFromError(error);
            // ...
            reject(error);
        });
    })
}

export const twitterSign = () => {
    return new Promise((resolve, reject)=> {
        const provider = new TwitterAuthProvider();
        signInWithPopup(auth, provider)
        .then((result) => {
            // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
            // You can use these server side with your app's credentials to access the Twitter API.
            resolve({user: result.user, userName: result.reloadUserInfo?.screenName});
        }).catch((error) => {
            // Handle Errors here.
            // The AuthCredential type that was used.
            const credential = TwitterAuthProvider.credentialFromError(error);
            reject({credential, error});
        });
    })
}

export const signInFacebook = () => {
    return new Promise((resolve, reject)=> {
        const provider = new FacebookAuthProvider();
        provider.addScope('public_profile');
    
        signInWithPopup(auth, provider)
        .then((result) => {
            // The signed-in user info.
            // const user = result.user;

            // // This gives you a Facebook Access Token. You can use it to access the Facebook API.
            // const credential = FacebookAuthProvider.credentialFromResult(result);
            // const accessToken = credential.accessToken;

            // // IdP data available using getAdditionalUserInfo(result)
            // const res = FacebookAuthProvider.getAdditionalUserInfo(result);
            //console.log(result);
            resolve(result);
        })
        .catch((error) => {
            // Handle Errors here.
            // const errorCode = error.code;
            // const errorMessage = error.message;
            // The email of the user's account used.
            // const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = FacebookAuthProvider.credentialFromError(error);
            reject(error);
        });
    })
}