import React from 'react';
import { View, Text, StyleSheet, TextInput } from 'react-native';
import { cssConfig } from '../css/cssConfig';
import css from '../css';
import MyButton from '../libs/MyButton';
import GIcons from '../images/GIcons';
import AppContext from '../context/AppContext';

const HeaderTitle = (props)=> {  
    const [str, setStr] = React.useState();

    const handleStr = (str) => {
        setStr(str);
    }

    const onSearch = () => {
        let string = (str) ? str : "";
        if(string){
            props.navigation.navigate('Search', {searchStr: string});
        }
    }

    if (cssConfig.isWeb) {
        return (
            <AppContext.Consumer>
                {context=> 
                    <View style={[css.setWidth(cssConfig.width()/1.3)]}>
                        <View style={[css.themeTextBox, css.row, css.setWidth(50, '%'), css.selfCenter]}>
                            <TextInput
                                onChangeText={handleStr}
                                style={[css.setHeight(40), css.setWidth(90, '%')]}
                            />
                            <MyButton
                                style={[css.setSize(40), css.center]}
                                onPress={onSearch}
                            >
                                <GIcons
                                    name="search-sm"
                                    height={35}
                                    width={35}
                                />
                            </MyButton>
                        </View>
                    </View>
                }
            </AppContext.Consumer>
        )
    }   
    
    return null
}

const style = StyleSheet.create({
    headerBar: {
        width: '100%',
        height: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {
        color: '#fff',
        fontSize: 18,
        fontFamily: cssConfig.fontFamily
    }
})

export default HeaderTitle;