export const featureList = [
    {
        iconName: 'user-check-01',
        title: 'Verify and manage your identity',
        content: "Stop imposters in their tracks. Verify your identity on The Internet Phone Book and link all your social media profiles. Gain control and let your connections know they've found the real you.",
    },
    {
        iconName: 'link-03',
        title: 'Discover and Connect',
        content: 'Looking for someone specific? Our search function simplifies the process. Find friends, family, and colleagues across multiple platforms, all at once. Connect like never before.',
    },
    {
        iconName: 'activity',
        title: 'Keep everyone updated',
        content: 'Automatically update all your contacts when your information changes. Never be tied to an email or phone number again.',
    },
    {
        iconName: 'lock-01',
        title: 'Privacy comes first',
        content: 'We respect your privacy. Control what you share and with whom. Your data is your own and we make sure it stays that way.',
    },
    {
        iconName: 'message-chat-circle',
        title: 'Invite and expand your network',
        content: 'Import your contacts seamlessly. Invite them to join The Internet Phone Book. Grow your network and enjoy the convenience of a unified online presence.',
    },
    {
        iconName: 'coins-hand',
        title: "you're free to use",
        content: 'Yes, you read it right.',
    },
]

export const firebaseConfig = {
    apiKey: "AIzaSyCDQVHL0HVJ85HnvZpYh6Jf7sBmci5bfnY",
    authDomain: "internetphonedirectory.firebaseapp.com",
    projectId: "internetphonedirectory",
    storageBucket: "internetphonedirectory.appspot.com",
    messagingSenderId: "304124948447",
    appId: "1:304124948447:web:a63c98ec32d63706c39e6a",
    measurementId: "G-0VWRGG9G88"
};

export const instaConfig = {
    appName: 'InternentPhoneDirectory',
    callBack: 'https://internetphonedirectory.firebaseapp.com/__/auth/handler',
    permission: 'instagram_graph_user_media',
    appId: '357702480015805', //'1367618200499428',
    appSecret: 'b985fded56e289415d80fb7485eed266', //'e9c9850a5c355e04afb624a9e09c63da',
    //url: 'https://www.instagram.com/oauth/authorize?app_id=1367618200499428&redirect_uri=https%3A%2F%2Finternetphonedirectory.firebaseapp.com%2F__%2Fauth%2Fhandler&scope=user_profile&response_type=code&logger_id=573dbe44-6517-49f8-b7a8-51fac23305e6'
    url: 'https://www.instagram.com/oauth/authorize?app_id=357702480015805&redirect_uri=https%3A%2F%2Finternetphonedirectory.firebaseapp.com%2F__%2Fauth%2Fhandler&scope=user_profile&response_type=code&logger_id=573dbe44-6517-49f8-b7a8-51fac23305e6'
}

export const fbConfig = {
    appId: '298767572509456', //'302172529131820',
    appSecret: '8c72f213f4895f8c05e66aee139e0e1a', //'8e7fd4dd84eeab51fc97f151ad075f16',
    displayName: 'internentPhoneDirectory',
    clientSecret: '8d339f57757554149849012706e18af2',
    callBackWeb: 'https://internetphonedirectory.firebaseapp.com/__/auth/handler'
}

export const twiterConfig = {
    apiKey: 'KTE1v4yHZWHbtkETlUHLHvYEk', //'TAGcFuXtmMYGuxCra1xwEHe5x', //consumerKey
    apiKeySecret: 'aSHAlF2xPXeWDLIfZGFf3veoiShRcUTr22Og1w5su6v6iLwBSi', //'74ybHxOvixwpXuTlkIuM2YqotwS5YxKslTwwDwigbpV0QOPsw1',
    bearerToken: 'AAAAAAAAAAAAAAAAAAAAADQlpgEAAAAAEN8LasECp9wCo5OsSPcflBxgceU%3DW6kp86DLDYeBw9eHkFtu6bigm8klp0LTHWQYr6PQryPc4xZ8tX',
    accessToken: '1693935994809020416-efK6jo2aggmaPmoPFi4K66UONxZm5e',
    accessTokenSecret: 'sHBfPfy1Y8MK9RXQhDkGxbuBpVRdDiox61hEp9uJP6bSh',
    clientId: 'ZUxJUnZyZm5xN2RNVUlyZ2lHS1Y6MTpjaQ',
    clientSecret: 'fIdKgszQXZ09NYWE8LjGbO-A65ulHddFX_wGbFC_bIqmmPP920',
    callBackWeb: 'https://internetphonedirectory.firebaseapp.com/__/auth/handler'
}

export const mastodonConfig = {
    clientKey: "Kq7p3FAPTpOgNVhDRPFioNwXkXslesBzkzd0I4W4B0Q",
    clientSecret: "ZqFGfoqcrknPo51GuOhkjDfhs7CLXrpOJtkPJGO1ufI",
    accessToken: "3Er52nTlCfqtD5BI50udJ_1NT4OyDa6tiHQ_1QN5i5g"
}