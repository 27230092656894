import { ApiCall, hostBase } from "../../api";

export const bsPrefix = 'data:image/png;base64,';

export const socialList = [
    {
        id: 'icon01',
        title: 'Mastodon',
        icon: 'mastodon',
        type: 'mastodon',
        link: '',
    },
    {
        id: 'icon02',
        title: 'Instagram',
        icon: 'instagram',
        type: 'instagram',
        link: '',
    },
    // {
    //     id: 'icon02',
    //     title: 'Rainbow',
    //     icon: 'rainbbow',
    //     type: 'rainbbow',
    //     link: '',
    // },
    {
        id: 'icon03',
        title: 'Facebook',
        type: 'facebook',
        icon: 'fb',
        link: '',
    },
    {
        id: 'icon04',
        title: 'Twitter',
        icon: 'twitter',
        type: 'twitter',
        link: '',
    }
];

export const onDeleteApi = (arg) => {
    let apiName = '';
    switch (arg.type) {
        case 'email': apiName = `/user-emails/${arg.data?.email_id}`;
            break;
        case 'verifyEmail': apiName = `/user-emails/${arg.data?.email_id}`;
            break;    
        case 'verifyPhone': apiName = `/user-phones/${arg.data?.phone_id}`;
            break;
        case 'link': apiName = `/user-links/${arg.data?.link_id}`;
            break;
        case 'phone': apiName = `/user-phones/${arg.data?.phone_id}`;
            break;
    }

    return ApiCall(apiName, { method: 'DELETE' });
}

export function saveEdits(apiName, req, method) {
    return ApiCall(apiName, { method: method }, req);
}

export function sendOtp(phoneNumber, cc) {
    return new Promise(resolve => {
        ApiCall('/send-phone-otp',
            { method: 'POST' },
            { phone_num: phoneNumber, phone_cc: cc }
        ).then((res) => {
            if (res.status) {
                resolve(true);
            } else {
                resolve(false);
            }
            console.log(res);
        }).catch((err) => {
            console.log(err);
            resolve(false);
        })
    })
}

export function sendEmail(email) {
    return new Promise(resolve => {
        ApiCall('/send-email-otp', { method: 'POST' }, { email })
            .then((res) => {
                if (res.status) {
                    resolve(true);
                } else {
                    resolve(false);
                }
                console.log(res);
            }).catch((err) => {
                console.log(err);
                resolve(false);
            })
    })
}

export function qrValue(data) {
    return `${hostBase}/profile/${data.username}`;
}
