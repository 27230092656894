import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import Home from './screens/Home';
import HeaderLeft from './nav/HeaderLeft';
import HeaderRight from './nav/HeaderRight';
import HeaderTitle from './nav/HeaderTitle';
import Profile from './screens/profile/Profile';
import Register from './screens/login/Register';
import Search from './screens/search/Search';
import Login from './screens/login/Login';
import Contacts from './screens/contact/Contacts';
import { AppProvider } from './context/AppContext';
import FindContacts from './screens/contact/FindContacts';
import Reports from './screens/profile/Reports';
import SocialConnect from './screens/profile/SocialConnect';
import EditInfo from './screens/profile/EditInfo';
import WebConnect from './screens/profile/WebConnect';
import { cssConfig } from './css/cssConfig';

const Stack = createStackNavigator();

export default function App() {
  const navigationRef = React.useRef();
  global.locaStore = {};
  const linking = {
    //prefixes: ['https://mychat.com', 'mychat://'],
    config: {
      screens: {
        Profile: 'profile/:username',
        WebConnect: 'WebConnect/:type'
      },
    },
  };

  const isCallFromMobile = (_navData)=> {
    return (_navData.route.name == 'WebConnect');
  }

  const loadStack = React.useMemo(() => {
    return (
      <Stack.Navigator
        key={"stackNav"}
        screenOptions = {(_navData) => isCallFromMobile(_navData) ? null : ({
          //headerBackground: props => <HeaderBackground {...props} />,
          headerTitle: props => <HeaderTitle {...props} {..._navData} />,
          headerRight: props => (<HeaderRight {...props} {..._navData} />),
          headerLeft: props =>  <HeaderLeft {...props} {..._navData} hideBack={true} />,
        })}
      >
        <Stack.Screen
          name="Home"
          component={Home}
          options={{title: 'Home'}}
        />
        <Stack.Screen
          name="Login"
          component={Login}
          options={{title: 'Login'}}
        />
        <Stack.Screen
          name="Profile"
          component={Profile}
          options={{title: 'Profile'}}
        />
        <Stack.Screen
          name="Search"
          component={Search}
          options={{title: 'Search Contacts'}}
        />
        <Stack.Screen
          name="Register"
          component={Register}
          options={{title: 'Register'}}
        />
        <Stack.Screen
          name="Contacts"
          component={Contacts}
          options={{title: 'Contacts'}}
        />
        <Stack.Screen
          name="FindContacts"
          component={FindContacts}
          options={{title: 'FindContacts'}}
        />
        <Stack.Screen
          name="Reports"
          component={Reports}
          options={{title: 'Reports'}}
        />
        <Stack.Screen
          name="SocialConnect"
          component={SocialConnect}
          options={{title: 'Connect To'}}
        />
        <Stack.Screen
          name="EditInfo"
          component={EditInfo}
          options={{title: 'Edit Info'}}
        />
        <Stack.Screen
          name="WebConnect"
          component={WebConnect}
          options={{title: 'Social connection'}}
        />
      </Stack.Navigator>
    )
  }, [])

  return (
    <AppProvider>
        <NavigationContainer ref={navigationRef} linking={linking}>
          {loadStack}
        </NavigationContainer>
    </AppProvider>
  );
}

