import React from "react";
import { FlatList, Image, TouchableOpacity, View } from "react-native";
import MyText from "../../libs/MyText";
import css from "../../css";
import MyButton from "../../libs/MyButton";
import { cssConfig } from "../../css/cssConfig";
import Select from "../../libs/Select";
import { searchData } from "./searchHelper";
import { ApiCall, BaseUrl, imgPrefix } from "../../api";
import Loader from "../../libs/Loader";
import AppContext from "../../context/AppContext";
import { getSocialIcon } from "../profile/ProfileUI";

const Search = (props) => {
    const _params = props.route.params;
    const [_data, setData] = React.useState({ name: _params.searchStr });
    const [isLoading, setLoading] = React.useState(true);
    const [locations, setLocationList] = React.useState([]);
    const [cityList, setCityList] = React.useState([]);
    const [list, setList] = React.useState([]);
    const context = React.useContext(AppContext);
    const [count, setCount] = React.useState(1);
    
    
    React.useEffect(() => {
        //context.setLoading(true);
        ApiCall('/countries').then((res) => {
            if (res.status) {
                const temp = res.data.reduce((acc, item) => {
                    acc.push({
                        label: item.name,
                        value: item.code,
                        phone: item.phone,
                    })
                    return acc;
                }, [])
                setLocationList(temp);
            }
        }).catch((err) => {
            console.log(err);
        })
        ApiCall('/cities').then((res) => {
            if (res.status) {
                const temp = res.data.reduce((acc, item) => {
                    acc.push({
                        label: item,
                        value: item,
                    })
                    return acc;
                }, [])
                setCityList(temp);
            }
        })
        .catch((err) => { console.log(err) })
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        let str= (_params.searchStr) ? _params.searchStr : "";
        if(str){
            _data.name = str;
            searchData(_data).then((res) => {
                if (res.status) {
                    const data = [...res.users, ...res.social];
                    if (data.length > 0) {
                        setList(data);
                    }
                    else {
                        setList([])
                    }
                }
            })
            .finally(() => {
                setLoading(false);
            })
        }
    }, [_params, _data, count]);

    const reset = () => {
        setData({ name: _params.searchStr });
    }


    const handleSelect = (name) => {
        return (d) => {
            setData({ ..._data, [name]: d.value });
        }
    }

    const unfollow = (arg)=> {
        if(context.isLogin) {
            ApiCall('/unfollow-user', {method: 'POST'}, {uuid_to_unfollow: arg?.item.uuid})
                .then((res)=> {
                    if (res.status) {
                        setCount(count + 1);
                        context.showInfoModal({
                            title: 'Success',
                            subTitle: 'User unfollowed.'
                        })
                    } else {
                        context.showInfoModal({
                            title: 'Warnin',
                            subTitle: 'Unable to unfollow. Try again latter.'
                        })
                    }
                })
                .catch((err)=> {
                    console.log(err);
                    context.showInfoModal({
                        title: 'Warnin',
                        subTitle: 'Unable to unfollow. Try again latter.'
                    })
                })
        } else {
            context.showInfoModal({
                title: 'Warning',
                subTitle: "Please login first",
                onOk: () => { props.navigation.navigate('Login'); },
            })
        }
    }
    
    const onFollow = (arg) => {
        if (context.isLogin) {
            ApiCall('/follow-user', { method: 'POST' }, { following_user_id: arg?.item.uuid })
                .then((res) => {
                    //console.log(res);
                    if (res.status) {
                        context.showInfoModal({
                            title: 'Followed',
                            subTitle: 'You are now following this profile.'
                        })
                    } else {
                        context.showInfoModal({
                            title: 'Warning',
                            subTitle: res.error
                        })
                    }
                });
        } else {
            context.showInfoModal({
                title: 'Warning',
                subTitle: "Please login first",
                onOk: () => { props.navigation.navigate('Login'); },
            })
        }
    }

    const openProfile = (arg) => {
        return () => {
            props.navigation.navigate('Profile', arg);
        }
    }

    const renderEmpty = () => {
        return (
            <View>
                <Loader loading={isLoading} style={[css.pLg]} />
                {isLoading ? null
                    : (
                        <>
                            <View style={[css.pLg, css.themeBorder, css.setMargin(22)]}>
                                <MyText
                                    title={"No data found. Please try again later."}
                                    style={[css.fs16, css.textGray]}
                                />
                            </View>
                            <MyButton
                                theme={true}
                                title={'Go Back'}
                                onPress={() => props.navigation.goBack()}
                                style={[css.setHeight(40)]}
                            />
                        </>
                    )
                }
            </View>
        )
    }

    const renderHeader = () => {
        return (
            <View style={[css.setPadding(5, 5, 5, 5, '%'), css.row, css.alignCorner, css.vCenter]}>
                <View>
                    <MyText
                        title="Search: "
                        nestedTitle={`"${_params.searchStr}"`}
                        theme="heading"
                        style={[css.textBlack, css.setFont(20)]}
                    />
                </View>
                <View style={[css.row]}>
                    <Select
                        onPress={handleSelect('country')}
                        headerTitle="Select Country"
                        list={locations}
                        value={_data.country}
                        placeholder="Country"
                        style={[css.setHeight(35), css.setWidth(150), css.themeTextBox]}
                        extraProps={{ center: true, widthPart: 3, viewStyle: 'fade' }}
                    />
                    <Select
                        onPress={handleSelect('city')}
                        headerTitle="Select Cilty"
                        list={cityList}
                        value={_data.city}
                        placeholder="City"
                        style={[css.setHeight(35), css.setWidth(150), css.themeTextBox, css.ml]}
                        extraProps={{ center: true, widthPart: 3, viewStyle: 'fade' }}
                    />
                    <Select
                        onPress={handleSelect('socialMedia')}
                        headerTitle="Select Socail Media"
                        value={_data.socialMedia}
                        list={[
                            {
                                label: 'Facebook',
                                value: 'facebook'
                            },
                            {
                                label: 'Instagram',
                                value: 'instagram'
                            },
                            {
                                label: 'Twitter',
                                value: 'twitter'
                            },
                            {
                                label: 'Mastodon',
                                value: 'mastodon'
                            }
                        ]}
                        placeholder="Social media"
                        style={[css.setHeight(35), css.ml, css.setWidth(150), css.themeTextBox]}
                        extraProps={{ center: true, widthPart: 3, viewStyle: 'fade' }}
                    />
                    <MyButton
                        title="Reset"
                        theme={true}
                        style={[css.setHeight(35), css.ml]}
                        onPress={reset}
                    />
                </View>
            </View>
        )
    }

    const getSocial = (item) => {
        if (item.social_profiles.length > 0) {
            return (
                <View key={`a${item.id}`} style={[css.setPadding(0, 84, 18, 10)]}>
                    <MyText title="Social Media" style={[css.setColor('#475467'), css.fs12, css.mb, css.textLight]} />
                    <View key={`b${item.id}`} style={css.row}>
                        {item.social_profiles.map((d) =>
                            <View key={d.profile_type} style={[css.row, css.vCenter, css.prMd]}>
                                <View key={`c${d.profile_type}`}>
                                    <Image
                                        source={d.profile_pic ? { uri: `${d.profile_pic}` } : require("../../images/user.png")}
                                        style={[
                                            css.setScreen(40, 40),
                                            css.imgContain,
                                            css.setBorder(1, '#ccc'),
                                            css.setRadius(20)
                                        ]}
                                    />
                                    <View key={`d${d.profile_type}`}
                                        style={[
                                            css.absolute,
                                            css.setBottom(0),
                                            css.setLeft(25),
                                            css.bgWhite,
                                            css.setRadius(12),
                                        ]}
                                    >
                                        {getSocialIcon(d.profile_type, 18)}
                                    </View>
                                </View>
                                <View style={css.plMd} key={`e${d.profile_type}`}>
                                    <MyText
                                        title={`${d.profile_fname} ${d.profile_lname}`}
                                        style={[css.fs14, css.setColor('#9747FF'), css.textLight]}
                                    />
                                    <MyText title={`@${d.profile_username}`} style={[css.fs12, css.setColor('#475467')]} />
                                </View>
                            </View>
                        )}
                    </View>
                </View>
            )
        }

        return null;
    }

    const getAction = (item) => {
        if(context.isLogin) {
            return (
                <View style={[css.center]}>
                    {item.followers.find((f) => f.following_user_id == context.user.uuid)
                        ? 
                            <MyButton
                                theme={true}
                                title="Unfollow"
                                onPress={unfollow}
                                arg={{item}}
                                style={[css.setHeight(35)]}
                            />
                        :
                        <MyButton
                            theme={true}
                            title="Follow"
                            onPress={onFollow}
                            arg={{item}}
                            style={[css.setHeight(35)]}
                        />
                    }
                </View>
            )
        }
        return null;
    }

    const renderRow = ({ item }) => {
        return (
            <View key={item.id} style={[css.setMargin(2, 5, 2, 5, '%'), css.setBg('#F7F7F7')]} >
                <TouchableOpacity style={[css.row, css.setPadding(14, 15, 8, 10)]} onPress={openProfile(item)}>
                    <View style={[css.setWidth(90, '%'), css.row, css.vCenter]}>
                        <View style={css.prMd}>
                            <Image
                                source={item.user_pic ? { uri: `${imgPrefix}/${item.user_pic}` } : require("../../images/user.png")}
                                style={[
                                    css.setScreen(60, 60),
                                    css.imgCover,
                                    css.setBorder(1, '#ccc'),
                                    css.setRadius(30)
                                ]}
                            />
                        </View>
                        <View>
                            <MyText title={`${item?.fname} ${item?.lname}`} style={[css.setColor('#6941C6'), css.textLightBold]} onPress={openProfile(item)} />
                            <MyText title={`${item.country}, ${item.city}`} style={[css.textBlack]} onPress={openProfile(item)} />
                        </View>
                    </View>
                    {getAction(item)}
                </TouchableOpacity>
                {getSocial(item)}
            </View>
        )
    }

    return (
        <FlatList
            data={list}
            renderItem={renderRow}
            ListHeaderComponent={renderHeader}
            ListEmptyComponent={renderEmpty}
            style={[css.bgWhite]}
        />
    )
}

export default Search;