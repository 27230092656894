import React, { createRef } from "react";
import NetInfo from "@react-native-community/netinfo";
import { StoreData, getStoreData } from "../api";
import { Dimensions, View } from "react-native";
import { appTheme } from "../config/theme";
import InfoModal from "../libs/InfoModal";
import { cssConfig } from "../css/cssConfig";
import Loader from "../libs/Loader";
import css from "../css";
const AppContext = React.createContext();
class AppProvider extends React.Component {
    constructor() {
        super()
        this.state = {
            lang: "en",
            portrait: true,
            net: {isConnected: false},
            dim: {},
            isLogin: false,
            user: {},
            isLoading: false,
            profileUpdate: 1,
        }
        this.dimRef = null;
        this.UI = appTheme();
        this.infoModalRef =  createRef();
    }

    componentDidMount() {
        getStoreData('user')
        .then((res)=> {
            if (res) {
                this.updateProvider("user", res);
                this.UI.accessKey(res.access_token);
                this.updateProvider("isLogin", res?.isLogin);
            }
        })
        .catch((err)=> {
            console.log(err);
        })
        this.dimRef = Dimensions.addEventListener('change', (e)=> {
            if (e.window) {
                this.updateProvider("portrait", e.window.width > e.window.height ? false : true);
                this.updateProvider("dim", e.window);
              }
        });

    }

    // Subscribe
    unsubscribe = NetInfo.addEventListener(netState => {
        this.setState({net: netState});
    });

    componentWillUnmount() {
        this.unsubscribe();
        this.dimRef.remove();
    }

    showModal = (data)=> {
        this.infoModalRef.current.showInfoModal(data);
    };

    updateProvider = (name, value)=> {
        if (name) {
            this.setState({[name]: value});
        }
    }

    setLoading = (st=false) => {
        this.setState({isLoading: st});
    }

    onLogout = () => {
        this.UI.accessKey(" ");
        StoreData('user', { isLogin: false }, true);
        this.setState({
            user: { isLogin: false },
            isLogin: false,
        })
    }

    onLogin = (res)=> {
        res.access_token && this.UI.accessKey(res.access_token);
        StoreData('user',{
            isLogin: true,
            isAdmin: res.type == 1,
            ...res,
        }, true);
        this.setState({
            user: res,
            isAdmin: res.type == 1,
            isLogin: true,
        })
    }

    updateUser = (res)=> {
        const temp = {
            ...this.state.user,
            ...res,
        }
        StoreData('user', temp, true);
        this.setState({
            user: temp,
            isLogin: true,
        })
    }

    getProviderData() {
        let stateData = this.state;
        return {
            ...stateData,
            updateState: this.updateProvider,
            updateUser: this.updateUser,
            onLogin: this.onLogin,
            onLogout: this.onLogout,
            showInfoModal: this.showModal,
            setLoading: this.setLoading,
        }
    }

    render() {
        return(
            <AppContext.Provider value={this.getProviderData()}>
                {this.props.children}
                {this.state.isLoading 
                    ? <View
                            style={[
                                css.absolute,
                                css.bgDark,
                                css.setScreen(),
                                css.setPosition(0, 0, 0, 0),
                                css.setOpacity(0.6),
                                css.center
                            ]}
                        >
                            <Loader loading={true} size={'large'} style={[]}/> 
                        </View>
                    : null
                }
                
                <InfoModal 
                    ref={this.infoModalRef} 
                    key="intorModal" 
                    center={cssConfig.isWeb}
                    widthPart={cssConfig.isWeb ? 3 : false} 
                    iconSize={18}
                    viewStyle={cssConfig.isWeb ? "fade" : 'slide'}
                />
            </AppContext.Provider>
        )
    }
}

export default AppContext;
export {AppProvider};
