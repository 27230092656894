import { Image, TextInput, View, Linking } from "react-native";
import css from "../../css";
import { cssConfig } from "../../css/cssConfig";
import { socialList } from "./profileConfig";
import MyText from "../../libs/MyText";
import MyButton from "../../libs/MyButton";
import userIcon from '../../images/user.png';
import { FbIcon, InstaIcon, Micon, YoutubeIcon } from "../../images/mIcons";
import RainBowIcon from "../../images/RainBowIcon";
import TwitterIcon from "../../images/Twitter.js";
import GIcons from "../../images/GIcons";
import AlertTriangle from "../../images/AlertTriangle";
import React from "react";
import CountryPicker from 'react-native-country-picker-modal'
import OTPbox from "../login/OTPbox";
import { BaseUrl } from "../../api";
import Loader from "../../libs/Loader";
import Camera from "../../images/Camera";

export function checkFollower(data, user) {
    const uid = data.user_followers.find((item) => {
        return item.following_user_id == user.uuid
    });
    //console.log(user.uuid, data.user_followers);
    return uid;
}

export function getSocialIcon(name, size = 35) {
    switch (name) {
        case 'mastodon': return (<Micon height={size} width={size} />);
        case 'instagram': return (<InstaIcon height={size} width={size} />);
        case 'facebook':
        case 'fb': return (<FbIcon height={size} width={size} />);
        case 'rainbbow': return (<RainBowIcon height={size} width={size} viewBox={70} />);
        case 'twitter': return (<TwitterIcon height={size} width={size} />);
        default: return null;
    }
}

export function GetSocialUI(props) {
    const createRow = (acc, items) => {
        const isAdded = props.data.find((d) => d.profile_type === items.type);
        // console.log(items);
        // console.log(props.data);
        if (!isAdded && items) {
            acc.push(
                <MyButton
                    key={items.id}
                    title={items.title}
                    onPress={props.handleSocial}
                    arg={items}
                    style={[
                        css.setHeight(55),
                        css.themeBorder,
                        css.btn,
                        css.setWidth(cssConfig.isWeb ? 60 : 100, '%'),
                        css.mtmd,
                        css.row,
                        css.center
                    ]}
                >
                    <View style={[css.setWidth(40)]}>{getSocialIcon(items.icon)}</View>
                    <View style={[css.setWidth(210)]}>
                        <MyText
                            title={'Connect with ' + items.title}
                            style={[css.ml, css.fs16, css.setColor('#344054'), css.textLight]}
                        />
                    </View>
                </MyButton>
            )
        }
        return acc;
    };

    return (
        <View style={[css.center, css.mtlg, css.setWidth(100, '%')]}>
            {socialList.reduce(createRow, [])}
        </View>
    )
}

function onFieldTouchLink(props){
    console.log(props);
    if (props.arg.link_url != "") {
        Linking.openURL(`https://${props.arg.link_url}`);
    }
}
function onFieldTouch (props) {
    console.log(props);
    let url = '';
    switch (props.type) {
        case 'twitter' :
             url = `https://twitter.com/${props.arg.profile_username}` 
             break;
        case 'facebook': 
            url = ''; 
            break;
        case 'instagram': 
            url = `https://instagram.com/${props.arg.profile_username}`
            break
        case 'mastodon': 
            url = `${props.arg.profile_url}`;
            break;
    }
    if (url != "") {
        Linking.openURL(url);
    }
}

export function GetField(props) {
    const dtStyle = props.style || [];
    let webWidth = 50;
    let mWidth = props.social ? 70 : 80;
    const verifyList = ['verifyPhone', 'verifyEmail', 'verifyLink'];

    const getDel = (arg) => {
        if (cssConfig.isWeb) {
            return (
                <MyText
                    title="Delete"
                    style={[css.pMd, css.ml, css.setColor(cssConfig.themeBgDark), css.textLightBold]}
                    onPress={props.onDelete}
                    arg={{ data: props.arg, type: props.type }}
                />
            )
        }
        return (
            <MyButton
                style={[css.setSize(35), css.center]}
                onPress={props.onDelete}
                arg={{ data: props.arg, type: props.type }}
            >
                <GIcons
                    name='removeIcon'
                    viewBox={256}
                    height={24}
                    width={24}
                />
            </MyButton>
        )
    }
    const getEdit = () => {
        if (props.edit) {
            webWidth = props.social ? 40 : 54;
            if (props.social) {
                return (
                    <MyText
                        title="Disconnect"
                        style={[css.pMd, css.ml, css.setColor(cssConfig.themeBgDark), css.textLightBold]}
                        onPress={props.handleEdit}
                        arg={{ social: true, arg: props.arg }}
                    />
                )
            }

            return (
                <>
                    <MyButton
                        style={[css.pMd, css.ml, css.center, css.setHeight(35)]}
                        onPress={props.handleEdit}
                        arg={props}
                    >
                        {cssConfig.isWeb
                            ? <MyText
                                title={verifyList.includes(props.type) ? 'Verify' : "Edit"}
                                style={[css.pMd, css.ml, css.setColor(cssConfig.themeBgDark), css.textLightBold]}
                            />
                            : (
                                <GIcons
                                    name={verifyList.includes(props.type) ? 'verify' : "editIcon"}
                                    viewBox={verifyList.includes(props.type) ? '50' : '22'}
                                    height={24}
                                    width={24}
                                />
                            )
                        }
                    </MyButton>
                    {getDel(props)}
                </>
            )
        }
        return null;
    }
    const getIcon = () => {
        if (props.source) {
            return (
                <View style={[css.pr]}>
                    <Image
                        source={props.source}
                        style={[css.setScreen(35, 35), css.setRadius(17)]}
                    />
                    <View style={[css.absolute, css.setBottom(-2), css.setLeft(20), css.bgWhite, css.setRadius(12)]}>
                        {getSocialIcon(props.arg?.profile_type, 18)}
                    </View>
                </View>
            )
        }
        return null;
    }
    return (
        <View style={[css.row, css.setWidth(100, '%'), css.center, ...dtStyle]}>
            <View style={[
                css.row,
                css.setHeight(45),
                css.setBg('#F9F5FF'),
                css.btn,
                css.setBorder(1, '#F9F5FF'),
                css.setWidth(cssConfig.isWeb ? webWidth : mWidth, '%')
            ]}>
                <View style={[css.flex1, css.row, css.center]}>
                    {getIcon()}
                    <MyText 
                        title={props.btnLabel} 
                        style={[css.setColor(cssConfig.themeBgDark), css.fs16]} 
                        onPress={props.social ? onFieldTouch.bind(this, props) : (props.arg.link_id) ? onFieldTouchLink.bind(this, props) : null}
                    />
                </View>
            </View>
            {getEdit()}
        </View>
    )
}

export function GetModalData(props) {
    const [countryCode, setCountryCode] = React.useState(props.data?.country_code || 'US');
    const d = props.data || {};
    const visiblityKey = Object.keys(d).find((k) => k.includes('visibility'));
    const [_form, setForm] = React.useState({ phone_cc: d.phone_cc || '1', visiblity: d[visiblityKey] || '0', ...d });

    const onSelectCountry = (country) => {
        setCountryCode(country.cca2);
        const cc = country.callingCode[0] || 0;
        setForm({ ..._form, phone_cc: cc, country_code: country.cca2 });
    }

    const handleModalInput = (name, type) => {
        return (value) => {
            if (type == "number" && isNaN(value)) return true;
            setForm({ ..._form, [name]: value });
        }
    }

    const handleCheckbox = (arg) => {
        setForm({ ..._form, visiblity: arg });
    }
    const vs = (
        <View style={[css.ptMd]}>
            <MyText title="Visibility" style={[css.mb]} />
            <View>
                <MyButton
                    type="checkbox"
                    title="Private"
                    onPress={handleCheckbox}
                    checked={_form.visiblity == "0"}
                    color={cssConfig.themeBgDark}
                    arg={"0"}
                    style={[css.setHeight(40)]}
                />
                <MyButton
                    type="checkbox"
                    title="Public"
                    onPress={handleCheckbox}
                    arg={"1"}
                    checked={_form.visiblity == "1"}
                    color={cssConfig.themeBgDark}
                    style={[css.setHeight(40)]}
                />
                <MyButton
                    type="checkbox"
                    title="People I follow"
                    onPress={handleCheckbox}
                    arg={"2"}
                    checked={_form.visiblity == "2"}
                    color={cssConfig.themeBgDark}
                    style={[css.setHeight(40)]}
                />
            </View>
        </View>
    );
    const act = (
        <View style={[css.row, css.setPadding(25, 'top')]}>
            <MyButton
                title="Cancel"
                onPress={props.closeModal}
                textStyle={[css.fs16]}
                style={[
                    css.setWidth(48, '%'),
                    css.setHeight(40),
                    css.mrsm,
                    css.themeBorder,
                    css.center
                ]}
            />
            <MyButton
                theme={true}
                title="Save"
                arg={{ name: props.name, data: _form }}
                onPress={props.handleSave}
                textStyle={[css.fs16]}
                disabled={props.progress}
                style={[
                    css.setWidth(48, '%'),
                    css.setHeight(40)
                ]}
            />
        </View>
    )
    switch (props.name) {
        case 'verifyLink': return (
            <View style={[css.center, cssConfig.isWeb ? css.setWidth(100, '%') : css.setWidth(), css.pLg]}>
                <View style={[css.pbLg]}>
                    <MyText title="Copy this link and paste in head of your website. Then click on Verify." style={[css.mb, css.fs18, css.textLightBold]} />
                </View>
                <View style={[css.setWidth(100, '%')]}>
                    <TextInput
                        value={d.link_url}
                        placeholderTextColor={cssConfig.themeTextColor}
                        placeholder="www.xxx.com"
                        style={[
                            css.themeTextBox, 
                            css.textBlack, 
                            css.setHeight(40), 
                            css.mbmd
                        ]}
                    />
                </View>
                <Loader loading={props.progress} style={[css.pLg]} />
                
                <MyButton
                    theme={true}
                    title={ "Verify"}
                    onPress={props.handleSave.bind(this, { name: 'verifyLink', data: d })}
                    arg={{ type: "link", data: d }}
                    style={[css.setHeight(40), css.setWidth(100, '%')]}
                    disabled={props.progress}
                />
            </View>
        );
        case 'verifyEmail':
        case 'emailOtp': return (
            <View style={[css.center]}>
                <OTPbox
                    embed={true}
                    show={true}
                    email={_form.email_address}
                    toggleOtp={props.closeModal}
                    onVerify={props.handleSave.bind(this, { name: 'verifyEmailOtp', data: _form })}
                />
            </View>
        );
        case 'newEmail':
        case 'email': return (
            <View style={[css.setWidth(100, '%'), css.vPadLg]}>
                <View style={[css.pbLg]}>
                    <MyText title="Add Email" style={[css.mb, css.fs18, css.textLightBold]} />
                </View>
                <View>
                    <MyText title="Email" style={[css.mb]} />
                    <TextInput
                        onChangeText={handleModalInput(props.name)}
                        placeholderTextColor={cssConfig.themeTextColor}
                        placeholder="Email"
                        value={_form[props.name]}
                        style={[css.themeTextBox, css.textBlack, css.setHeight(40)]}
                    />
                </View>
                {vs}
                {act}
            </View>
        );
        case 'newLink':
        case 'link': return (
            <View style={[css.setWidth(100, '%'), css.vPadLg]}>
                <View style={[css.pbLg]}>
                    <MyText title="Add Link" style={[css.mb, css.fs18, css.textLightBold]} />
                </View>
                <View>
                    <MyText title="Website" style={[css.mb]} />
                    <TextInput
                        onChangeText={handleModalInput(props.name)}
                        placeholderTextColor={cssConfig.themeTextColor}
                        placeholder="www.xxx.com"
                        value={_form[props.name]}
                        style={[css.themeTextBox, css.textBlack, css.setHeight(40)]}
                    />
                </View>
                {vs}
                <Loader loading={props.progress} style={[css.pLg]} />
                
                <MyButton
                    theme={true}
                    title={(_form.link_icon) ? "Update Link Icon" : "Add Link Icon"}
                    onPress={props.exploreImage}
                    arg={{ type: "link", data: _form }}
                    hide={props.name == 'newLink'}
                    style={[css.setHeight(40), css.setWidth(100, '%')]}
                    disabled={props.progress}
                />
                {act}
            </View>
        );
        case 'verifyPhone':
        case 'otp': return (
            <View style={[css.center]}>
                <OTPbox
                    embed={true}
                    show={true}
                    type="newPhone"
                    cc={_form.phone_cc}
                    phone={_form.phone_num}
                    toggleOtp={props.closeModal}
                    onVerify={props.handleSave.bind(this, { name: 'verifyOtp', data: _form })}
                />
            </View>
        )
        case 'newPhone':
        case 'phone': return (
            <View style={[css.setWidth(100, '%'), css.vPadLg]}>
                <View style={[css.pbLg]}>
                    <MyText title="Add Phone" style={[css.mb, css.fs18, css.textLightBold]} />
                </View>
                <MyText title="Phone number" style={[css.mb]} />
                <View style={[css.row]}>
                    <CountryPicker
                        countryCode={countryCode}
                        onSelect={onSelectCountry}
                        withFlag={true}
                        withFilter={true}
                        withEmoji={true}
                        withAlphaFilter={false}
                        withCallingCode={false}
                        ariaHideApp={false}
                        containerButtonStyle={[
                            css.setZindex(100),
                            css.setHeight(40),
                            css.setWidth(50),
                            css.setRadius(4, 0, 4, 0),
                            css.setBg(cssConfig.themeBgLight),
                            css.setBorder({ top: 1, left: 1, bottom: 1, right: 0 }, '#ccc'),
                            css.center,
                        ]}
                    />
                    <TextInput
                        value={_form[props.name]}
                        onChangeText={handleModalInput(props.name, "number")}
                        placeholderTextColor={cssConfig.themeTextColor}
                        placeholder="XX-XXXX-XXXX"
                        keyboardType="number-pad"
                        maxLength={10}
                        style={[
                            css.themeTextBox,
                            css.textBlack,
                            css.plMd,
                            css.setHeight(40),
                            css.setRadius(0, 4, 0, 4),
                            css.flex1
                        ]}
                    />
                </View>
                {vs}
                {act}
            </View>
        );
    }
}

export function getLabel(str) {
    return (
        <MyText
            title={str}
            style={[css.vPadMd, css.textLightBold, css.fs24]}
        />
    )
}

export function ProfileHeader(props) {
    const _profile = props.data || {};
    return (
        <>
            <View style={[css.setHeight(240)]}>
                <View
                    style={[
                        css.absolute,
                        css.setBg('#7F56D9'),
                        css.setWidth(100, '%'),
                        css.setHeight(150),
                        css.center,
                    ]}
                >
                    <View
                        style={[
                            css.absolute,
                            css.setBottom(-35, '%'),
                            css.bgWhite,
                            css.setRadius(50),
                            css.center,
                        ]}
                    >
                        <Image
                            source={_profile.profile_pic ? { uri: `${BaseUrl}/${_profile.profile_pic}` } : userIcon}
                            style={[
                                css.imgCover,
                                css.setScreen(100, 100),
                                css.setRadius(50),
                            ]}
                        />
                    </View>
                    {props.edit
                        ? (
                            <>
                                <View
                                    style={[
                                        css.bgDark,
                                        css.setSize(100),
                                        css.setBottom(-50, '%'),
                                        //css.absoluteCenter, 
                                        css.setOpacity('0.4'),
                                        css.setRadius(50),
                                    ]}
                                />
                                <MyButton
                                    hide={!props.edit}
                                    onPress={props.exploreImage}
                                    arg={"profilePic"}
                                    style={[
                                        css.setSize(100),
                                        css.center,
                                        css.absolute,
                                        css.setBottom(-35, '%'),
                                        css.setRadius(50),
                                        css.setRadius(20, 20)
                                    ]}
                                >
                                    <Camera
                                        height={48}
                                        width={48}
                                        viewBox={48}
                                        stroke={'white'}
                                        fill="gray"
                                    />
                                </MyButton>
                            </>
                        )
                        : null
                    }
                </View>
                <MyButton
                    title="Find Contacts"
                    onPress={props.openContacts}
                    textStyle={[css.textBlack]}
                    hide={cssConfig.isWeb || !props.edit}
                    style={[
                        css.themeBorder,
                        css.absolute,
                        css.setBottom(22, '%'),
                        css.setRight(1, '%'),
                        css.setHeight(30),
                        css.center,
                        css.hPadSm
                    ]}
                />
            </View>
            <View style={[css.center]}>
                <MyText
                    title={props.title}
                    style={[css.fs20, css.textLight]}
                />
                <View style={[css.row]}>
                    <MyText
                        title={props.location}
                        style={[css.fs12, css.textGray, css.vPadSm]}
                    />
                    <MyText
                        title={'Edit'}
                        style={[css.fs12, css.setColor(cssConfig.themeBgDark), css.vPadSm, css.mlmd]}
                        hide={!props.edit}
                        arg="locationUpdate"
                        onPress={props.handleHeaderAction}
                    />
                </View>
                <View style={[css.row, css.alignAround, css.setWidth(200)]}>
                    <MyText
                        title="Followers: "
                        nestedTitle={props.followers || 0}
                        style={[css.textLight]}
                        nestedStyle={[css.textGray]}
                        onPress={props.handleHeaderAction}
                        arg={"contacts"}
                    />
                    <MyText
                        title="Following: "
                        nestedTitle={props.followed || 0}
                        style={[css.textLight]}
                        nestedStyle={[css.textGray]}
                        onPress={props.handleHeaderAction}
                        arg={"contacts"}
                    />
                </View>
            </View>
            <View style={[css.row, css.center, css.ptLg]}>
                <MyButton
                    onPress={props.handleHeaderAction}
                    arg="report"
                    style={[css.setHeight(35), css.themeBgLight, css.btn, css.row]}
                    textStyle={[css.fs16, css.plMd, css.setColor(cssConfig.themeBgDark)]}
                    hide={props.data.uuid != props.user.uuid}
                >
                    <AlertTriangle width={20} height={20} stroke={cssConfig.themeBgDark} />
                    <MyText title="Report" style={[css.pl, css.setColor(cssConfig.themeBgDark)]} />
                </MyButton>
                <MyButton
                    onPress={props.handleHeaderAction}
                    arg="share"
                    style={[css.setHeight(35), css.mlmd, css.themeBgLight, css.btn, css.row]}
                    textStyle={[css.fs16, css.plMd, css.setColor(cssConfig.themeBgDark)]}
                >
                    <GIcons width={20} height={20} stroke={cssConfig.themeBgDark} name="share-06" />
                    <MyText title="Share" style={[css.pl, css.setColor(cssConfig.themeBgDark)]} />
                </MyButton>
                <MyButton
                    theme={true}
                    onPress={props.handleHeaderAction}
                    arg={props.isFollowed ? "unfollow" : "follow"}
                    style={[css.setHeight(35), css.mlmd, css.row]}
                    textStyle={[css.fs16, css.plMd]}
                    disabled={!props.isLogin}
                    hide={props.edit}
                >
                    <GIcons width={20} height={20} stroke="white" name="plus-circle" />
                    <MyText
                        title={props.isFollowed ? "Unfollow" : "Follow"}
                        style={[css.textWhite, css.pl]}
                    />
                </MyButton>
            </View>
        </>
    )
}

export function AddInfo(props) {
    if (props.edit || props.social) {
        return (
            <View
                style={[
                    css.setWidth(cssConfig.isWeb ? 61 : 100, '%'),
                    css.ptLg
                ]}
            >
                <MyButton
                    title={props.label}
                    onPress={props.onTouch}
                    arg={props.arg}
                    textStyle={[css.setColor(cssConfig.themeBgDark), css.textLight, css.textLeft, css.fs16]}
                    style={[css.setScreen(180, 35), css.hCenter, css.atStart]}
                />
            </View>
        )
    }

    return null;
}